export default [
  { title: 'Face with Tears of Joy', keywords: 'faces, expressions, emotions, tears, joy, laughter, happy, hysterical, happiness, giddiness', val: '😂' },
  { title: 'Smiling Face with Open Mouth and Smiling Eyes', keywords: 'grinning face, smiling eyes, smile, mouth, eye, open, laughter, happiness, joy, excitement, faces', val: '😄' },
  { title: 'Smiling Face with Open Mouth', keywords: 'grinning, smiles, eyes, faces, expressions, emotions, happy, joyous, content, pleased', val: '😃' },
  { title: 'Grinning Face', keywords: 'grinning, faces, emotions, expressions, grins, happiness, joy, excitement', val: '😀' },
  { title: 'Smiling Face with Smiling Eyes', keywords: 'smiling, emotions, expressions, happy, joyous, faces, eyes, blush', val: '😊' },
  { title: 'Winking Face', keywords: 'winking, faces, winks, fun, joking, jokes, flirting, laughing, playful, expressions, emotions', val: '😉' },
  { title: 'Smiling Face with Heart-Shaped Eyes', keywords: 'smiling, smiles, heart-eyes, hearts, faces, expressions, emotions, love, affection, romance, eyes', val: '😍' },
  { title: 'Face Throwing a Kiss', keywords: 'faces, emotions, expressions, blowing, kiss, goodbye, love, affection, romance, flirtation', val: '😘' },
  { title: 'Kissing Face with Closed Eyes', keywords: 'kisses, kissing, faces, eyes, expressions, affection, gratitude, friendly, friends, hello, goodbye', val: '😚' },
  { title: 'Kissing Face', keywords: 'kissing, faces, kisses, love, expressions, appreciation, gratitude, goodbye', val: '😗' },
  { title: 'Kissing Face with Smiling Eyes', keywords: 'smile, eye, face, kiss, love, gratitude, hello, goodbye, greeting', val: '😙' },
  { title: 'Face with Stuck-Out Tongue and Winking Eye', keywords: 'expressions, tongues, eyes, jokes, winking, faces, funny, crazy, joking, hilarious, flirtatious', val: '😜' },
  { title: 'Face with Stuck-Out Tongue and Tightly-Closed Eyes', keywords: 'squinting, faces, emotions, expressions, horrible, tongues, eyes, faces, taste, joking, laughter, excitement, fun, playfulness', val: '😝' },
  { title: 'Face with Stuck-Out Tongue', keywords: 'faces, expressions, tongues, teasing, joking, funny, cute, happy', val: '😛' },
  { title: 'Flushed Face', keywords: 'flushed, dazed, faces, emotions, expressions, embarrassment, awkwardness, shyness, bashfulness', val: '😳' },
  { title: 'Grinning Face with Smiling Eyes', keywords: 'faces, expressions, emotions, grins, smiles, eyes, teeth, squinting, happy, funny, excitemen', val: '😁' },
  { title: 'Pensive Face', keywords: 'pensive, dejected, faces, emotions, expressions, melancholy, sadness, grief, depression, disappointment, dissatisfaction', val: '😔' },
  { title: 'Relieved Face', keywords: 'relieved, faces, calmness, relief, serenity, gratitude, emotions, expressions, peacefulness', val: '😌' },
  { title: 'Unamused Face', keywords: 'annoyance, unhappiness, grumpiness, irritation, disagreement, boredom, unamused, unhappy, faces, emotions, expressions', val: '😒' },
  { title: 'Disappointed Face', keywords: 'faces, emotions, expressions, disappointed, upset, disappointed, frustrated, burned, burnt', val: '😞' },
  { title: 'Persevering Face', keywords: 'persevering, faces, expressions, emotions, persevere, discomfort, frustration, impatience, unpleasant', val: '😣' },
  { title: 'Crying Face', keywords: 'crying, faces, emotions, tears, weeping, sobbing, sad, sadness, droplets', val: '😢' },
  { title: 'Loudly Crying Face', keywords: 'loudly, crying, faces, expressions, emotions, tears, sad, sobbing, wailing, intense, pain, hurt, sadness, injuries', val: '😭' },
  { title: 'Sleepy Face', keywords: 'sleepy, asleep, tired, exhausted, exhaustion, faces, expressions, emotions, sleeping', val: '😪' },
  { title: 'Sad but Relieved Face', keywords: 'sad, faces, expressions, emotions, disappointed, relieved, whew', val: '😥' },
  { title: 'Anxious Face with Sweat', keywords: 'cold, rushed, anxious, faces, sweating, blue, anxiety, stress, sadness, fear, worry, nervousness, emotions', val: '😰' },
  { title: 'Smiling Face with Open Mouth and Cold Sweat', keywords: 'smiling, smiles, faces, expressions, sweat, cold, emotions, relief, relieved, whew', val: '😅' },
  { title: 'Face with Cold Sweat', keywords: 'downcast, faces, expressions, emotions, cold, sweat, worry, stress, anxiousness, pressure, disappointment, frustration, sadness', val: '😓' },
  { title: 'Weary Face', keywords: 'tired, exhausted, sad, overwhelmed, weary, faces, emotions, expressions', val: '😩' },
  { title: 'Tired Face', keywords: 'tired face', val: '😫' },
  { title: 'Fearful Face', keywords: 'fearful, fear, scared, faces, emotions, expressions, surprised, shocked, sad, depressed', val: '😨' },
  { title: 'Face Screaming in Fear', keywords: 'faces, emotions, expressions, screaming, fear, scared, face, Edvard Munch, scream, overwhelmed, shock, dismay, horror, terrible, news', val: '😱' },
  { title: 'Angry Face', keywords: 'angry, faces, mad, sad, anger, madness, upset, disapproval, emotion', val: '😠' },
  { title: 'Pouting Face', keywords: 'mad, emotions, expressions, faces, pouting, angry, red, rage, anger, hatred', val: '😡' },
  { title: 'Face with Steam From Nose', keywords: 'faces, emotions, expressions, fuming, anger, rage, irritation, frustration, contempt', val: '😤' },
  { title: 'Confounded Face', keywords: 'faces, emotions, confounded, failure, illness, anguish, pain, ouch, frustration, sadness', val: '😖' },
  { title: 'Smiling Face with Open Mouth and Tightly-Closed Eyes', keywords: 'grinning, squinting, faces, laughter, satisfied, laughing, mouth, smiles, emotions, expressions', val: '😆' },
  { title: 'Face Savouring Delicious Food', keywords: 'faces, expressions, savoring, food, delicious, savouring, yum, tongue, smile, tasty, hungry, silliness, playfulness, happiness, naughty, joking', val: '😋' },
  { title: 'Face with Medical Mask', keywords: 'faces, medical, patients, masks, doctors, colds, sick, bacteria, germs, sickness, odors, environment, pollution, dust, infection', val: '😷' },
  { title: 'Smiling Face with Sunglasses', keywords: 'smiling, smiles, faces, expressions, sun, sunglasses, cool, bright, sunny, eyes', val: '😎' },
  { title: 'Sleeping Face', keywords: 'sleeping, faces, asleep, zzz, expressions, tired, exhausted, snoring, snores', val: '😴' },
  { title: 'Dizzy Face', keywords: 'faces, expressions, dizzy, confusion, dizziness, hypnosis, trance, hypnotized, swooning, overwhelmed, puzzled', val: '😵' },
  { title: 'Astonished Face', keywords: 'astonished, faces, shocked, surprised, astonishment, excitement, emotions', val: '😲' },
  { title: 'Worried Face', keywords: 'worried, concerned, anxious, anxiety, worry, faces, emotions, expressions', val: '😟' },
  { title: 'Frowning Face with Open Mouth', keywords: 'frowning, faces, emotions, expressions, open, mouth', val: '😦' },
  { title: 'Anguished Face', keywords: 'anguished, faces, emotions, anxious, worried, confused', val: '😧' },
  { title: 'Smiling Face with Horns', keywords: 'smiling, smiles, faces, horns, devils, demons, evil, expressions, smirk, red, purple', val: '😈' },
  { title: 'Angry Face with Horns', keywords: 'imps, devils, angry, faces, emotions, horns, fantasy, fantasies, demons, anger, frustrations, madness, evilness', val: '👿' },
  { title: 'Face with Open Mouth', keywords: 'faces, expressions, emotions, open, mouth, shocked, surprised', val: '😮' },
  { title: 'Grimacing Face', keywords: 'grimace, grimacing, faces, emotions, expressions, embarrassment, nervousness, frustration', val: '😬' },
  { title: 'Neutral Face', keywords: 'deadpan, neutral, disinterest, boredom, silence, indifference, emotionless, expressionless, faces', val: '😐' },
  { title: 'Confused Face', keywords: 'faces, emotions, confused, confusion, sorrow, depression, unhappy, melancholy', val: '😕' },
  { title: 'Hushed Face', keywords: 'hushed, surprised, face, stunned, surprise, shock, astonishment, confusion, emotions, expressions', val: '😯' },
  { title: 'Face without Mouth', keywords: 'faces, expressions, emotions, mouthless, quiet, silent, silence, frustration, disappointment', val: '😶' },
  { title: 'Smiling Face with Halo', keywords: 'smiling, faces, halos, expressions, angelic, innocent, fantasy', val: '😇' },
  { title: 'Smiling Face', keywords: 'smiling, smiles, emotions, expressions, faces, content, happy, pleased, joyous, joyful', val: '☺️' },
  { title: 'Smirking Face', keywords: 'smirking, smirks, faces, expressions, sly, sarcastic', val: '😏' },
  { title: 'Expressionless Face', keywords: 'inexpressive, expressionless, unexpressive, unimpressed, bored, skeptical, silent, faces, emotions, expressions', val: '😑' },
  { title: 'Upside-Down Face', keywords: 'upside-down, faces, expressions, emotions, confusion, irony, sarcasm, silliness', val: '🙃' },
  { title: 'Face with Rolling Eyes', keywords: 'faces, eyes, eyeroll, rolling, disinterest, boredom, indifference, expressions, emotions', val: '🙄' },
  { title: 'Frowning Face', keywords: 'frowning, faces, emotions, expressions, frown, sadness, concern, disappointment', val: '☹️' },
  { title: 'Zipper-Mouth Face', keywords: 'zipper-mouth, faces, mouths, zippers, silence, secret, quiet, speechless, silent, emotions, expressions', val: '🤐' },
  { title: 'Money-Mouth Face', keywords: 'money, mouth, faces, bills, rich, success, wealthy, winning', val: '🤑' },
  { title: 'Face with Thermometer', keywords: 'sick, expressions, thermometers, ill, faces, sickness, temperature, fevers, illness, discomfort', val: '🤒' },
  { title: 'Nerd Face', keywords: 'nerds, geeks, smart, brainy, geeky, goofy, awkward, strange, faces, expressions', val: '🤓' },
  { title: 'Thinking Face', keywords: 'thinking face', val: '🤔' },
  { title: 'Face with Head-Bandage', keywords: 'faces, expressions, emotions, hurt, bandages, injury, accidents, injuries, pain', val: '🤕' },
  { title: 'Slightly Frowning Face', keywords: 'frowning, faces, disappointed, emotions, expressions, pensive, thinking, sad', val: '🙁' },
  { title: 'Slightly Smiling Face', keywords: 'smiling, faces, smiles, happy, pleased, content, emotions, expressions', val: '🙂' },
  { title: 'Hugging Face', keywords: 'hugging, hugs, happiness, emotions, expressions, embracing, gratitude, friendship, love, caring', val: '🤗' },
  { title: 'Rolling On The Floor Laughing', keywords: 'rolling, laughing, floors, laughter, fun, giggles, hilarious, hysterics, faces, emotions, expressions, joking', val: '🤣' },
  { title: 'Face With Cowboy Hat', keywords: 'hats, cowgirls, cowboys, faces, wild, west, western, riding, horses, cosplay, adventure, confidence, fun', val: '🤠' },
  { title: 'Lying Face', keywords: 'lies, expressions, lying, faces, Pinocchio, guilt, regret, dishonesty, deception', val: '🤥' },
  { title: 'Drooling Face', keywords: 'faces, emotions, expressions, drooling, desire, longing, craving, food', val: '🤤' },
  { title: 'Nauseated Face', keywords: 'nauseated, vomiting, sick, throw up, seasick, disgust, repulsion, faces, expressions, ill, green', val: '🤢' },
  { title: 'Sneezing Face', keywords: 'sneezing, sneezes, colds, allergies, flu, achoo, sick, ill, coughing, fever, faces, gesundheit', val: '🤧' },
  { title: 'Clown Face', keywords: 'faces, clowns, silliness, fun, kids, children, parties, birthdays, jokes, comedy, humor, smiles, circus', val: '🤡' },
  { title: 'Robot Face', keywords: 'monsters, robots, electronics, technology, robotics, science, fiction, superintelligence', val: '🤖' },
  { title: 'Black Heart', keywords: 'black, hearts, evil, wicked, sorrow, grief, sadness, emotions, love, romance, shapes', val: '🖤' },
  { title: 'Yellow Heart', keywords: 'hearts, yellow, love, friendship, romance, appreciation, color, shape', val: '💛' },
  { title: 'Blue Heart', keywords: 'blue, hearts, peace, calm, water, boys, men, males, platonic, cold, romance, love, emotions, shapes', val: '💙' },
  { title: 'Purple Heart', keywords: 'hearts, purple, love, sensitivity, calmness, royalty, shapes', val: '💜' },
  { title: 'Green Heart', keywords: 'green, hearts, eco-friendly, jealousy, organic', val: '💚' },
  { title: 'Orange Heart', keywords: 'oranges, hearts, tomatoes, carrots, color, shapes', val: '🧡' },
  { title: 'Red Heart', keywords: 'hearts, love, red, affection, caring, romance, like, shapes, emotion', val: '❤️️' },
  { title: 'Broken Heart', keywords: 'breaks, broken, hearts, broken, heartbreaks, love, sickness, sadness, missing, divorce, romance, emotions', val: '💔' },
  { title: 'Growing Heart', keywords: 'growing, hearts, nervous, growing, pulsing, excited, Valentine, love', val: '💗' },
  { title: 'Beating Heart', keywords: 'beating, hearts, heartbeats, pulsating, love, romance, passion, red, emotions', val: '💓' },
  { title: 'Two Hearts', keywords: 'love, affection, romance, friendship, appreciation, relationships, hearts, emotions, expressions', val: '💕' },
  { title: 'Sparkling Heart', keywords: 'sparkling, hearts, sparkles, romance, excitement, love, affection, gratitude, goodwill', val: '💖' },
  { title: 'Revolving Hearts', keywords: 'revolving, hearts, spinning, emotions, romance, love, affection', val: '💞' },
  { title: 'Heart with Arrow', keywords: 'hearts, expressions, emotions, arrows, cupid, love, romance, Valentines', val: '💘' },
  { title: 'Heart with Ribbon', keywords: 'valentine, hearts, ribbons, chocolates, candies, romantic, gifts, love, affection, emotions, expressions', val: '💝' },
  { title: 'Heart Exclamation', keywords: 'marks, punctuation, exclamation, heart exclamation, happiness, love, agreement, consent, admiration', val: '❣️' },
  { title: 'Love Letter', keywords: 'letters, hearts, love, mail, cards, romance, Valentines, sentimental, intimacy', val: '💌' },
  { title: 'Kiss Mark', keywords: 'kisses, lipstick, lips, goodbye, romance, love', val: '💋' },
  { title: 'Smiling Cat Face with Open Mouth', keywords: 'grinning, cats, smiles, mouths, faces, expressions, happy, content, pleased, joyous, joyful', val: '😺' },
  { title: 'Grinning Cat Face with Smiling Eyes', keywords: 'grins, eyes, smiles, cats, faces, joy, happiness, excitement, emotions, expressions', val: '😸' },
  { title: 'Smiling Cat Face with Heart-Shaped Eyes', keywords: 'smiling, smiles, cats, heart-eyes, hearts, love, eyes, faces, emotions, expressions', val: '😻' },
  { title: 'Kissing Cat Face with Closed Eyes', keywords: 'kissing, cats, faces, thanks, kisses, love, goodbye', val: '😽' },
  { title: 'Cat Face with Wry Smile', keywords: 'cats, wry, smiles, ironic, faces, sarcasm, displeasure, aggression, dry, wit, wryness, emotions', val: '😼' },
  { title: 'Weary Cat Face', keywords: 'surprised, shocked, astonished, weary, dismayed, concerned, cats, faces, expressions, emotions', val: '🙀' },
  { title: 'Crying Cat Face', keywords: 'crying, cats, cries, faces, tears, sadness, sorrow, weeping, sobbing, emotions, droplets', val: '😿' },
  { title: 'Cat Face with Tears of Joy', keywords: 'tears, cats, joy, faces, happiness, emotions, smiles, laughter, fun', val: '😹' },
  { title: 'Pouting Cat Face', keywords: 'pouting, faces, cats, anger, frustration, bitterness, rage, madness, emotions, expressions', val: '😾' },
  { title: 'See-No-Evil Monkey', keywords: 'see-no-evil, monkeys, forbidden, see, evil, faces, expressions', val: '🙈' },
  { title: 'Hear-No-Evil Monkey', keywords: 'hear-no-evil, monkeys, hear, listen, forbidden, evil, faces, reluctance, gossip, bad, news', val: '🙉' },
  { title: 'Speak-No-Evil Monkey', keywords: 'speak, speak-no-evil monkeys, speak, silence, ignorance, forbidden, evil, faces', val: '🙊' },
  { title: 'Skull', keywords: 'monsters, skulls, faces, death, scary, bones, skeletons', val: '💀' },
  { title: 'Extraterrestrial Alien', keywords: 'faces, heads, extraterrestrials, aliens, fantasy, creatures, ufos, strange, unexplainable', val: '👽' },
  { title: 'Japanese Ogre', keywords: 'fairy tales, monsters, fantasy, creature, ogre, ghost, evil, spirits, faces', val: '👹' },
  { title: 'Japanese Goblin', keywords: 'monsters, faces, fantasy, goblins, fairy tales, creatures, dangers, demons, evil, spiritual', val: '👺' },
  { title: 'Star-Struck', keywords: 'admiration, appreciation, happiness, joy, star-struck, stars, eyes, faces, expressions, emotions', val: '🤩' },
  { title: 'Face With Raised Eyebrow', keywords: 'faces, expressions, eyebrows, distrust, skeptic, skepticism, concerns, doubts, confusion', val: '🤨' },
  { title: 'Pleading Face', keywords: 'puppy, eyes, pleading, faces, expressions, emotions, begging, mercy, pity, loveliness, cuteness, favors', val: '🥺️' },
  { title: 'Exploding Head', keywords: 'mind, blown, exploding, head, shocked, shock, surprise, faces, emotions, expressions', val: '🤯' },
  { title: 'Zany Face', keywords: 'zany, goofy, crazy, fun, wacky, silly, drunk, faces, expressions, emotions, wackiness, craziness, drunkenness, partying', val: '🤪' },
  { title: 'Face With Symbols Over Mouth', keywords: 'faces, emotions, expressions, swearing, anger, angry, rage, madness, irritation, hatefulness, vulgar, cursing, seriousness', val: '🤬' },
  { title: 'Face Vomiting', keywords: 'sick, faces, vomiting, vomit, negative, disgusting, opposition, rejection, gross', val: '🤮' },
  { title: 'Shushing Face', keywords: 'shush, quiet, shushing, faces, fingers, hush, expressions', val: '🤫' },
  { title: 'Face With Hand Over Mouth', keywords: 'faces, emotions, expressions, whoops, embarrassment, smirking, giggling', val: '🤭' },
  { title: 'Face With Monocle', keywords: 'faces, expressions, monocles, investigations, intellectual, learning, detective, work, inspecting', val: '🧐' },
  { title: 'Smiling Face With Hearts', keywords: 'love, smiling, faces, hearts, crushes, adores, romance, affection, emotions, expressions', val: '🥰️' },
  { title: 'Hot Face', keywords: 'hot, sweat, reddish, overheated, discomfort, warm, burning, heat, thirst, chilis, peppers, spicy', val: '🥵️' },
  { title: 'Cold Face', keywords: 'faces, cold, weather, body, coldness, chills, frostbite, hypothermia, freezing, frozen, frigid, winter', val: '🥶️' },
  { title: 'Woozy Face', keywords: 'intoxicated, dizzy, tipsy, drunk, wavy, mouth, woozy, tired, crazy, confused, faces, expressions', val: '🥴️' },
  { title: 'Partying Face', keywords: 'parties, confetti, horns, hats, celebrations, fun, holidays, birthdays, special, occasions, faces, emotions', val: '🥳️' },
  { title: 'Smiling Face with Tear', keywords: 'smiling, tear, tears, emotions, faces, expression, touched, grateful, proud, relieved', val: '🥲' },
  { title: 'Disguised Face', keywords: 'faces, mystery, intrigue, secret, information, hiding, disguising, detective work, glasses', val: '🥸' },
  { title: 'Baby', keywords: 'young, baby, babies, infants, cute, precious, diapers, newborns, children, child', val: '👶' },
  { title: 'Boy', keywords: 'young, boys, males, men, masculinity, adolescents, children, child', val: '👦' },
  { title: 'Girl', keywords: 'young, girls, feminine, female, women, adolescents, children, child', val: '👧' },
  { title: 'Man', keywords: 'man, men, males, masculine, boys, adults, ♂️', val: '👨' },
  { title: 'Woman', keywords: 'woman, women, females, feminine, girls, ♀️, adult', val: '👩' },
  { title: 'Baby Angel', keywords: 'baby, babies, angels, fairy, tales, fantasy, fantasies, infants, halos, wings, face, child, children', val: '👼' },
  { title: 'Pregnant Woman', keywords: 'woman, women, pregnant, labor, nine, months, birth, baby, babies, infants, newborns, children, breastfeeding', val: '🤰' },
  { title: 'Selfie', keywords: 'phones, cameras, selfies, portraits, photos, pictures', val: '🤳' },
  { title: 'Princess', keywords: 'princesses, royalty, crowns, kings, women, girls, fairy tales, nobility, romance, ballgowns, kingdoms', val: '👸' },
  { title: 'Prince', keywords: 'princes, royalty, crowns, kings, men, boys, fairy tales, nobility, romance, shining, armor, horses, princesses, kingdoms', val: '🤴' },
  { title: 'Bearded Person', keywords: 'beards, man, person, mustaches, facial, hair, masculine, male', val: '🧔' },
  { title: 'Man Dancing', keywords: 'man, men, males, dancing, dancers, partners, disco, suits, activity, exercise', val: '🕺' },
  { title: 'Woman Dancing', keywords: 'woman, women, dancers, dancing, red, dresses, exercise, ballet, activity, partners, tango, flamenco, ballgowns, salsa', val: '💃' },
  { title: 'Ninja', keywords: 'hidden, fighters, stealth, ninjas, swords, Japanese, silent, warriors', val: '🥷' },
  { title: 'Person in Suit Levitating', keywords: 'business, person, suit, levitating, levitate', val: '🕴️' },
  { title: 'Ghost', keywords: 'ghosts, monsters, creatures, stories, fantasy, faces, funny, scary, cartoon-like, faces, friendly, hauntings, spirits, supernatural', val: '👻' },
  { title: 'Japanese Dolls', keywords: 'dolls, Japanese, festivals, celebrations, Hinamatsuri, girls', val: '🎎' },
  { title: 'Snowman', keywords: 'snow, cold, snowman. snowmen, winter, Christmas, fun', val: '☃️' },
  { title: 'Skull and Crossbones', keywords: 'bodies, faces, skulls, crossbones, bones, crosses, death, monsters, scary', val: '☠️' },
  { title: 'Eye in Speech Bubble', keywords: 'speech, bubbles, eyes, witnessing, watching, community, protection', val: '👁‍🗨' },
  { title: 'Moyai', keywords: 'Moyai, Moai, statue, faces, silliness, deadpan, tourism, Easter Island', val: '🗿' },
  { title: 'Person With Skullcap', keywords: 'hats, gua pi mao, person, caps, skullcaps', val: '👲' },
  { title: 'Ear', keywords: 'earrings, ears, body, parts, hearing, listening, deaf, lobes, canals, eardrums, anatomy', val: '👂' },
  { title: 'Eyes', keywords: 'eyes, eye, face, body, parts, cartoon, seeing, watching, looking, observing', val: '👀' },
  { title: 'Nose', keywords: 'nose, body, parts, anatomy, smelling, scents, smells, odors, breathing, respiratory, face, nostrils', val: '👃' },
  { title: 'Tongue', keywords: 'tongues, body, parts, anatomy, mouth, taste, joking, mocking', val: '👅' },
  { title: 'Mouth', keywords: 'mouth, lips, oral, lipstick, red, anatomy, body, part', val: '👄' },
  { title: 'Eye', keywords: 'eyes, body, ocular, eyelids, eyelashes, retinas, eyeballs, anatomy, vision, visual, watching, seeing, looking', val: '👁' },
  { title: 'Lungs', keywords: 'lungs, respiration, organs, inhaling, breathing, exhaling, pulmonology, bronchial, pneumonia, smoking', val: '🫁' },
  { title: 'Anatomical Heart', keywords: 'hearts, cardiology, organ, pulse, anatomical, blood, veins, circulatory, system, anatomy', val: '🫀' },
  { title: 'Bust in Silhouette', keywords: 'busts, silhouettes, users, generic, human, profile', val: '👤' },
  { title: 'Busts in Silhouette', keywords: 'busts, silhouette, users, profiles, humans', val: '👥' },
  { title: 'Speaking Head in Silhouette', keywords: 'silhouettes, faces, heads, speaking, speaks, talking, shouting, yelling', val: '🗣' },
  { title: 'People Hugging', keywords: 'hugs, greetings, relationships, friendship, goodbye, hello, people, hugging, thanks, embrace, embracing', val: '🫂' },
  { title: 'Speech Balloon', keywords: 'bubbles, balloons, dialogues, speech, text, cartoons, comics', val: '💬' },
  { title: 'Thought Balloon', keywords: 'bubbles, thoughts, thinking, comics, cartoons, silence, balloon, dialogues, expressions', val: '💭' },
  { title: 'Left Speech Bubble', keywords: 'left, speech, bubble, talking, dialog, cartoons, comics', val: '🗨' },
  { title: 'Right Anger Bubble', keywords: 'bubbles, right, anger, mad, balloon, angry, comics, cartoons, dialog', val: '🗯' },
  { title: 'Child', keywords: 'young, child, children, boys, girls, kids', val: '🧒' },
  { title: 'Person', keywords: 'bicycles, cyclists, athletes, person, biking, exercise, outdoor, activities, transportation, bike, races, riding', val: '🧑' },
  { title: 'Woman With Headscarf', keywords: 'hijabs, tichels, headscarf, headscarves, mantillas, turbans, woman, women', val: '🧕' },
  { title: 'Breast-Feeding', keywords: 'breast-feeding, breasts, nursing, baby, babies, infants, children, newborns, feeding, formula', val: '🤱' },
  { title: 'Leg', keywords: 'legs, limbs, kick, thighs, knees, calves, running, jumping, anatomy, body, parts, feet', val: '🦵️' },
  { title: 'Foot', keywords: 'stomping, foot, feet, kicking, walking, running, toes, ankles, anatomy, podiatry, pedicures', val: '🦶️' },
  { title: 'Tooth', keywords: 'tooth, teeth, dental, dentistry, braces, orthodontist, dentist, white', val: '🦷️' },
  { title: 'Bone', keywords: 'skeletons, bones, calcification, osteo, skeletal, fractures, dogs, marrow, anatomy', val: '🦴️' },
  { title: 'Family', keywords: 'family, families, mothers, fathers, moms, dads, parents, children, kids, love, relationships, men, women', val: '👪' },
  { title: 'Family: Man, Boy', keywords: 'family, man, boys, sons, dads, fathers, families, relationships, men, love, children, males, relatives', val: '👨‍👦' },
  { title: 'Family: Man, Boy, Boy', keywords: 'families, family, love, fathers, dads, men, man, uncles, males, relatives, sons, boys, parents, children, relationships, brothers, siblings', val: '👨‍👦‍👦' },
  { title: 'Family: Man, Girl', keywords: 'family, love, man, girls, daughters, dads, fathers, families, men, man, relationships, children, females, males, relatives', val: '👨‍👧' },
  { title: 'Family: Man, Girl, Boy', keywords: 'families, family. love, fathers, dads, men, man, females, males, girls, daughters, siblings, sons, boys, parents, children, relationships, brothers, sisters', val: '👨‍👧‍👦' },
  { title: 'Family: Man, Girl, Girl', keywords: 'families, family, love, fathers, dads, men, man, uncles, males, females, relatives, girls, daughters, sisters, parents, children, relationships, siblings', val: '👨‍👧‍👧' },
  { title: 'Family: Man, Woman, Boy', keywords: 'families, men, man, woman, women, family, love, fathers, dads, mothers, moms, females, males, relatives, sons, boys, parents, children, relationships, brothers, siblings', val: '👨‍👩‍👦' },
  { title: 'Family: Woman, Boy', keywords: 'family, woman, boys, sons, moms, mothers, families, relationships, women, love, children, females, relatives', val: '👩‍👦' },
  { title: 'Family: Woman, Boy, Boy', keywords: 'families, family, love, mothers, moms, women, woman, aunts, females, relatives, sons, boys, parents, children, relationships, brothers, siblings', val: '👩‍👦‍👦' },
  { title: 'Family: Woman, Girl', keywords: 'family, love, woman, girls, daughters, moms, mothers, families, women, woman, relationships, children, females, relatives', val: '👩‍👧' },
  { title: 'Family: Woman, Girl, Boy', keywords: 'families, family. love, mothers, moms, women, woman, females, males, girls, daughters, siblings, sons, boys, parents, children, relationships, brothers, sisters', val: '👩‍👧‍👦' },
  { title: 'Family: Woman, Girl, Girl', keywords: 'families, family, love, mothers, moms, women, woman, aunts, females, relatives, girls, daughters, sisters, parents, children, relationships, siblings', val: '👩‍👧‍👧' },
  { title: 'Family: Man, Man, Boy', keywords: 'families, family, love, fathers, dads, men, man, uncles, males, relatives, sons, boys, parents, children, relationships, brothers, siblings', val: '👨‍👨‍👦' },
  { title: 'Family: Man, Man, Boy, Boy', keywords: 'families, men, man, family, love, fathers, dads, uncles, males, relatives, sons, boys, parents, children, relationships, brothers, siblings', val: '👨‍👨‍👦‍👦' },
  { title: 'Family: Man, Man, Girl', keywords: 'families, family, love, fathers, dads, men, man, uncles, males, relatives, daughters, girls, parents, children, relationships, child', val: '👨‍👨‍👧' },
  { title: 'Family: Man, Man, Girl, Boy', keywords: 'families, men, man, family, love, fathers, dads, uncles, males, relatives, sons, boys, girls, daughters, parents, children, relationships, brothers, sisters, siblings', val: '👨‍👨‍👧‍👦' },
  { title: 'Family: Man, Man, Girl, Girl', keywords: 'families, men, man, family, love, fathers, dads, uncles, males, relatives, daughters, girls, parents, children, relationships, sisters, siblings', val: '👨‍👨‍👧‍👧' },
  { title: 'Family: Man, Woman, Boy, Boy', keywords: 'families, men, man, woman, women, family, love, fathers, dads, mothers, moms, females, males, relatives, sons, boys, parents, children, relationships, brothers, siblings', val: '👨‍👩‍👦‍👦' },
  { title: 'Family: Man, Woman, Girl', keywords: 'families, family, love, fathers, dads, mothers, moms, women, woman, men, man, females, males, relatives, daughters, girls, parents, children, relationships, child', val: '👨‍👩‍👧' },
  { title: 'Family: Man, Woman, Girl, Boy', keywords: 'families, men, man, women, woman, mom, mothers, family, love, fathers, dads, females, males, relatives, sons, boys, girls, daughters, parents, children, relationships, brothers, sisters, siblings', val: '👨‍👩‍👧‍👦' },
  { title: 'Family: Man, Woman, Girl, Girl', keywords: 'families, women, woman, family, love, mothers, moms, fathers, dads, females, males, relatives, daughters, girls, parents, children, relationships, sisters, siblings', val: '👨‍👩‍👧‍👧' },
  { title: 'Family: Woman, Woman, Boy', keywords: 'families, family, love, fathers, dads, mothers, moms, women, woman, men, man, females, males, relatives, sons, boys, parents, children, relationships', val: '👩‍👩‍👦' },
  { title: 'Family: Woman, Woman, Boy, Boy', keywords: 'families, women, woman, family, love, mothers, moms, aunts, sisterhood, females, relatives, sons, boys, parents, children, relationships, brothers, siblings', val: '👩‍👩‍👦‍👦' },
  { title: 'Family: Woman, Woman, Girl', keywords: 'families, women, woman, family, love, mothers, moms, aunts, sisterhood, females, relatives, daughters, girls, parents, children, relationships', val: '👩‍👩‍👧' },
  { title: 'Family: Woman, Woman, Girl, Boy', keywords: 'families, women, woman, family, love, mothers, moms, aunts, sisterhood, females, relatives, sons, boys, girls, daughters, parents, children, relationships, brothers, sisters, siblings', val: '👩‍👩‍👧‍👦' },
  { title: 'Family: Woman, Woman, Girl, Girl', keywords: 'families, women, woman, family, love, mothers, moms, aunts, sisterhood, females, relatives, daughters, girls, parents, children, relationships, sisters, siblings', val: '👩‍👩‍👧‍👧' },
  { title: 'Kiss', keywords: 'hearts, kisses, kissing, love, lips, romance, men, women, couples, dating, boyfriends, girlfriends, husbands, wives, marriage, romantic, relationships', val: '💏' },
  { title: 'Kiss: Woman, Man', keywords: 'hearts, kisses, kissing, love, lips, romance, men, women, couples, dating, boyfriends, girlfriends, husbands, wives, marriage, romantic, relationships', val: '👩‍❤️‍💋‍👨' },
  { title: 'Kiss: Man, Man', keywords: 'hearts, kisses, kissing, lips, love, romance, men, man, couples, dating, boyfriends, husbands, marriage, romantic, relationships', val: '👨‍❤️‍💋‍👨' },
  { title: 'Kiss: Woman, Woman', keywords: 'hearts, kisses, kissing, lips, love, romance, women, couples, dating, girlfriends, marriage, wives, romantic, relationships', val: '👩‍❤️‍💋‍👩' },
  { title: 'Couple with Heart', keywords: 'couples, marriage, hearts, dating, romance, relationships', val: '💑' },
  { title: 'Couple with Heart: Woman, Man', keywords: 'couples, hearts, men, women, marriage, romance, love', val: '👩‍❤️‍👨' },
  { title: 'Couple with Heart: Man, Man', keywords: 'couples, men, man, gay, hearts, boyfriends, husbands, romance, dating', val: '👨‍❤️‍👨' },
  { title: 'Couple with Heart: Woman, Woman', keywords: 'couples, hearts, love, woman, women, relationships, marriage, sisterhood, lesbian, romance, girlfriends, couple', val: '👩‍❤️‍👩' },
  { title: 'Person in Tuxedo', keywords: 'person, tuxedos, grooms, weddings, dressing, suits, events', val: '🤵' },
  { title: 'Man in Tuxedo', keywords: 'person, tuxedos, weddings, dressing, suits, events, man, men, male', val: '🤵‍♂️' },
  { title: 'Woman in Tuxedo', keywords: 'person, tuxedos, weddings, dressing, suits, events, woman, women, female', val: '🤵‍♀️' },
  { title: 'Person: White Hair', keywords: 'gender-neutral, adult, person, white, gray, senior, older, grandparent, hair, unspecified gender', val: '🧑‍🦳' },
  { title: 'Man: White Hair', keywords: 'white, gray, grey, hair, man, adults, seniors, grandfathers, elderly, grandpas, older, men, males, masculine', val: '👨‍🦳️' },
  { title: 'Woman: White Hair', keywords: 'white, gray, grey, hair, woman, adults, seniors, grandmothers, elderly, grandmas, older, women, females, feminine', val: '👩‍🦳️' },
  { title: 'Person: Curly Hair', keywords: 'curly, hair, frizzy, ringlets, wavy', val: '🧑‍🦱' },
  { title: 'Man: Curly Hair', keywords: 'man, adult, men, ringlets, afro, hair, curly, curls, wave, wavy, frizzy, frizzies', val: '👨‍🦱️' },
  { title: 'Woman: Curly Hair', keywords: 'woman, adult, women, ringlets, afro, hair, curly, curls, wave, wavy, frizzy, frizzies', val: '👩‍🦱️' },
  { title: 'Person: Bald', keywords: 'gender-neutral, unspecified gender, adult, person, bald, baldness, hairlessness, alopecia', val: '🧑‍🦲' },
  { title: 'Man: Bald', keywords: 'man, men, adult, baldness, bald, balding, hairlessness, male, pattern, alopecia', val: '👨‍🦲️' },
  { title: 'Woman: Bald', keywords: 'woman, women, adult, baldness, bald, balding, hairlessness, female, pattern, alopecia', val: '👩‍🦲️' },
  { title: 'Older Person', keywords: 'old, older, person, adults, seniors, citizens, retirees, retired, grandparents', val: '🧓' },
  { title: 'Older Man', keywords: 'white, gray, grey, bald, elderly, man, adults, seniors, grandfathers, grandpas, older, men, males, retirees, wrinkles, eyeglasses', val: '👴' },
  { title: 'Older Woman', keywords: 'old, adult, woman', val: '👵' },
  { title: 'Person: Red Hair', keywords: 'gender-neutral, adult, person, unspecified gender, red, ginger, color, redheads, hair', val: '🧑‍🦰' },
  { title: 'Man: Red Hair', keywords: 'red, hair, man, adult, men, beards, ginger, masculinity, males', val: '👨‍🦰️' },
  { title: 'Woman: Red Hair', keywords: 'red, hair, woman, adults, women, ginger, femininity, females', val: '👩‍🦰️' },
  { title: 'Person with Blond Hair', keywords: 'hair, blonde, blond-haired, person, blond, bleach', val: '👱' },
  { title: 'Man with Blond Hair', keywords: 'hair, blonde, blond-haired, person, blond, bleach, man, men, male', val: '👱‍♂️' },
  { title: 'Woman with Blond Hair', keywords: 'blondes, blond-haired, woman, female, beach, women, hair', val: '👱‍♀️' },
  { title: 'Person with Veil', keywords: 'person, veils, brides, weddings, married, marriages, white, proposals, engaged', val: '👰' },
  { title: 'Man with Veil', keywords: 'person, veils, weddings, married, marriages, white, proposals, engaged, man, men, male', val: '👰‍♂️' },
  { title: 'Woman with Veil', keywords: 'person, veils, brides, weddings, married, marriages, white, proposals, engaged, woman, women, female', val: '👰‍♀️' },
  { title: 'Mx Claus', keywords: 'Christmas, celebrations, dressing, costumes, Claus, Santa, chimneys, trees, presents, gifts, winter', val: '🧑‍🎄' },
  { title: 'Santa Claus', keywords: 'Christmas, celebrations, fathers, Claus, Santa, Santa, chimneys, trees, presents, gifts, beards, belly, winter, Claus, St., Nick, Saint', val: '🎅' },
  { title: 'Mrs. Claus', keywords: 'Christmas, celebrations, Claus, mother, winter, Mrs., Santa, presents, North Pole', val: '🤶' },
  { title: 'Person Gesturing OK', keywords: 'gestures, hands, person, OK, Okay, allowed, agreement, O, arms, positive, responses, yes', val: '🙆' },
  { title: 'Man Gesturing OK', keywords: 'gestures, hands, person, OK, Okay, allowed, agreement, O, arms, positive, responses, yes, man, men, male', val: '🙆‍♂️' },
  { title: 'Woman Gesturing OK', keywords: 'gestures, hands, person, OK, Okay, allowed, agreement, O, arms, positive, responses, yes, woman, women, female', val: '🙆‍♀️' },
  { title: 'Person Gesturing No', keywords: 'gestures, hands, person, NO, prohibited, disagreement, X, disagreements, arms, crossed, opposition, negative, responses', val: '🙅' },
  { title: 'Man Gesturing No', keywords: 'gestures, hands, person, NO, prohibited, disagreement, X, disagreements, arms, crossed, opposition, negative, responses, man, men, male', val: '🙅‍♂️' },
  { title: 'Woman Gesturing No', keywords: 'gestures, hands, person, NO, prohibited, disagreement, X, disagreements, arms, crossed, opposition, negative, responses, woman, women, female', val: '🙅‍♀️' },
  { title: 'Person Tipping Hand', keywords: 'information, sassy, hands, person, tipping, help, tipping', val: '💁' },
  { title: 'Man Tipping Hand', keywords: 'information, sassy, hands, person, tipping, help, tipping, man, men, male', val: '💁‍♂️' },
  { title: 'Woman Tipping Hand', keywords: 'information, sassy, hands, person, tipping, help, tipping, woman, women, female', val: '💁‍♀️' },
  { title: 'Person Raising Hand', keywords: 'happy, gestures, hands, raising, raised, person, classrooms, attention, greetings, hello', val: '🙋' },
  { title: 'Man Raising Hand', keywords: 'happy, gestures, hands, raising, raised, person, classrooms, attention, greetings, hello, man, men, male', val: '🙋‍♂️' },
  { title: 'Woman Raising Hand', keywords: 'happy, gestures, hands, raising, raised, person, classrooms, attention, greetings, hello, woman, women, female', val: '🙋‍♀️' },
  { title: 'Person Shrugging', keywords: 'ignorance, indifference, doubts, person, shrugging, shrugs, gesture, shoulders, body, language', val: '🤷' },
  { title: 'Man Shrugging', keywords: 'ignorance, indifference, doubts, person, shrugging, shrugs, gesture, shoulders, body, language, man, men, male', val: '🤷‍♂️' },
  { title: 'Woman Shrugging', keywords: 'ignorance, indifference, doubts, person, shrugging, shrugs, gesture, shoulders, body, language, woman, women, female', val: '🤷‍♀️' },
  { title: 'Person Frowning', keywords: 'gestures, frowns, person, frowning, unhappy, facial, expression, disappointed, concentrating', val: '🙍' },
  { title: 'Man Frowning', keywords: 'gestures, frowns, person, frowning, unhappy, facial, expression, disappointed, concentrating, man, men, male', val: '🙍‍♂️' },
  { title: 'Woman Frowning', keywords: 'gestures, frowns, person, frowning, unhappy, facial, expression, disappointed, concentrating, woman, women, female', val: '🙍‍♀️' },
  { title: 'Person Getting Massage', keywords: 'massages, faces, person, spa, masseuse, relaxation, stress', val: '💆' },
  { title: 'Man Getting Massage', keywords: 'massages, faces, person, spa, masseur, relaxation, stress, man, men, male', val: '💆‍♂️' },
  { title: 'Woman Getting Massage', keywords: 'massages, faces, person, spa, masseuse, relaxation, stress, woman, women, female', val: '💆‍♀️' },
  { title: 'Person Getting Haircut', keywords: 'haircuts, beauty, parlor, barbers, barbershops, person, trims, scissors, salons', val: '💇' },
  { title: 'Man Getting Haircut', keywords: 'haircuts, barbers, barbershops, stylists, person, trims, scissors, salons, man, men, male', val: '💇‍♂️' },
  { title: 'Woman Getting Haircut', keywords: 'haircuts, beauty, parlor, stylists, person, trims, scissors, salons, woman, women, female', val: '💇‍♀️' },
  { title: 'Person Pouting', keywords: 'person, pouting, gestures, expressions, sullen, scowling', val: '🙎' },
  { title: 'Man Pouting', keywords: 'person, pouting, gestures, expressions, sullen, scowling, man, men, male', val: '🙎‍♂️' },
  { title: 'Woman Pouting', keywords: 'person, pouting, gestures, expressions, sullen, scowling, woman, women, female', val: '🙎‍♀️' },
  { title: 'Person Facepalming', keywords: 'exasperation, palm, disbelief, face, person, facepalming, face, palms, palming, embarrassment, frustration, gestures', val: '🤦' },
  { title: 'Man Facepalming', keywords: 'exasperation, disbelief, person, facepalming, face, palms, palming, embarrassment, frustration, gestures, man, male', val: '🤦‍♂️' },
  { title: 'Woman Facepalming', keywords: 'exasperation, disbelief, face, person, facepalming, face, palms, palming, embarrassment, frustration, gestures, woman, females, women', val: '🤦‍♀️' },
  { title: 'Police Officer', keywords: 'officers, cops, police, crime, safety, protection, sheriffs, emergencies, transit, patrols, criminals, guns, community', val: '👮' },
  { title: 'Man Police Officer', keywords: 'officers, cops, police, policeman, policemen, crime, safety, protection, sheriffs, emergencies, transit, patrols, criminals, guns, community, man, men, male', val: '👮‍♂️' },
  { title: 'Woman Police Officer', keywords: 'officers, cops, police, policewoman, policewomen, crime, safety, protection, sheriffs, emergencies, transit, patrols, criminals, guns, community, woman, women, female', val: '👮‍♀️' },
  { title: 'Construction Worker', keywords: 'hats, workers, construction, builders, tools, scaffolds, heavy, buildings, constructors, professions', val: '👷' },
  { title: 'Man Construction Worker', keywords: 'hats, workers, construction, builders, tools, scaffolds, heavy, buildings, constructors, professions, man, male', val: '👷‍♂️' },
  { title: 'Woman Construction Worker', keywords: 'hats, workers, construction, builders, tools, scaffolds, heavy, buildings, constructors, professions, woman, women, female', val: '👷‍♀️' },
  { title: 'Deaf Person', keywords: 'deaf, person, deafness, ears, hearing, accessibility, aids, mute, listening', val: '🧏' },
  { title: 'Deaf Man', keywords: 'deaf, man, men, boys, male, deafness, ears, hearing, accessibility, aids, mute, listening', val: '🧏‍♂️' },
  { title: 'Deaf Woman', keywords: 'deaf, person, deafness, ears, hearing, accessibility, aids, mute, listening, woman, women, girls, females', val: '🧏‍♀️' },
  { title: 'Guard', keywords: 'guards, Great Britain, United Kingdom, uniforms, hats, tradition, royal, guardsmen, foot, professions', val: '💂' },
  { title: 'Man Guard', keywords: 'guards, Great Britain, United Kingdom, uniforms, hats, tradition, royal, guardsmen, foot, professions, man, male, men', val: '💂‍♂️' },
  { title: 'Woman Guard', keywords: 'guards, Great Britain, United Kingdom, uniforms, hats, tradition, royal, guardswomen, foot, professions, woman, women, female', val: '💂‍♀️' },
  { title: 'Detective', keywords: 'sleuths, detectives, spy, spies, investigators, investigations, police, professions', val: '🕵' },
  { title: 'Man Detective', keywords: 'sleuths, detectives, spy, spies, investigators, investigations, police, professions, man, male, men', val: '🕵️‍♂️' },
  { title: 'Woman Detective', keywords: 'sleuths, detectives, spy, spies, investigators, investigations, police, professions, woman, female, women', val: '🕵️‍♀️' },
  { title: 'Person Bowing', keywords: 'gesture, person, bowing, apology, apologize, performance, gratitude, prostrate, kneeling, bows, sorry', val: '🙇' },
  { title: 'Man Bowing', keywords: 'gesture, person, bowing, apology, apologize, performance, gratitude, prostrate, kneeling, bows, sorry, man, men, male', val: '🙇‍♂️' },
  { title: 'Woman Bowing', keywords: 'gesture, person, bowing, apology, apologize, performance, gratitude, prostrate, kneeling, bows, sorry, woman, female, women', val: '🙇‍♀️' },
  { title: 'Person Walking', keywords: 'walking, hiking, person, ambulatory, pacing, moving, exercise', val: '🚶' },
  { title: 'Man Walking', keywords: 'walking, hiking, person, ambulatory, pacing, moving, exercise, man, men, male', val: '🚶‍♂️' },
  { title: 'Woman Walking', keywords: 'walking, hiking, person, ambulatory, pacing, moving, exercise, woman, women, female', val: '🚶‍♀️' },
  { title: 'Person Running', keywords: 'person, running, runners, runs, jogs, joggers, sports, athletics, marathons, escaping, rushing, jogging', val: '🏃' },
  { title: 'Man Running', keywords: 'person, running, runners, runs, jogs, joggers, sports, athletics, marathons, escaping, rushing, jogging, man, men, male', val: '🏃‍♂️' },
  { title: 'Woman Running', keywords: 'person, running, runners, runs, jogs, joggers, sports, athletics, marathons, escaping, rushing, jogging, woman, women, female', val: '🏃‍♀️' },
  { title: 'Mage', keywords: 'sorceress, sorcerers, wizards, mages, witches, warlocks, magicians, magic, supernatural, powers', val: '🧙' },
  { title: 'Man Mage', keywords: 'sorcerers, wizards, mages, warlocks, magicians, magic, supernatural, powers, man, men, male', val: '🧙‍♂️' },
  { title: 'Woman Mage', keywords: 'sorceress, wizards, mages, witches, magicians, magic, supernatural, powers, woman, women, female', val: '🧙‍♀️' },
  { title: 'Fairy', keywords: 'Oberon, Titania, Puck, fairy, fairies, Peter Pan, magical beings, humanoid, elves, supernatural, fairy, tales, story, godmothers', val: '🧚' },
  { title: 'Man Fairy', keywords: 'Oberon, Titania, Puck, fairy, fairies, Peter Pan, magical beings, humanoid, elves, supernatural, fairy, tales, story, godfathers, man, men, male', val: '🧚‍♂️' },
  { title: 'Woman Fairy', keywords: 'Oberon, Titania, Puck, fairy, fairies, Peter Pan, magical beings, humanoid, elves, supernatural, fairy, tales, story, godmothers, woman, women, female', val: '🧚‍♀️' },
  { title: 'Vampire', keywords: 'undead, Dracula, vampires, blood, sucking, fangs, necks, coffins, bats evil', val: '🧛' },
  { title: 'Man Vampire', keywords: 'undead, Dracula, vampires, blood, sucking, fangs, necks, coffins, bats evil, man, men, male', val: '🧛‍♂️' },
  { title: 'Woman Vampire', keywords: 'undead, Dracula, vampires, vampiress, blood, sucking, fangs, necks, coffins, bats evil, woman, women, female', val: '🧛‍♀️' },
  { title: 'Merperson', keywords: 'merman, mermen, mermaids, merperson, merwoman, legends, mythical, sailors, oceans, half, sirena', val: '🧜' },
  { title: 'Merman', keywords: 'Triton, mermen, merman, legends, mythical, sailors, oceans, half, sirens, men, males, man, boys', val: '🧜‍♂️' },
  { title: 'Mermaid', keywords: 'mermaids, merwoman, legends, mythical, sailors, oceans, half, sirena, women, females, woman, girls', val: '🧜‍♀️' },
  { title: 'Elf', keywords: 'magical, elf, elves, Christmas, Santa Claus, cute, fairy, fairies, magical, supernatural, powers', val: '🧝' },
  { title: 'Man Elf', keywords: 'magical, elf, elves, Christmas, Santa Claus, cute, fairy, fairies, magical, supernatural, powers, man, men, male', val: '🧝‍♂️' },
  { title: 'Woman Elf', keywords: 'magical, elf, elves, Christmas, Santa Claus, cute, fairy, fairies, magical, supernatural, powers, woman, women, female', val: '🧝‍♀️' },
  { title: 'Genie', keywords: 'genies, djinn, Aladdin, lamps, three, wishes, magic, luck, profession, fairytales, myths, carpets', val: '🧞' },
  { title: 'Man Genie', keywords: 'genies, djinn, Aladdin, lamps, three, wishes, magic, luck, profession, fairytales, myths, carpets, man, men, male', val: '🧞‍♂️' },
  { title: 'Woman Genie', keywords: 'genies, djinn, Aladdin, lamps, three, wishes, magic, luck, profession, fairytales, myths, carpets, woman, women, female', val: '🧞‍♀️' },
  { title: 'Person in Steamy Room', keywords: 'sauna, steam, room, person, steamy, relaxation, saunas, spas, gyms, stress', val: '🧖' },
  { title: 'Man in Steamy Room', keywords: 'sauna, steam, room, steamroom, person, steamy, relaxation, saunas, spas, gyms, stress, man, men, male', val: '🧖‍♂️' },
  { title: 'Woman in Steamy Room', keywords: 'sauna, steam, room, person, steamy, relaxation, saunas, spas, gyms, stress, woman, women, female', val: '🧖‍♀️' },
  { title: 'Person Wearing Turban', keywords: 'turbans, head, covering, hats, religion, religious, Muslim, Sikh', val: '👳' },
  { title: 'Man Wearing Turban', keywords: 'turbans, head, covering, hats, religion, religious, Muslim, Sikh, man, men, male', val: '👳‍♂️' },
  { title: 'Woman Wearing Turban', keywords: 'turbans, head, covering, hats, religion, religious, Muslim, Sikh, woman, women, female, cancer, bald', val: '👳‍♀️' },
  { title: 'Person Climbing', keywords: 'person, climbing, climbers, rocks, cliffs, walls, outdoors, dangerous, sports, ascending, up, ladders', val: '🧗' },
  { title: 'Man Climbing', keywords: 'person, climbing, climbers, rocks, cliffs, walls, outdoors, dangerous, sports, ascending, up, ladders, man, male', val: '🧗‍♂️' },
  { title: 'Woman Climbing', keywords: 'person, climbing, climbers, rocks, cliffs, walls, outdoors, dangerous, sports, ascending, up, ladders, woman, women, female', val: '🧗‍♀️' },
  { title: 'Person in Lotus Position', keywords: 'person, lotus, position, meditation, yoga, exercise', val: '🧘' },
  { title: 'Man in Lotus Position', keywords: 'person, lotus, position, meditation, yoga, exercise, man, men, male', val: '🧘‍♂️' },
  { title: 'Woman in Lotus Position', keywords: 'person, lotus, position, meditation, yoga, exercise, woman, women, female', val: '🧘‍♀️' },
  { title: 'Zombie', keywords: 'walking, undead, zombies, disease, dead, movies, horror, monsters, imaginary', val: '🧟' },
  { title: 'Man Zombie', keywords: 'walking, undead, zombies, disease, dead, movies, horror, monsters, imaginary, man, men, male', val: '🧟‍♂️' },
  { title: 'Woman Zombie', keywords: 'walking, undead, zombies, disease, dead, movies, horror, monsters, imaginary, woman, women, female', val: '🧟‍♀️' },
  { title: 'Superhero', keywords: 'heroines, good, heroes, superheroes, powers, strong, superpowers, cartoons, comics', val: '🦸' },
  { title: 'Man Superhero', keywords: 'good, heroes, superheroes, powers, strong, superpowers, cartoons, comics, man, men, male', val: '🦸‍♂️' },
  { title: 'Woman Superhero', keywords: 'heroines, good, heroes, superheroes, powers, strong, superpowers, cartoons, comics, woman, women, female', val: '🦸‍♀️' },
  { title: 'Supervillain', keywords: 'superpower, criminal, supervillain, evil, villain', val: '🦹' },
  { title: 'Man Supervillain', keywords: 'superpower, criminal, supervillain, evil, villain, man, men, male', val: '🦹‍♂️' },
  { title: 'Woman Supervillain', keywords: 'superpower, criminal, supervillain, villainess, evil, villain, woman, women, female', val: '🦹‍♀️' },
  { title: 'People with Bunny Ears', keywords: 'dancers, bunny, bunnies, ears, partying, people, costumes, cosplay', val: '👯' },
  { title: 'Men with Bunny Ears', keywords: 'dancers, bunny, bunnies, ears, partying, people, costumes, cosplay, Playgirl, man, men, male, boys', val: '👯‍♂️' },
  { title: 'Women with Bunny Ears', keywords: 'dancers, bunny, bunnies, ears, partying, people, costumes, cosplay, Playboy, woman, women, female, girls', val: '👯‍♀️' },
  { title: 'Person Mountain Biking', keywords: 'cyclists, bicyclists, mountains, bicycles, bikes, person, outdoors', val: '🚵' },
  { title: 'Man Mountain Biking', keywords: 'cyclists, bicyclists, mountains, bicycles, bikes, person, outdoors, man, men, male', val: '🚵‍♂️' },
  { title: 'Woman Mountain Biking', keywords: 'cyclists, bicyclists, mountains, bicycles, bikes, person, outdoors, woman, women, female', val: '🚵‍♀️' },
  { title: 'Person Biking', keywords: 'bicycles, cyclists, athletes, person, biking, exercise, outdoor, activities, transportation, bike, races, riding', val: '🚴' },
  { title: 'Man Biking', keywords: 'bicycles, cyclists, athletes, person, biking, exercise, outdoor, activities, transportation, bike, races, riding, man, men, male', val: '🚴‍♂️' },
  { title: 'Woman Biking', keywords: 'bicycles, cyclists, athletes, person, biking, exercise, outdoor, activities, transportation, bike, races, riding, woman, women, female', val: '🚴‍♀️' },
  { title: 'Person with Ball', keywords: 'person, bouncing, balls, basketball, dribbling, sports, athletes, exercise, games, holding, playing', val: '⛹' },
  { title: 'Man with Ball', keywords: 'person, bouncing, balls, basketball, dribbling, sports, athletes, exercise, games, holding, playing, man, men, male', val: '⛹️‍♂️' },
  { title: 'Woman with Ball', keywords: 'person, bouncing, balls, basketball, dribbling, sports, athletes, exercise, games, holding, playing, woman, women, female', val: '⛹️‍♀️' },
  { title: 'Person Swimming', keywords: 'swim, person, swimming, swimmers, pools, water, exercise, aquatic, oceans', val: '🏊' },
  { title: 'Man Swimming', keywords: 'swim, person, swimming, swimmers, pools, water, exercise, aquatic, oceans, boys, man, men, male', val: '🏊‍♂️' },
  { title: 'Woman Swimming', keywords: 'swim, person, swimming, swimmers, pools, water, exercise, aquatic, oceans, girls, woman, women, female', val: '🏊‍♀️' },
  { title: 'Person Surfing', keywords: 'surfing, person, surfers, surfboards, sports, waves, water, aquatic, beaches, balance, sports', val: '🏄' },
  { title: 'Man Surfing', keywords: 'surfing, person, surfers, surfboards, sports, waves, water, aquatic, beaches, balance, sports, man, men, male', val: '🏄‍♂️' },
  { title: 'Woman Surfing', keywords: 'surfing, person, surfers, surfboards, sports, waves, water, aquatic, beaches, balance, sports, woman, women, female', val: '🏄‍♀️' },
  { title: 'Person Juggling', keywords: 'jugglers, balance, multitasking, person, juggling, skills, circus', val: '🤹' },
  { title: 'Man Juggling', keywords: 'jugglers, balance, multitasking, person, juggling, skills, circus, man, men, male', val: '🤹‍♂️' },
  { title: 'Woman Juggling', keywords: 'jugglers, balance, multitasking, person, juggling, skills, circus, woman, women, female', val: '🤹‍♀️' },
  { title: 'Person Lifting Weights', keywords: 'person, lifting, weights, lifter, weights, fitness, exercise', val: '🏋' },
  { title: 'Man Lifting Weights', keywords: 'person, lifting, weights, lifter, weights, fitness, weightlifter, exercise, man, men, male', val: '🏋️‍♂️' },
  { title: 'Woman Lifting Weights', keywords: 'person, lifting, weights, lifter, weights, fitness, weightlifter, exercise, woman, women, female', val: '🏋️‍♀️' },
  { title: 'Person Golfing', keywords: 'golf, person, golfing, balls, clubs, courses, outdoor, activities, miniature, PGA, swings, tees', val: '🏌' },
  { title: 'Man Golfing', keywords: 'golf, person, golfing, balls, clubs, courses, outdoor, activities, miniature, PGA, swings, tees, man, men, male', val: '🏌️‍♂️' },
  { title: 'Woman Golfing', keywords: 'golf, person, golfing, balls, clubs, courses, outdoor, activities, miniature, PGA, swings, tees, woman, women, female', val: '🏌️‍♀️' },
  { title: 'Person Cartwheeling', keywords: 'cartwheels, person, gymnasts, gymnastics, acrobats, acrobatics, cartwheeling', val: '🤸' },
  { title: 'Man Cartwheeling', keywords: 'cartwheels, person, gymnasts, gymnastics, acrobats, acrobatics, cartwheeling, man, men, male', val: '🤸‍♂️' },
  { title: 'Woman Cartwheeling', keywords: 'cartwheels, person, gymnasts, gymnastics, acrobats, acrobatics, cartwheeling, woman, women, female', val: '🤸‍♀️' },
  { title: 'Person Rowing Boat', keywords: 'person, rowing, boats, rowboats, water, transportation, oars, paddles, lakes', val: '🚣' },
  { title: 'Man Rowing Boat', keywords: 'person, rowing, boats, rowboats, water, transportation, oars, paddles, lakes, man, men, male', val: '🚣‍♂️' },
  { title: 'Woman Rowing Boat', keywords: 'person, rowing, boats, rowboats, water, transportation, oars, paddles, lakes, woman, women, female', val: '🚣‍♀️' },
  { title: 'Person Playing Water Polo', keywords: 'polo, water, person, aquatic, sports, playing, summer, swimming, pools, polo', val: '🤽' },
  { title: 'Man Playing Water Polo', keywords: 'polo, water, person, aquatic, sports, playing, summer, swimming, pools, polo, man, men, male', val: '🤽‍♂️' },
  { title: 'Woman Playing Water Polo', keywords: 'polo, water, person, aquatic, sports, playing, summer, swimming, pools, polo, woman, women, female', val: '🤽‍♀️' },
  { title: 'Person Playing Handball', keywords: 'handball, balls, person, playing, football, rugby, racquetball', val: '🤾' },
  { title: 'Man Playing Handball', keywords: 'handball, balls, person, playing, football, rugby, racquetball, man, men, male', val: '🤾‍♂️' },
  { title: 'Woman Playing Handball', keywords: 'handball, balls, person, playing, football, rugby, racquetball, woman, women, female', val: '🤾‍♀️' },
  { title: 'Person Feeding Baby', keywords: 'feeding, baby, babies, bottles, infants, nursing, person', val: '🧑‍🍼' },
  { title: 'Man Feeding Baby', keywords: 'feeding, baby, babies, bottles, infants, nursing, person, man, males, fathers, dads, grandfathers, grandpas, uncles', val: '👨‍🍼' },
  { title: 'Woman Feeding Baby', keywords: 'feeding, baby, babies, bottles, infants, nursing, person, woman, females, women, mothers, grandmothers, grandmas, aunts, moms', val: '👩‍🍼' },
  { title: 'Student', keywords: 'students, graduates, learning, school, studying, classrooms', val: '🧑‍🎓' },
  { title: 'Man Student', keywords: 'students, graduates, learning, school, studying, classrooms, man, men, male', val: '👨‍🎓' },
  { title: 'Woman Student', keywords: 'students, graduates, learning, school, studying, classrooms, woman, women, female', val: '👩‍🎓' },
  { title: 'Singer', keywords: 'entertainer, singer, actor, rock, star, karaoke, performers', val: '🧑‍🎤' },
  { title: 'Man Singer', keywords: 'entertainer, singer, actor, rock, star, karaoke, performers, man, men, male', val: '👨‍🎤' },
  { title: 'Woman Singer', keywords: 'entertainer, singer, actor, rock, star, karaoke, performers, woman, women, female', val: '👩‍🎤' },
  { title: 'Cook', keywords: 'cooks, chefs, food, restaurants, sous, cooking, profession, cuisine', val: '🧑‍🍳' },
  { title: 'Man Cook', keywords: 'man, men, male, cook, chef, cooks, chefs, food, restaurants, sous, cooking, profession, cuisine', val: '👨‍🍳' },
  { title: 'Woman Cook', keywords: 'cooks, chefs, food, restaurants, sous, cooking, profession, cuisine, woman, female, women', val: '👩‍🍳' },
  { title: 'Firefighter', keywords: 'firetrucks, firefighters, fires, emergency, emergencies, brave, strong, courageous, hospitals, professions', val: '🧑‍🚒' },
  { title: 'Man Firefighter', keywords: 'firetrucks, fireman, firefighters, fires, emergency, emergencies, brave, strong, courageous, hospitals, professions, man, male', val: '👨‍🚒' },
  { title: 'Woman Firefighter', keywords: 'firetrucks, firefighters, fires, emergency, emergencies, brave, strong, courageous, hospitals, professions, woman, women, female', val: '👩‍🚒' },
  { title: 'People Holding Hands', keywords: 'people, men, women, couples, holding, hands, dating, courting, boyfriends, girlfriends, romantic, relationships', val: '🧑‍🤝‍🧑' },
  { title: 'Woman and Man Holding Hands', keywords: 'woman, man, holding, hands, women, men, , relationships, dating, romance, boyfriends, girlfriends, marriage, couple', val: '👫' },
  { title: 'Men Holding Hands', keywords: 'holding, hands, couples, men, man, twins,zodiac', val: '👬' },
  { title: 'Women Holding Hands', keywords: 'holding hands, hand, women, couples, lesbians, sisterhood, sisters, dating, girlfriends, relationships, romance', val: '👭' },
  { title: 'Person in Manual Wheelchair', keywords: 'manual, wheelchairs, accessibility, disabled, handicapped, person', val: '🧑‍🦽' },
  { title: 'Man in Manual Wheelchair', keywords: 'manual, wheelchairs, accessibility, disabled, handicapped, person, man, men, male', val: '👨‍🦽' },
  { title: 'Woman in Manual Wheelchair', keywords: 'manual, wheelchairs, accessibility, disabled, handicapped, person, woman, women, female', val: '👩‍🦽' },
  { title: 'Man in Motorized Wheelchair', keywords: 'motorized, wheelchairs, accessibility, disabled, person, handicapped, transportation, man, men male', val: '👨‍🦼' },
  { title: 'Woman in Motorized Wheelchair', keywords: 'motorized, wheelchairs, accessibility, disabled, person, handicapped, transportation, woman, women, female', val: '👩‍🦼' },
  { title: 'Person in Motorized Wheelchair', keywords: 'motorized, wheelchairs, accessibility, disabled, person, handicapped, transportation', val: '🧑‍🦼' },
  { title: 'Artist', keywords: 'palettes, artists, art, painters, paint, easels, professions, painting', val: '🧑‍🎨' },
  { title: 'Man Artist', keywords: 'palettes, artists, art, painters, paint, easels, professions, painting, man, men, male', val: '👨‍🎨' },
  { title: 'Woman Artist', keywords: 'palettes, artists, art, painters, paint, easels, professions, painting, woman, women, female', val: '👩‍🎨' },
  { title: 'Person with White Cane', keywords: 'blind, person, visually impaired, accessibility, white cane, red tip', val: '🧑‍🦯' },
  { title: 'Man with White Cane', keywords: 'blind, person, visually impaired, accessibility, white cane, red tip, man, men, male', val: '👨‍🦯' },
  { title: 'Woman with White Cane', keywords: 'blind, person, visually impaired, accessibility, white cane, red tip, woman, women, female', val: '👩‍🦯' },
  { title: 'Astronaut', keywords: 'rockets, ships, astronauts, space, travel, professions, scientists', val: '🧑‍🚀' },
  { title: 'Man Astronaut', keywords: 'rockets, ships, astronauts, space, travel, professions, scientists, man, men, male', val: '👨‍🚀' },
  { title: 'Woman Astronaut', keywords: 'rockets, ships, astronauts, space, travel, professions, scientists, woman, women, female', val: '👩‍🚀' },
  { title: 'Farmer', keywords: 'gardeners, ranchers, farms, farmers, agriculture, crops, growing, vegetables, animals, profession', val: '🧑‍🌾' },
  { title: 'Man Farmer', keywords: 'gardeners, ranchers, farms, farmers, agriculture, crops, growing, vegetables, animals, profession, man, male', val: '👨‍🌾' },
  { title: 'Woman Farmer', keywords: 'gardeners, ranchers, farms, farmers, agriculture, crops, growing, vegetables, animals, profession, woman, females, women', val: '👩‍🌾' },
  { title: 'Judge', keywords: 'scales, judges, justice, lawsuits, lawyers, legal, professions', val: '🧑‍⚖️' },
  { title: 'Man Judge', keywords: 'scales, judges, justice, lawsuits, lawyers, legal, professions, man, men, male', val: '👨‍⚖️' },
  { title: 'Woman Judge', keywords: 'scales, judges, justice, lawsuits, lawyers, legal, professions, woman, women, female', val: '👩‍⚖️' },
  { title: 'Mechanic', keywords: 'plumber, tradesperson, mechanics, electricians, auto repair, tools, cars, professions', val: '🧑‍🔧' },
  { title: 'Man Mechanic', keywords: 'plumber, tradesperson, mechanics, electricians, auto repair, tools, cars, professions, man, men, male', val: '👨‍🔧' },
  { title: 'Woman Mechanic', keywords: 'plumber, tradesperson, mechanics, electricians, auto repair, tools, cars, profession, woman, women, female', val: '👩‍🔧' },
  { title: 'Office Worker', keywords: 'managers, architects, businesses, offices, professionals, administrative, staff, workers, white-collar', val: '🧑‍💼' },
  { title: 'Man Office Worker', keywords: 'managers, architects, businesses, offices, professionals, administrative, staff, workers, white-collar, man, men, male', val: '👨‍💼' },
  { title: 'Woman Office Worker', keywords: 'managers, architects, businesses, offices, professionals, administrative, staff, workers, white-collar, secretary, woman, women, female', val: '👩‍💼' },
  { title: 'Health Worker', keywords: 'therapists, healthcare, health, workers, nurses, doctors, paramedics, professions, emergencies, medical', val: '🧑‍⚕️' },
  { title: 'Man Health Worker', keywords: 'therapists, healthcare, health, workers, nurses, doctors, paramedics, professions, emergencies, medical, man, men, male', val: '👨‍⚕️' },
  { title: 'Woman Health Worker', keywords: 'therapists, healthcare, health, workers, nurses, doctors, paramedics, professions, emergencies, medical, woman, women, female', val: '👩‍⚕️' },
  { title: 'Technologist', keywords: 'developer, coder, software, inventor, technologist, information, computers, technology, Internet, programming', val: '🧑‍💻' },
  { title: 'Man Technologist', keywords: 'developer, coder, software, inventor, technologist, information, computers, technology, Internet, programming, man, men, male', val: '👨‍💻' },
  { title: 'Woman Technologist', keywords: 'developer, coder, software, inventor, technologist, information, computers, technology, Internet, programming, woman, women, female', val: '👩‍💻' },
  { title: 'Scientist', keywords: 'chemists, biologists, physicists, engineers, scientists, investigators, investigations, discovery, sciences, inventors', val: '🧑‍🔬' },
  { title: 'Man Scientist', keywords: 'chemists, biologists, physicists, engineers, scientists, investigators, investigations, discovery, sciences, inventors, man, men,male', val: '👨‍🔬' },
  { title: 'Woman Scientist', keywords: 'chemists, biologists, physicists, engineers, scientists, investigators, investigations, discovery, sciences, inventors, woman, women, female', val: '👩‍🔬' },
  { title: 'Teacher', keywords: 'professors, instructor, teacher, schools, education, learning, students, classrooms, profession', val: '🧑‍🏫' },
  { title: 'Man Teacher', keywords: 'professors, instructor, teacher, schools, education, learning, students, classrooms, profession, man, men, male', val: '👨‍🏫' },
  { title: 'Woman Teacher', keywords: 'professors, instructor, teacher, schools, education, learning, students, classrooms, profession, woman, women, female', val: '👩‍🏫' },
  { title: 'Factory Worker', keywords: 'factory, factories, industrial, workers, production, manufacturing, industry, assembly', val: '🧑‍🏭' },
  { title: 'Man Factory Worker', keywords: 'factory, factories, industrial, workers, production, manufacturing, industry, assembly, man, men, male', val: '👨‍🏭' },
  { title: 'Woman Factory Worker', keywords: 'factory, factories, industrial, workers, production, manufacturing, industry, assembly, woman, women, females', val: '👩‍🏭' },
  { title: 'Pilot', keywords: 'planes, pilots, airplanes, flights, flying, cockpits, airports, transportation, travel', val: '🧑‍✈️' },
  { title: 'Man Pilot', keywords: 'planes, pilots, airplanes, flights, flying, cockpits, airports, transportation, travel, man, men, male', val: '👨‍✈️' },
  { title: 'Woman Pilot', keywords: 'planes, pilots, airplanes, flights, flying, cockpits, airports, transportation, travel, woman, women, female', val: '👩‍✈️' },
  { title: 'Pine Decoration', keywords: 'celebration, pine, pine decoration, Japanese, bamboo, plants, gate', val: '🎍' },
  { title: 'School Satchel', keywords: 'school, satchels, knapsacks, rucksacks, book bags, small, backpacks, purses, accessories, bags, luggage, travel, packing, outings', val: '🎒' },
  { title: 'Graduation Cap', keywords: 'clothing, celebrations, graduations, caps, hats, diplomas, degrees, scholarships, schools, education, scholastic, achievements', val: '🎓' },
  { title: 'Carp Streamer', keywords: 'carp, fish, children, holidays, Japan, May, streamers', val: '🎏' },
  { title: 'Fireworks', keywords: 'firecrackers, fireworks, dynamite, explosive, explosions, celebrations, independence days, New Year\'s Eve, July 4th, Chinese New Year', val: '🎆' },
  { title: 'Firework Sparkler', keywords: 'sparklers, safe, fireworks, bright, sparkly, celebrations, independence days, New Year\'s Eve, July 4th, Chinese New Year', val: '🎇' },
  { title: 'Wind Chime', keywords: 'wind, chime, bells, breezes, soft, musical', val: '🎐' },
  { title: 'Moon Viewing Ceremony', keywords: 'moon, moon viewing ceremony, ceremony, celebration, Japan', val: '🎑' },
  { title: 'Jack-o-lantern', keywords: 'Halloween, autumn, jack-o-lanterns, celebration, lanterns, scary, spooky, pumpkins, October, fall', val: '🎃' },
  { title: 'Christmas Tree', keywords: 'Christmas, evergreen, pine trees, Noel, Santa Claus, Merry Christmas, presents, gifts, ornaments, holidays, celebrations, winter, happiness, family', val: '🎄' },
  { title: 'Wrapped Present', keywords: 'presents, gifts, celebrations, wrapped, boxes, parties, surprises, birthday', val: '🎁' },
  { title: 'Tanabata Tree', keywords: 'tanabata tree, tree, Japanese, banner, love, celebration', val: '🎋' },
  { title: 'Party Popper', keywords: 'party, popper, celebrations, parties, joy, happiness, excitement, congratulations, birthday', val: '🎉' },
  { title: 'Confetti Ball', keywords: 'celebrations, confetti, balls, parties, celebrations, ornaments, congratulations, birthdays, weddings, paper', val: '🎊' },
  { title: 'Balloon', keywords: 'balloons, parties, special events, birthdays, children, celebrations, popping, hot air, helium', val: '🎈' },
  { title: 'Crossed Flags', keywords: 'crossed flags, crossed, cross, Japanese, Japan, holidays, celebrations, cars, races', val: '🎌' },
  { title: 'Crystal Ball', keywords: 'fairy tales, fortune, fantasy, crystal balls, magic, future, wizards, witches, intuition, round', val: '🔮' },
  { title: 'Movie Camera', keywords: 'projectors, frames, movies, cinema, cinematography, recording, photography, cameras, Hollywood, film, video', val: '🎥' },
  { title: 'Camera', keywords: 'videos, cameras, cellphones, photographs, pictures, photos, portraits, landscapes, images, photography, posing, poses', val: '📷' },
  { title: 'Video Camera', keywords: 'videos, cameras, filming, film, cinematography, photography', val: '📹' },
  { title: 'Videocassette', keywords: 'videocassettes, videos, VHS, outdated, technology, tapes, cassettes', val: '📼' },
  { title: 'Optical Disc', keywords: 'optical discs, DVDs, CDs, blu-ray, optical drives, disks, computers, outdated technology, movies, games, data storage', val: '💿' },
  { title: 'DVD', keywords: 'computer, blu-ray, optical, disk, DVDs, CDs, drives, computers, games, movies, data, storage', val: '📀' },
  { title: 'Minidisc', keywords: 'minidisks, minidiscs, disk, optical, computers, storage, data, outdated, technology', val: '💽' },
  { title: 'Floppy Disk', keywords: 'disks, floppy, computers, floppy disks, desktops, towers, data, storage, outdated, technology', val: '💾' },
  { title: 'Personal Computer', keywords: 'laptops, notebooks, technology, personal, computers, PCs', val: '💻' },
  { title: 'Mobile Phone', keywords: 'lphones, telephones, mobiles, cells, cell phones, communication', val: '📱' },
  { title: 'Telephone', keywords: 'telephone, phones, communication, cell phones, talking, calls', val: '☎️' },
  { title: 'Telephone Receiver', keywords: 'telephone, phone, receivers, ongoing, phones, calls', val: '📞' },
  { title: 'Pager', keywords: 'pagers, messages, texts, communication, outdated technology', val: '📟' },
  { title: 'Satellite', keywords: 'outer, space, satellites, communication, signals, GPS, weather, orbits, wireless', val: '🛰' },
  { title: 'Fax Machine', keywords: 'fax machine, faxes, technology, old, sending, receiving, outdated', val: '📠' },
  { title: 'Satellite Antenna', keywords: 'satellites, dishes, antennas, satellite dishes, communication, signals, television, radio, Internet, broadcasting, streaming, cellular, wireless, technology', val: '📡' },
  { title: 'Television', keywords: 'tv, televisions, videos, programs, media', val: '📺' },
  { title: 'Radio', keywords: 'radios, music, talk shows, programs, programming, audio, equipment, listening, DJs, deejays, shortwave, tuning, records, albums', val: '📻' },
  { title: 'Speaker with Three Sound Waves', keywords: 'speaker high volume, loud, speakers, volume, devices', val: '🔊' },
  { title: 'Speaker with One Sound Wave', keywords: 'speaker low volume, low, speaker, volume, medium', val: '🔉' },
  { title: 'Speaker', keywords: 'speaker, device, volume, sound, audio', val: '🔈' },
  { title: 'Speaker with Cancellation Stroke', keywords: 'muted speaker, mute, silent, quiet, speaker', val: '🔇' },
  { title: 'Bell', keywords: 'bell, alarms, bells, reminders, wake-up calls, loud noises, church bells, ringing', val: '🔔' },
  { title: 'Bell with Cancellation Stroke', keywords: 'bell with slash, mute, forbidden, bell, quiet, silent', val: '🔕' },
  { title: 'Public Address Loudspeaker', keywords: 'loudspeakers, loud, public addresses, megaphones, crowds, public speaking, announcements, cheerleaders, cheers, team, spirit', val: '📢' },
  { title: 'Cheering Megaphone', keywords: 'megaphones, cheering, cheers, announcements, microphones, public, speaking, team spirit, cheerleaders', val: '📣' },
  { title: 'Hourglass with Flowing Sand', keywords: 'hourglass not done, hourglass, sand, timer, hours, deadlines, time, passing', val: '⏳' },
  { title: 'Hourglass', keywords: 'hourglass, hour done, timers, eggs, deadlines, time, sand', val: '⌛️' },
  { title: 'Alarm Clock', keywords: 'clocks, alarms, wake up, morning, timers, buzzers, deadlines, bells', val: '⏰' },
  { title: 'Watch', keywords: 'watches, wristwatches, time, alarms, clocks', val: '⌚️' },
  { title: 'Open Lock', keywords: 'open, unlock, unlocked, lock, keys, padlocks, insecure, security, breach', val: '🔓' },
  { title: 'Lock', keywords: 'closed, locked, locks, combinations, keys, secure, safe, security, secrets, padlocks', val: '🔒' },
  { title: 'Lock with Ink Pen', keywords: 'locked with pen, locks, pens, nibs, privacy, ink, keys, closed, locked, secure, safe, security, secrets, padlocks', val: '🔏' },
  { title: 'Closed Lock with Key', keywords: 'locked with key, closed, locked, locks, keys, secure, safe, security, secrets, under lock and key, padlocks', val: '🔐' },
  { title: 'Key', keywords: 'locks, keys, doors, cars, boxes, passwords', val: '🔑' },
  { title: 'Right-Pointing Magnifying Glass', keywords: 'magnifying, glasses, right, searches, searching, tools, investigate, investigating, investigations, online search, detectives, inquiry, inquiring, inquiries', val: '🔎' },
  { title: 'Electric Light Bulb', keywords: 'electric, lights, bulbs, ideas, electric, electricity, power, wattage, brightness, illumination, lamps', val: '💡' },
  { title: 'Electric Torch', keywords: 'flashlights, lights, illumination, tool, electric, torches, nighttime, batteries, camping, darkness', val: '🔦' },
  { title: 'High Brightness Symbol', keywords: 'bright button, bright, brightness, screens, computers, cellphones, tablets, televisions', val: '🔆' },
  { title: 'Low Brightness Symbol', keywords: 'dim button, dim, low, brightness, low brightness, dimming, dimmers, buttons, televisions, computers, cellphones, tablets, electronic, devices, screens', val: '🔅' },
  { title: 'Electric Plug', keywords: 'electricity, electric, plug, power, wires, sockets', val: '🔌' },
  { title: 'Battery', keywords: 'battery, batteries, charging, charge, energy, recharge, battery acid', val: '🔋' },
  { title: 'Left-Pointing Magnifying Glass', keywords: 'magnifying, glasses, left, searches, searching, tools, investigate, investigating, investigations, online search, detectives, inquiry, inquiring, inquiries', val: '🔍' },
  { title: 'Bathtub', keywords: 'bathtubs, baths, bathrooms, bath time, showers, washing, soaking, luxury, rubber duckies, relaxing, cleanliness, water', val: '🛁' },
  { title: 'Person Taking Bath', keywords: 'bathtub, bathing, person, baths, bathrooms, water, showers, washing, relaxing, clean, soaking', val: '🛀' },
  { title: 'Shower', keywords: 'water, showers, shower stalls, water, baths, bathrooms, bathtubs, bath time, washing, getting clean, cooling down', val: '🚿' },
  { title: 'Toilet', keywords: 'toilets, bathrooms, potty, peeing, pooping', val: '🚽' },
  { title: 'Wrench', keywords: 'wrenches, tools, spanners, nuts, mechanics', val: '🔧' },
  { title: 'Nut and Bolt', keywords: 'nut and bolt, nuts, bolts, tools, construction, building, screws', val: '🔩' },
  { title: 'Hammer', keywords: 'tools, hammers, construction, nails, repairs, work', val: '🔨' },
  { title: 'Door', keywords: 'doors, entrances, entryways, exits, closed, open', val: '🚪' },
  { title: 'Smoking Symbol', keywords: 'smoking, cigarettes, smokers', val: '🚬' },
  { title: 'Bomb', keywords: 'comic bomb', val: '💣' },
  { title: 'Pistol', keywords: 'squirt, water, cap, guns, pistols, revolvers, handguns, weapons, tools', val: '🔫' },
  { title: 'Knife', keywords: 'kitchen knife, knives, hocho, cooking, weapons, tools, Japanese, Japan', val: '🔪' },
  { title: 'Pill', keywords: 'medicines, medications, prescriptions, illnesses, sick, doctors, pills', val: '💊' },
  { title: 'Syringe', keywords: 'needles, medicines, syringes, sick, shots, injections, vaccinations', val: '💉' },
  { title: 'Money Bag', keywords: 'money, moneybag, dollar, bag, cash, wealth, coins, riches', val: '💰' },
  { title: 'Banknote with Yen Sign', keywords: 'yen, currency, currencies, money, notes, bills, banknotes, finance, economics, economy, purchases, shopping, success, financial, transactions, exchange rates, Japan, Asia', val: '💴' },
  { title: 'Banknote with Dollar Sign', keywords: 'dollar currency,money money note bill banknote', val: '💵' },
  { title: 'Banknote with Pound Sign', keywords: 'pounds, currency, currencies, money, notes, bills, banknotes, finance, economics, economy, purchases, shopping, success, financial, transactions, exchange rates, United Kingdom, Great Britain', val: '💷' },
  { title: 'Banknote with Euro Sign', keywords: 'euros, currency, currencies, money, notes, bills, banknotes, finance, economics, economy, purchases, shopping, success, financial, transactions, exchange rates, Europe, European', val: '💶' },
  { title: 'Credit Card', keywords: 'money, credit, card, credit card, shopping, debit, wealth, online, purchases, plastic', val: '💳' },
  { title: 'Money with Wings', keywords: 'money, money with wings, waste, lost, wings, banknote, bill, fly', val: '💸' },
  { title: 'Mobile Phone with Rightwards Arrow at Left', keywords: 'mobile phone with arrow, mobile, phone, arrow, receive, incoming, cell phones, telephones, communication, devices', val: '📲' },
  { title: 'E-Mail Symbol', keywords: 'letters, emails, e-mails, mail, reminders, @, inboxes, you\'ve got mail', val: '📧' },
  { title: 'Inbox Tray', keywords: 'letter, inboxes, mail, emails, tray, box, receive', val: '📥' },
  { title: 'Outbox Tray', keywords: 'outbox, tray, mail, letter, box, sent, emails, outgoing, sending', val: '📤' },
  { title: 'Envelope', keywords: 'envelopes, mail, stamps, letters, cards, post offices, mailboxes, email, sending, seals, receiving', val: '✉️' },
  { title: 'Envelope with Downwards Arrow Above', keywords: 'envelope with arrow, email, e-mails, envelopes, electronic, arrows, outgoing', val: '📩' },
  { title: 'Incoming Envelope', keywords: 'envelopes, incoming, email, e-mail, letters, receive', val: '📨' },
  { title: 'Postal Horn', keywords: 'post, postal, horns, mail, delivery, brass, instruments, insignia, Europe', val: '📯' },
  { title: 'Closed Mailbox with Raised Flag', keywords: 'closed mailbox with raised flag, mailboxes, postboxes, mail, delivery, postal, letters, raised, flag, closed', val: '📫' },
  { title: 'Closed Mailbox with Lowered Flag', keywords: 'closed mailbox with lowered flag, mailbox, postbox, lowered, mail, closed', val: '📪' },
  { title: 'Open Mailbox with Raised Flag', keywords: 'open, open mailbox with raised flag, mail, mailbox, postbox, outgoing, pickup', val: '📬' },
  { title: 'Open Mailbox with Lowered Flag', keywords: 'mailbox, mail, open, open mailbox with lowered flag, lowered, postbox, incoming, delivery', val: '📭' },
  { title: 'Postbox', keywords: 'mailboxes, mail, postboxes, delivery, deliveries, email, postal service', val: '📮' },
  { title: 'Package', keywords: 'packages, boxes, parcels, gifts, deliveries, packed', val: '📦' },
  { title: 'Memo', keywords: 'pencils, memos, notes, lists, reminders, jotting', val: '📝' },
  { title: 'Page Facing Up', keywords: 'page, page facing up, paper, writing, printer, document', val: '📄' },
  { title: 'Page with Curl', keywords: 'page, page with curl, document, curl, business, sheet, paper, writing', val: '📃' },
  { title: 'Bookmark Tabs', keywords: 'marks, bookmarks, marker, tabs, placeholder', val: '📑' },
  { title: 'Bar Chart', keywords: 'chart graph bar', val: '📊' },
  { title: 'Chart with Upwards Trend', keywords: 'chart increasing, growth, chart, graphs, upwards, trends, increase, numbers', val: '📈' },
  { title: 'Chart with Downwards Trend', keywords: 'chart decreasing, graphs, down, charts, trends, downwards, numbers, losses', val: '📉' },
  { title: 'Scroll', keywords: 'scrolls, papers, ancient, papyrus, parchment, writing, old, historical, documents', val: '📜' },
  { title: 'Clipboard', keywords: 'clipboards, forms, writing, hard surface, public notice, studying, journalists, journalism, information, boards, pens, signing', val: '📋' },
  { title: 'Calendar', keywords: 'dates, calendars, days, months, weeks, planners, schedules, scheduling, agendas, organizers, annual', val: '📅' },
  { title: 'Tear-Off Calendar', keywords: 'tear-off calendar, pads, schedules, planning, calendars', val: '📆' },
  { title: 'Card Index', keywords: 'Rolodex, index, cards, phone numbers, addresses, data, business cards', val: '📇' },
  { title: 'File Folder', keywords: 'folders, files, cabinets, electronic files, offices, computer', val: '📁' },
  { title: 'Open File Folder', keywords: 'open, folders, files, cabinets, electronic files, offices, computer', val: '📂' },
  { title: 'Scissors', keywords: 'scissors, cutting, paper, arts, crafts, household, tools, haircuts, office, supplies, hobbies, sewing, origami, school projects, gardening', val: '✂️' },
  { title: 'Pushpin', keywords: 'pushpins, bulletins, boards, announcements, pins, office, supplies', val: '📌' },
  { title: 'Paperclip', keywords: 'paperclips, paperclips, administrative assistants, secretaries, papers, offices, supplies', val: '📎' },
  { title: 'Fountain Pen', keywords: 'nibs, pens, tips, fountain pens, pens, writing, writers, education, ink, inkwells, tips, calligraphy, signatures, signing', val: '✒️' },
  { title: 'Pencil', keywords: 'pencils, writing, lead, notes, class', val: '✏️' },
  { title: 'Straight Ruler', keywords: 'straight edges, straight rulers, rulers, measurements, inches, centimeters', val: '📏' },
  { title: 'Triangular Ruler', keywords: 'triangle, triangular ruler, set, ruler, architects', val: '📐' },
  { title: 'Closed Book', keywords: 'books, closed, reading, libraries, librarians, studying, school, textbooks, words, pages, authors, writers', val: '📕' },
  { title: 'Green Book', keywords: 'green, books, reading, libraries, librarians, studying, school, textbooks, words, pages, authors, writers, green', val: '📗' },
  { title: 'Blue Book', keywords: 'books, blue, blue book, cars, automobiles, value, pricing, statistics, almanacs, reading, libraries, blue, bookworms', val: '📘' },
  { title: 'Orange Book', keywords: 'orange, books, reading, libraries, librarians, studying, school, textbooks, words, pages, authors, writers', val: '📙' },
  { title: 'Notebook', keywords: 'notebooks, notes, books, compositions, school, homework, writing, studying, journaling', val: '📓' },
  { title: 'Notebook with Decorative Cover', keywords: 'notebooks, covers, decorative, decorated, sketchbooks, drawing, notebooks, notes, books, school, writing, diaries, journaling', val: '📔' },
  { title: 'Ledger', keywords: 'notebooks, ledgers, finance, money, financial, records, reports, data, transactions, spiral-bound, spirals, journals, yellow', val: '📒' },
  { title: 'Books', keywords: 'books, book, reading, libraries, librarians, studying, school, textbooks, words, pages, authors, writers', val: '📚' },
  { title: 'Open Book', keywords: 'open, books, honest, reading, studying, textbooks, pages, writers, writing, authors, words', val: '📖' },
  { title: 'Bookmark', keywords: 'marks, bookmark, books, placeholders, reading', val: '🔖' },
  { title: 'Name Badge', keywords: 'names, badges, hello', val: '📛' },
  { title: 'Microscope', keywords: 'tools, microscopes, microbes, slides, science, medicine, investigation, research, laboratories, medical labs, culture, germs, magnify, magnification', val: '🔬' },
  { title: 'Telescope', keywords: 'telescope, tool, science, outer space, stars', val: '🔭' },
  { title: 'Newspaper', keywords: 'newspapers, newsprint, print media, and journalism', val: '📰' },
  { title: 'Artist Palette', keywords: 'artist palettes, museums, paintings, palettes, artistic, paints, artists, painters, artwork, easels, portraits, landscapes, acrylics, oils, Rembrandt, Monet, Botticelli', val: '🎨' },
  { title: 'Clapper Board', keywords: 'clapper boards, clappers, movies, actors, filming, films, action, cut, directors', val: '🎬' },
  { title: 'Microphone', keywords: 'mic, microphone, mikes, hosting, public speaking, audiovisual, volume, amplifying, sound, performing, singing, acting, stand-up, comedy, karaoke', val: '🎤' },
  { title: 'Headphone', keywords: 'headphones, earbuds, music, listening, recording, studios, noise, blocking', val: '🎧' },
  { title: 'Musical Score', keywords: 'musical score, music, scores, instruments, songs, singing', val: '🎼' },
  { title: 'Musical Note', keywords: 'musical note, note, music, songs, singing, scores, instruments', val: '🎵' },
  { title: 'Multiple Musical Notes', keywords: 'musical notes, music, note, notes, singing, songs, scores', val: '🎶' },
  { title: 'Musical Keyboard', keywords: 'keyboards, pianos, pianists, organs, accordions, music, musical notes, scores, songs, singing, concerts', val: '🎹' },
  { title: 'Violin', keywords: 'violins, instruments, music, strings, classical music, orchestras', val: '🎻' },
  { title: 'Trumpet', keywords: 'music, trumpets, instruments, horns, brass, jazz', val: '🎺' },
  { title: 'Saxophone', keywords: 'saxophones, sax, music, musicians, instruments, saxophones, horns, brass, symphonies, jazz, orchestras, concerts', val: '🎷' },
  { title: 'Guitar', keywords: 'guitars, instruments, acoustic, music, songs, singer, strings, chords, tunes, rhythms, electric, unplugged, concerts', val: '🎸' },
  { title: 'Alien Monster', keywords: 'extraterrestrials, monsters, UFOs, creatures, aliens, face', val: '👾' },
  { title: 'Video Game', keywords: 'video games, games, controllers, controls, gamers', val: '🎮' },
  { title: 'Playing Card Black Joker', keywords: 'playing cards, decks, jokers, games, casinos, blackjack, wildcards, black', val: '🃏' },
  { title: 'Flower Playing Cards', keywords: 'flower playing cards, game, playing, card, Japanese, flowers, Japan, hanafuda', val: '🎴' },
  { title: 'Mahjong Tile Red Dragon', keywords: 'mahjong red dragon, mahjong, red, game, China, Chinese, pairs', val: '️🀄️' },
  { title: 'Amphora', keywords: 'Aquarius, zodiac, amphora, cooking, drink, jugs, relics, home decor, collectibles, museums, artifacts', val: '🏺' },
  { title: 'Prayer Beads', keywords: 'prayers, beads, strings, necklaces, Middle East, religions, religious, rosaries, rosary, Catholicism, Buddhists, Buddhism, Muslim, Middle East, Islam, Hinduism, Hindi, Eastern, pastimes, worry beads', val: '📿' },
  { title: 'Kaaba', keywords: 'Kaaba, Muslim, Islam, Mecca, Saudi Arabia, religion, pilgrimage', val: '🕋' },
  { title: 'Hammer and Pick', keywords: 'hammer and pick, hammers, picks, tools, nails, mines, mining, Minecraft, rocks', val: '⚒️' },
  { title: 'Crossed Swords', keywords: 'swords, fencing, weapons, battles, fighting, crossed, metal', val: '⚔️' },
  { title: 'Scales', keywords: 'scales, balances, weights, law, courts, Libra, zodiac, justice', val: '⚖️' },
  { title: 'Alembic', keywords: 'chemistry, alembic, tools, containers, round', val: '⚗️' },
  { title: 'Gear', keywords: 'cogwheel, cog, gears, tools, machinery, settings', val: '⚙️' },
  { title: 'Keyboard', keywords: 'keyboards, computers', val: '⌨️' },
  { title: 'Dark Sunglasses', keywords: 'eyewear, sunglasses, glasses, dark, eyes, sun, sunny, protection, cool', val: '🕶' },
  { title: 'Stopwatch', keywords: 'stopwatches, clocks, timers, alarms, buzzers', val: '⏱' },
  { title: 'Timer Clock', keywords: 'timers, clocks, time, stopwatches, alarms', val: '⏲' },
  { title: 'Umbrella', keywords: 'umbrellas, raingear, clothing, rain, rainy, raining', val: '☂️' },
  { title: 'Fleur-de-Lis', keywords: 'fleur-de-lis, fleur-de-lys, iris, flower, royal, royalty, heritage, France, French, European, heraldry', val: '⚜️' },
  { title: 'Coffin', keywords: 'coffins, death, sadness, mourning, loss, burials, funerals, haunted, Dracula, Halloween', val: '⚰️' },
  { title: 'Funeral Urn', keywords: 'death, funerals, ashes, urns, cremation, cremate, grief', val: '⚱️' },
  { title: 'Pick', keywords: 'picks, tools, mining, rocks, mines, miners, construction', val: '⛏' },
  { title: 'Helmet with White Cross', keywords: 'helmets, rescues, crosses, safety, Japanese, hard hats, hazards, warnings, aid, hats, face', val: '⛑' },
  { title: 'Chains', keywords: 'chains, links, tools, weapons, security', val: '⛓' },
  { title: 'Shinto Shrine', keywords: 'shinto, shrines, religions, spirits, Asian, Japan, Japanese', val: '⛩' },
  { title: 'Umbrella on Ground', keywords: 'umbrellas, umbrella on ground, beach, picnic, vacation, rain, sun', val: '⛱' },
  { title: 'Slot Machine', keywords: 'slot machines, slots, games, casinos, online casinos, Las Vegas, gambling', val: '🎰' },
  { title: 'Top Hat', keywords: 'hats, top hats, tophats, clothing, magicians, men\'s', val: '🎩' },
  { title: 'Crown', keywords: 'crowns, queens, kings, princes, princesses, royalty, palaces, headwear, gold, jewels, clothing', val: '👑' },
  { title: 'Woman\'s Hat', keywords: 'woman\'s hats, woman, women\'s, hats, clothing, headwear', val: '👒' },
  { title: 'Briefcase', keywords: 'briefcases, documents, satchels, businessmen, businesswomen, meetings, offices, papers, briefs, cases', val: '💼' },
  { title: 'Handbag', keywords: 'purses, bags, handbags, pocketbooks, wallets, designers, fashion, accessories', val: '👜' },
  { title: 'Pouch', keywords: 'clutches, bags, pouches, clothing, hand, hands, handbag, purses, pocketbooks', val: '👝' },
  { title: 'Purse', keywords: 'purses, coins, clutches, pocketbooks, handbags, small, bag', val: '👛' },
  { title: 'Eyeglasses', keywords: 'eyeglasses, eyewear, eyes, spectacles, reading, glasses', val: '👓' },
  { title: 'Ribbon', keywords: 'gifts, presents, special, occasions, celebrations, surprises, hair, pretty, cute', val: '🎀' },
  { title: 'Closed Umbrella', keywords: 'umbrellas, closed umbrellas, rain, rainy, rain gear, clothing, winter', val: '🌂' },
  { title: 'Lipstick', keywords: 'lipstick, cosmetics, makeup, lip, gloss, color, women, fashion, dressing, getting ready, style, beauty, cosmetics, red', val: '💄' },
  { title: 'Ring', keywords: 'rings, jewelry, diamonds, gems, gold, silver, engagement, marriage, proposals, marital', val: '💍' },
  { title: 'Gem Stone', keywords: 'gemstone, gems, jewel, stones, riches, wealth, treasure, diamond', val: '💎' },
  { title: 'Footprints', keywords: 'footprint, footprints, prints, feet, foot, walking, exploring, beaches, environment, carbon footprints, shoes', val: '👣' },
  { title: 'Thermometer', keywords: 'weather, thermometers, fevers, cold, heat, temperatures', val: '🌡' },
  { title: 'Military Medal', keywords: 'medals, celebration, armed forces, bravery, valor, courage, awards, military', val: '🎖' },
  { title: 'Reminder Ribbon', keywords: 'reminders, ribbons, celebrations, red, pink, awareness, breasts, cancer', val: '🎗' },
  { title: 'Studio Microphone', keywords: 'performing, singing, acting, stand-up, comedy, karaoke', val: '🎙' },
  { title: 'Level Slider', keywords: 'music, level sliders, sliders, music, knobs, controls, mixing, boards, recording, recording studios', val: '🎚' },
  { title: 'Control Knobs', keywords: 'knobs, music, controls, mixing, recording, studios', val: '🎛' },
  { title: 'Film Frames', keywords: 'film, frames, movies,videos, cinema, cinematography, recording, photography, cameras, Hollywood', val: '🎞' },
  { title: 'Admission Tickets', keywords: 'admission tickets, admissions, tickets, entry, boarding, transportation', val: '🎟' },
  { title: 'Sports Medal', keywords: 'sports medals, medals, Olympics, awards, athletes, athletic, celebrations', val: '🏅' },
  { title: 'Snow Capped Mountain', keywords: 'snow-capped mountain, snow, mountains, cold, high altitudes, cold climates, freezing, mountain ranges, Swiss Alps, winter', val: '🏔' },
  { title: 'Camping', keywords: 'camping, tents, outdoor activities, family fun, wilderness, campsites, holidays, vacations, adventure', val: '🏕' },
  { title: 'Beach with Umbrella', keywords: 'beach umbrellas, umbrellas, beaches, summers, hot weather, vacations, holidays, relaxation', val: '🏖' },
  { title: 'Rosette', keywords: 'rosettes, plants, ribbons, flowers, awards, military, appreciation, certificates', val: '🏵' },
  { title: 'Label', keywords: 'paper, cardboard, strings, labels, shopping, prices, tags, sales, clothing', val: '🏷' },
  { title: 'Camera with Flash', keywords: 'camera with flash, flashes, cameras, classic, flashes, flashbulbs, cellphones, photographs, pictures, videos, photos, portraits, landscapes, images, posing, poses, photography', val: '📸' },
  { title: 'Film Projector', keywords: 'projectors, frames, movies, cinema, cinematography, recording, photography, cameras, Hollywood, film, video', val: '📽' },
  { title: 'Om Symbol', keywords: 'om, religions, Hindu, Buddhism, Buddhists, Jainism, Indian, India, yoga, mantra, chant', val: '🕉' },
  { title: 'Dove of Peace', keywords: 'doves, fly, birds, peace, white, Christian, Jesus Christ, religious, symbol, pigeons, love', val: '🕊' },
  { title: 'Candle', keywords: 'lights, candles, religion, prayers, wax, wicks, birthday', val: '🕯' },
  { title: 'Mantelpiece Clock', keywords: 'mantelpiece clock, clocks, mantels, vintage, home, decor, time', val: '🕰' },
  { title: 'Hole', keywords: 'holes, craters, manholes', val: '🕳' },
  { title: 'Spider Web', keywords: 'webs, spiders, Halloween, creepy, bugs, arachnids', val: '🕸' },
  { title: 'Joystick', keywords: 'joysticks, games, video games', val: '🕹' },
  { title: 'Pile of Poo', keywords: 'pile of poo, human waste, poo, poop, poops, poopies, caca, doody, doodies, feces, bowel movements, dung, shit, dung, face, brown', val: '💩' },
  { title: 'Linked Paperclips', keywords: 'linked paperclips, paperclips, links, administrative assistants, secretaries, papers, chains, offices, supplies', val: '🖇' },
  { title: 'Lower Left Ballpoint Pen', keywords: 'pens, ballpoints, left, writing, writers, signing, signatures, writing instruments', val: '🖊' },
  { title: 'Lower Left Fountain Pen', keywords: 'fountain pens, writing, ink, inkwells, writing instruments, signing, signatures, autographs', val: '🖋' },
  { title: 'Lower Left Paintbrush', keywords: 'paintbrushes, brushes, painting, art, artwork, portraits, artists, landscapes, paints, palettes, oils, acrylics, waterpainting, easels', val: '🖌' },
  { title: 'Lower Left Crayon', keywords: 'crayons, coloring, art, drawing, school, pictures, artwork', val: '🖍' },
  { title: 'Desktop Computer', keywords: 'desktops, computers, technology', val: '🖥' },
  { title: 'Printer', keywords: 'printers, paper, computers, laser, inkjets, copies, copying, printing, graphics', val: '🖨' },
  { title: 'Three Button Mouse', keywords: 'computer mouse, computers, PCs, outdated, desktops', val: '🖱' },
  { title: 'Trackball', keywords: 'trackballs, computers, mouses, mice, tracking, devices', val: '🖲' },
  { title: 'Frame with Picture', keywords: 'frames, pictures, art, artists, artwork, decor, decorating, interior design, painting, museums', val: '🖼' },
  { title: 'Card Index Dividers', keywords: 'dividers, index, cards, separators, boxes, alphabetical, data', val: '🗂' },
  { title: 'Card File Box', keywords: 'cards, files, boxes, addresses, phone numbers, business cards', val: '🗃' },
  { title: 'File Cabinet', keywords: 'files, filing, cabinets, offices, electronic file cabinets', val: '🗄' },
  { title: 'Wastebasket', keywords: 'wastebaskets, trash, paper, bins, garbage, pails, offices', val: '🗑' },
  { title: 'Spiral Note Pad', keywords: 'spiral notepads, spirals, notes, pads, notebooks', val: '🗒' },
  { title: 'Spiral Calendar Pad', keywords: 'spirals, calendars, pads, planning, schedules', val: '🗓' },
  { title: 'Compression', keywords: 'compression, devices, construction, building, tools, vices, clamps, metal, wood', val: '🗜' },
  { title: 'Old Key', keywords: 'key, lock, old, clue, keys, doors, chests, vintage items, wardrobes, closets, locked', val: '🗝' },
  { title: 'Rolled-up Newspaper', keywords: 'newspapers, papers, news, rolled-up newspapers, rolled, rolls, journalism, print media, reading, newsprint', val: '🗞' },
  { title: 'Dagger Knife', keywords: 'knife, weapons, knives, stabbing, sharp, daggers', val: '🗡' },
  { title: 'Ballot Box with Ballot', keywords: 'ballot box with ballot, boxes, voting, elections, making decisions, choosing, choices, electing, politics, ballots', val: '🗳' },
  { title: 'World Map', keywords: 'world, maps, continents, oceans, globe, earth', val: '🗺' },
  { title: 'Couch and Lamp', keywords: 'couch and lamp, hotel, lamps, couches, living rooms, dens, family, relaxation, reading', val: '🛋' },
  { title: 'Person in Bed', keywords: 'person, beds, hotels, sleeping, bedrooms, night, blankets, pillows, asleep, snoring, rest', val: '🛌' },
  { title: 'Shopping Bags', keywords: 'shopping, shopping bags, supermarkets, gifts, bags, groceries', val: '🛍' },
  { title: 'Bellhop Bell', keywords: 'bellhops, bells, hotels, restaurants, notify, notifications, sounds, attention, assistance', val: '🛎' },
  { title: 'Bed', keywords: 'sleep, beds, hotels, bedtime, sleep, tiredness, exhaustion, exhausted, night, rest, nighttime, bedrooms', val: '🛏' },
  { title: 'Hammer and Wrench', keywords: 'hammer and wrench, wrenches, hammers, spanners, tools, construction, repairs, nails, screws, nuts, bolts', val: '🛠' },
  { title: 'Shield', keywords: 'weapons, shields, protection, protective, riots, gear, antivirus, weaponry', val: '🛡' },
  { title: 'Oil Drum', keywords: 'oil, drums, crude, toxic, liquid, explosive, rigs, mines, shale', val: '🛢' },
  { title: 'Athletic Shoe', keywords: 'running shoes, sneakers, athletic, sports, clothing, shoes, footwear', val: '👟' },
  { title: 'Man\'s Shoe', keywords: 'man\'s shoe, men, men\'s, man, shoes, clothing, footwear, feet, fashion, style, clothes, clothing', val: '👞' },
  { title: 'Woman\'s Sandal', keywords: 'sandals, shoes, woman\'s sandals, woman, summer, clothing', val: '👡' },
  { title: 'High-Heeled Shoe', keywords: 'high-heeled shoes, heels, shoes, women, footwear, fashion, heels, clothing, woman', val: '👠' },
  { title: 'Woman\'s Boots', keywords: 'woman\'s boots, boots, womans, shoe, clothing, women\'s boots', val: '👢' },
  { title: 'T-Shirt', keywords: 'shirts, t-shirts, tshirts, clothing, tees, casual clothes', val: '👕' },
  { title: 'Necktie', keywords: 'neckties, ties, menswear, men, accessories, apparel, formal, suits, clothing', val: '👔' },
  { title: 'Blouse', keywords: 'women\'s clothes, woman, women, clothing, clothes, fashion, apparel, dressing', val: '👚' },
  { title: 'Dress', keywords: 'dress, clothing, dresses, fashion, apparel, clothes, clothing, style, beauty, women, woman, girls, shopping', val: '👗' },
  { title: 'Running Shirt with Sash', keywords: 'running shirts, running, runners, joggers, jogging, shirts, sashes, athletic wear, sports, apparel, races, racers, racing, marathons, track, Olympics', val: '🎽' },
  { title: 'Jeans', keywords: 'jeans, trousers, clothing, pants, denim, comfortable, casual, clothes', val: '👖' },
  { title: 'Kimono', keywords: 'kimonos, clothing, Japan, Japanese, women, Asia, dresses, silk, traditional, apparel', val: '👘' },
  { title: 'Bikini', keywords: 'bikinis, bathing suits, women, summer, clothing, pools, beaches, oceans, activities, sunbathing, swimming, vacations', val: '👙' },
  { title: 'Izakaya Lantern', keywords: 'Izakaya, red, red paper lanterns, lights, bars, Japan, Japanese, Asia, Asian', val: '🏮' },
  { title: 'Circus Tent', keywords: 'circuses, tents, circus, animals, clowns, circus acts, crazy, fun, wacky', val: '🎪' },
  { title: 'Performing Arts', keywords: 'performing, performing arts, masks, theater, theatre, arts, drama, comedy', val: '🎭' },
  { title: 'Round Pushpin', keywords: 'round pushpins, pushpins, pins, locations, maps, office, supplies, announcements, bulletin boards', val: '📍' },
  { title: 'Fuel Pump', keywords: 'fuelpump, gas, gasoline, fuel, pumps, gas stations, cars, travel, transportation, vehicles, diesel', val: '⛽️' },
  { title: 'Clinking Glasses', keywords: 'clinking glasses, clinking, drinks, champagne, congratulations, cheers, toasts, New Years Eve, wine, celebrations, glasses, celebrate', val: '🥂' },
  { title: 'Tumbler Glass', keywords: 'tumblers, glasses, whisky, shots, liquor', val: '🥃' },
  { title: 'Spoon', keywords: 'tableware, spoons, utensils, flatware, eating', val: '🥄' },
  { title: 'Drum With Drumsticks', keywords: 'drums, drumsticks, instruments, music, bands, drummers, percussion, orchestras, symphonies, marching bands, parades', val: '🥁' },
  { title: 'Shopping Trolley', keywords: 'shopping, trolley, carts, stores, supermarkets, groceries, grocery, stores', val: '🛒' },
  { title: 'Brain', keywords: 'intelligent, intelligence, thought, thinking, brain, brain, skull, anatomy, cerebral', val: '🧠' },
  { title: 'Scarf', keywords: 'scarf, scarves, cold, snow, winter, accessories, clothing, neck', val: '🧣' },
  { title: 'Gloves', keywords: 'hands, gloves, accessories, winter, cold, clothes', val: '🧤' },
  { title: 'Coat', keywords: 'jackets, coats, outerwear, winter, snow, freezing, overcoats', val: '🧥' },
  { title: 'Socks', keywords: 'feet, socks', val: '🧦' },
  { title: 'Billed Cap', keywords: 'billed cap, baseball cap, sports, sports apparel, teams, basketball, players', val: '🧢' },
  { title: 'Compass', keywords: 'orienteering, magnetic, navigation, compass, compasses, hiking, directions, maps, travel, adventure, magnetic poles, lost, exploration', val: '🧭️' },
  { title: 'Brick', keywords: 'Bricks, homes, houses, construction, concrete, buildings, materials, fireplaces, ovens', val: '🧱️' },
  { title: 'Luggage', keywords: 'packing, luggage, travelling, vacations, leisure, suitcases, airports', val: '🧳️' },
  { title: 'Firecracker', keywords: 'firecracker, fireworks, dynamite, explosive, explosions, celebrations, independence days, New Year\'s Eve, July 4th, Chinese New Year', val: '🧨️' },
  { title: 'Red Envelope', keywords: 'hóngbāo, good luck, lai see, red, envelopes, money, gifts', val: '🧧️' },
  { title: 'Softball', keywords: 'softballs, gloves, underarm, balls, games, baseball', val: '🥎️' },
  { title: 'Flying Disc', keywords: 'ultimate, flying discs, Frisbees, lawn, outdoors, summer, beaches', val: '🥏️' },
  { title: 'Lacrosse', keywords: 'goal, lacrosse, balls, sticks, sports, games', val: '🥍️' },
  { title: 'Nazar Amulet', keywords: 'nazar, nazar amulet, evil-eye, charm, talisman, bead, religion, Muslim, Islam, Middle East, religion', val: '🧿️' },
  { title: 'Puzzle Piece', keywords: 'pieces, puzzles, board games, jigsaws, brainteasers, interlocking, puzzled, confused, clues', val: '🧩️' },
  { title: 'Teddy Bear', keywords: 'teddy bears, stuffed, toy, plaything, children, plush', val: '🧸️' },
  { title: 'Chess Pawn', keywords: 'chess, pawns, dupes, expendable, strategy, strategic, board games, checkers, game pieces', val: '♟️' },
  { title: 'Thread', keywords: 'sewing, spools, threads, needles, strings, mending', val: '🧵' },
  { title: 'Yarn', keywords: 'knitting, crocheting, yarn, ball, wool, kittens', val: '🧶' },
  { title: 'Goggles', keywords: 'goggles, eye protection, construction, welding, safety glasses', val: '🥽' },
  { title: 'Lab Coat', keywords: 'lab coats, hospitals, laboratories, medicine, medical, facilities, research, experiments, doctors, physicians, scientists, disease, illness, pandemics, coronavirus, Covid-19', val: '🥼' },
  { title: 'Hiking Boot', keywords: 'hiking, camping, backpacking, boots, hikes, low, shoes, comfort, footwear, walking, outdoor, camping, backpacking, climbing, mountaineering', val: '🥾' },
  { title: 'Flat Shoe', keywords: 'flat shoes, slip-ons, women, girls, shoes, low heels, ballet flats, flats, comfort, walking, footwear, slippers, comfortable', val: '🥿️' },
  { title: 'Abacus', keywords: 'calculations, abacus, mathematics, computers, accounting, counting', val: '🧮️' },
  { title: 'Receipt', keywords: 'receipts, bills, cash, registers, shopping, proof, purchases, taxes, accounting, bookkeeping, financial, records', val: '🧾️' },
  { title: 'Toolbox', keywords: 'toolboxes, mechanic, tools, chest, plumbers', val: '🧰️' },
  { title: 'Magnet', keywords: 'horseshoe shape, U-shape, magnetic, magnet, attraction, positive, negative, charges, poles, magnetism', val: '🧲️' },
  { title: 'Test Tube', keywords: 'test tube, experiment, science, lab, chemistry, chemist', val: '🧪️' },
  { title: 'Petri Dish', keywords: 'petri dishes, biologists, biology, bacteria, lab, cultures, laboratories, laboratory, science, fungi, microbes', val: '🧫️' },
  { title: 'DNA', keywords: 'DNA, genetics, genes, life, biologists, evolution', val: '🧬️' },
  { title: 'Lotion Bottle', keywords: 'lotion bottles, shampoos, sunscreen, moisturizers, lotions, bottles, conditioners, body wash, creams, sunscreen, packaging', val: '🧴️' },
  { title: 'Safety Pin', keywords: 'punk rock, safety pins, diapers, pins, fasteners', val: '🧷️' },
  { title: 'Broom', keywords: 'Brooms, sweeping, cleaning, floors, witches', val: '🧹️' },
  { title: 'Basket', keywords: 'Baskets, woven, carrying, containers, picnics, laundry, groceries, crafts, weave', val: '🧺️' },
  { title: 'Roll of Paper', keywords: 'roll of paper, toilet paper, paper towels, cleaning, wipes, wiping, sanitary, bathrooms, kitchens', val: '🧻️' },
  { title: 'Soap', keywords: 'soaps, soap dish, bathing, cleaning, cleanliness, washing, bars, lathers, showering, showers, baths, slippery', val: '🧼️' },
  { title: 'Sponge', keywords: 'sponge, cleaning, absorbing, porous, wiping', val: '🧽️' },
  { title: 'Fire Extinguisher', keywords: 'fire extinguisher, stoves, kitchens, fires, flames, extinguish, barbecues, quench', val: '🧯️' },
  { title: 'Barber Pole', keywords: 'haircuts, poles, barbers, hair, barbershops', val: '💈' },
  { title: 'Baby Bottle', keywords: 'milk, drinks, bottles, babies, baby, infants, nipples', val: '🍼' },
  { title: 'Roller Skate', keywords: 'skates, roller, rinks, derbies, exercise, sports', val: '🛼' },
  { title: 'Teapot', keywords: 'teapots, pots, teas, drinks, beverages, hot', val: '🫖' },
  { title: 'Magic Wand', keywords: 'magic wands, magic, wizards, witches, wands, magicians, fairy godmothers', val: '🪄' },
  { title: 'Military Helmet', keywords: 'military, helmets, soldiers, army, armies, warriors, wars, armed forces', val: '🪖' },
  { title: 'Mirror', keywords: 'speculums, mirrors, reflection, glass, reflectors', val: '🪞' },
  { title: 'Mouse Trap', keywords: 'mousetrap, mouse, traps, snares, bait, rats, rodents', val: '🪤' },
  { title: 'Nesting Dolls', keywords: 'nesting dolls, Russian, nesting, dolls', val: '🪆' },
  { title: 'Knot', keywords: 'knots, boating, fishing, climbing, scouts, ropes, married, nervous, hair', val: '🪢' },
  { title: 'Ladder', keywords: 'steps, rungs, climbing, ladders, step stools, construction, building, electrical, corporate ladder, success', val: '🪜' },
  { title: 'Long Drum', keywords: 'congas, bongos, drums, drummers, drumsticks, instruments, percussion, music, rhythm, beats, bands, long drum', val: '🪘' },
  { title: 'Hook', keywords: 'hooks, ensnare, selling point, crook, curve, catch', val: '🪝' },
  { title: 'Window', keywords: 'windows, transparent, fresh air, opening, frame, views', val: '🪟' },
  { title: 'Thong Sandal', keywords: 'thong sandal, thong sandals, thongs, beach sandals, sandals, zōri, flip-flops', val: '🩴' },
  { title: 'Toothbrush', keywords: 'toothbrushes, dental, hygiene, clean, bathroom, brushing, teeth, tooth', val: '🪥' },
  { title: 'Screwdriver', keywords: 'screwdrivers, screws, tools, construction, repairs, handyman', val: '🪛' },
  { title: 'Piñata', keywords: 'Piñata, piñatas, children, birthday, parties, party, games, candy, Mexico, Latin America, paper, paper mache, papier-mâché', val: '🪅' },
  { title: 'Placard', keywords: 'demonstrations, protests, pickets, placards, signs, picket lines, announcements', val: '🪧' },
  { title: 'Plunger', keywords: 'plungers, clogged, clogs, toilets, sinks, drains, household, tools, bathrooms, sinks, plumbers, rubber, suction, force cup', val: '🪠' },
  { title: 'Sewing Needle', keywords: 'needles, tailoring, sewing, sutures, mending, embroidery, stitches, seamstress, tailors, tailoring, buttons, crafts, needlepoint, cross stitch, sewing machines', val: '🪡' },
  { title: 'Feather', keywords: 'plumage, light, lightweight, flight, feathers, birds, quills', val: '🪶' },
  { title: 'Boomerang', keywords: 'boomerang, Australia, repercussion, rebound, hunting, aborigine, bad luck', val: '🪃' },
  { title: 'Bucket', keywords: 'vats, casks, buckets, pails, bucket list, lists, cleaning, water, mopping, mops', val: '🪣' },
  { title: 'Accordion', keywords: 'squeeze boxes, accordions, concertinas, accordian, handheld', val: '🪗' },
  { title: 'Carpentry Saw', keywords: 'carpentry saw, sawing, lumber, carpenters, tool, furniture, building, construction', val: '🪚' },
  { title: 'Coin', keywords: 'money, treasures, silver, gold, coins, metal, currency, finance, rare, success, coin toss, casino, riches, wealth, financial', val: '🪙' },
  { title: 'Bouquet', keywords: 'flower, bouquet', val: '💐' },
  { title: 'Cherry Blossom', keywords: 'cherry, flower, blossom', val: '🌸' },
  { title: 'Tulip', keywords: 'tulip, flowers, pink, bouquets, spring, Mother\'s Day, Valentine\'s Day, birthdays, plants, nature, thank you, summer, gardens, friendship, love, affection', val: '🌷' },
  { title: 'Four Leaf Clover', keywords: 'four-leaf clover, leaf, four, clover, luck, good luck, fortune, 4', val: '🍀' },
  { title: 'Rose', keywords: 'roses, flowers, love, romance, affection, weddings, birthdays, holidays, Mother\'s Day, Valentine\'s Day', val: '🌹' },
  { title: 'Wilted Flower', keywords: 'wilted, flowers, loss, death, dying, sad, weak, sick, poor, tired', val: '🥀' },
  { title: 'Sunflower', keywords: 'sunflower, sun, flowers, plants, nature, sunflower seeds, sunflower seed oil, happy, happiness, big flowers, summer', val: '🌻' },
  { title: 'Hibiscus', keywords: 'hibiscus, flower, herbal teas, Flor de Jamaica, edible plants, pink', val: '🌺' },
  { title: 'Maple Leaf', keywords: 'leaf, maple, falling, fall, leaves, Canada, syrup, pancakes, autumn, nature, trees, forests, woods, unity, peace', val: '🍁' },
  { title: 'Leaf Fluttering in Wind', keywords: 'leaf fluttering in wind, fluttering, leaf, leaves, wind, falling, breeze, nature, spring, summer, beauty', val: '🍃' },
  { title: 'Fallen Leaf', keywords: 'falling, leaf, fallen leaf, leaves, fall foliage, nature, trees, autumn, colorful leaves', val: '🍂' },
  { title: 'Herb', keywords: 'leaf, leaves, herbs, herbal medicines, cooking, spices, plants, teas', val: '🌿' },
  { title: 'Ear of Rice', keywords: 'sheaf of rice, rice, grain, ear, planting rice, cereals, grains, harvests, rice plantations, rice crops, farms', val: '🌾' },
  { title: 'Mushroom', keywords: 'toadstools, mushrooms, poisonous mushrooms, magic mushrooms, food, fungus, cooking', val: '🍄' },
  { title: 'Cactus', keywords: 'plant, cactus', val: '🌵' },
  { title: 'Palm Tree', keywords: 'trees, palms, coconut trees, date palms, palm leaves, tropics, vacations, relaxation, palm tree oil', val: '🌴' },
  { title: 'Evergreen Tree', keywords: 'evergreen trees, pine trees, trees, Christmas trees, forests, winter, nature', val: '🌲' },
  { title: 'Deciduous Tree', keywords: 'shedding, tree, deciduous', val: '🌳' },
  { title: 'Chestnut', keywords: 'plant, chestnut', val: '🌰' },
  { title: 'Seedling', keywords: 'young, seedlings, plants, gardens, nature, greenery, life, spring, beginnings', val: '🌱' },
  { title: 'Blossom', keywords: 'flower, blossom', val: '🌼' },
  { title: 'Globe with Meridians', keywords: 'globe with meridians, meridians, globe, earth, world, global, interconnectivity, longitude, latitude, Internet, World Wide Web, www', val: '🌐' },
  { title: 'Sun with Face', keywords: 'sun with face, sun, bright, happy, weather, good weather, cartoon suns, happiness, good moods, happy face', val: '🌞' },
  { title: 'Full Moon with Face', keywords: 'full, face, moon, bright, full moon, moons, man on the moon', val: '🌝' },
  { title: 'New Moon with Face', keywords: 'new moon face, moon, face, dark, new moon, moons, man on the moon', val: '🌚' },
  { title: 'New Moon Symbol', keywords: 'moon, moons, moon phases, moon cycles, nighttime, astronomy, outer space, new moon, darkness', val: '🌑' },
  { title: 'Waxing Crescent Moon Symbol', keywords: 'moon, waxing, crescent, moons, outer space, night, moon phases, astronomy', val: '🌒' },
  { title: 'First Quarter Moon Symbol', keywords: 'mmoon, moons, moon phases, moon cycle, nighttime, astronomy, outer space, first quarter moon, quarter', val: '🌓' },
  { title: 'First Quarter Moon Symbol', keywords: 'moon, moons, moon phases, moon cycle, nighttime, astronomy, outer space, first quarter moon, quarter', val: '🌓' },
  { title: 'Waxing Gibbous Moon Symbol', keywords: 'moon, waxing, gibbous, moons, astronomy, outer space, night, moon phases', val: '🌔' },
  { title: 'Full Moon Symbol', keywords: 'moon, full, full moon, nighttime sky, outer space, moon phases, moon cycles, celestial objects, werewolves, howling, wolves, spooky', val: '🌕' },
  { title: 'Waning Gibbous Moon', keywords: 'moon, waning, gibbous, moons, outer space, astronomy, moon phases, night', val: '🌖' },
  { title: 'Last Quarter Moon Symbol', keywords: 'moon, moons, moon phases, moon cycle, nighttime, astronomy, outer space, last quarter moon, quarter', val: '🌗' },
  { title: 'Waning Crescent Moon Symbol', keywords: 'waning, moon, crescent, moons, outer space, nighttime, astronomy', val: '🌘' },
  { title: 'Last Quarter Moon with Face', keywords: 'last quarter moon faces, moon phases, outer space, astronomy, nighttime, happy moons, moons, moon faces, quarter', val: '🌜' },
  { title: 'First Quarter Moon with Face', keywords: 'first quarter moon faces, moon phases, outer space, astronomy, nighttime, happy moons, moons, moon faces, quarter', val: '🌛' },
  { title: 'Crescent Moon', keywords: 'moon, crescent', val: '🌙' },
  { title: 'Fire', keywords: 'flames, fires, heat, hot, on fire, passion, valentine', val: '🔥' },
  { title: 'Droplet', keywords: 'droplet, drop, sweat, comic, cold', val: '💧' },
  { title: 'Sparkles', keywords: 'sparkles, star, sparkle, magic, cleanliness', val: '✨' },
  { title: 'Glowing Star', keywords: 'glowing star, stars, shining, sparkle, glittery, glow, bright', val: '🌟' },
  { title: 'Earth Globe Europe-Africa', keywords: 'the world, the earth, globes, European countries, African countries, continents, the planet, world peace, global concerns, land, water', val: '🌍' },
  { title: 'Earth Globe Americas', keywords: 'the earth, globes, North America, Central America, South America, the planet, world peace, global concerns, land, water', val: '🌎' },
  { title: 'Earth Globe Asia-Australia', keywords: 'the world, the earth, globes, Asian countries, Australia, the planet, world peace, global concerns, land, water', val: '🌏' },
  { title: 'Volcano', keywords: 'mountains, volcanos, eruptions, lava, tourism, Mother Nature, smoke, nature, power, anger', val: '🌋' },
  { title: 'Milky Way', keywords: 'space, Milky Way, galaxy, galaxies, stars, star gazing, outer space, astrology, astronomy, celestial bodies, heaven, nighttime, night skies', val: '🌌' },
  { title: 'Shooting Star', keywords: 'shooting, stars, falling, shooting stars, comets, night skies, astronomy, outer space, wishes, love spark, sparkles, shiny, bright stars, glowing stars, rare things', val: '🌠' },
  { title: 'Thunder Cloud and Rain', keywords: 'cloud with lightning and rain, thunder storms, thunderstorms, lightning, storms, stormy, thunder, rain, cloud', val: '⛈' },
  { title: 'Comet', keywords: 'space, comet', val: '☄️' },
  { title: 'Mountain', keywords: 'mountains, mountaineering, hiking, extreme sports, climbing, nature, outdoors, adventure', val: '⛰' },
  { title: 'Shamrock', keywords: 'plants, luck, Ireland, Irish, St. Patrick\'s Day, fortune, green, four-leaf clover', val: '☘️' },
  { title: 'Star', keywords: 'star, stars, gold star, gold stars, approval, golden, yellow, superstar, appreciation, good grades', val: '⭐️' },
  { title: 'Sun', keywords: 'sun, sunny, rays, bright, heat, hot, weather, power, summer, beach', val: '☀️' },
  { title: 'Sun Behind Cloud', keywords: 'sun behind cloud, sun, cloud, cloudy, clouds, sunny, partially sunny, partial sun, weather, cloudy weather, mixed weather', val: '⛅️' },
  { title: 'Desert', keywords: 'desert', val: '🏜' },
  { title: 'Desert Island', keywords: 'island, desert', val: '🏝' },
  { title: 'Cloud', keywords: 'weather, cloud', val: '☁️' },
  { title: 'High Voltage Sign', keywords: 'high voltage, electric, lightning, zap, voltage, danger, warning, energy, storms, flashing, quick', val: '⚡️' },
  { title: 'Umbrella with Rain Drops', keywords: 'umbrellas, umbrella with rain drops, raindrops, rain, drops, rainy, rain wear, drizzling, winter', val: '☔️' },
  { title: 'Snowflake', keywords: 'snowflake, snow, cold, snow, snowfall, winter, storms, freezing, Christmas', val: '❄️' },
  { title: 'Snowman without Snow', keywords: 'ssnowman without snow, snowman, snow, cold, winter, snowmen, fun, Christmas', val: '⛄️' },
  { title: 'Sun Behind Small Cloud', keywords: 'sun behind small cloud, sun, cloud, cloudy, clouds, sunny, partially sunny, partial sun, weather, cloudy weather, mixed weather', val: '🌤' },
  { title: 'Sun Behind Large Cloud', keywords: 'sun, sun behind large cloud, cloud, sun behind cloud, cloudy, clouds, large cloud, large clouds, sunny, partially sunny, partial sun, weather, cloudy weather, mixed weather', val: '🌥' },
  { title: 'Sun Behind Rain Cloud', keywords: 'sun behind rain cloud, rainy weather, partial sun, cloud, sun, rain, clouds, mixed weather', val: '🌦' },
  { title: 'Cloud with Rain', keywords: 'cloud with rain, rain, cloud', val: '🌧' },
  { title: 'Cloud with Snow', keywords: 'cloud with snow, cold, snow, cloud', val: '🌨' },
  { title: 'Cloud with Lightning', keywords: 'cloud with lightning, lightning, cloud', val: '🌩' },
  { title: 'Cloud with Tornado', keywords: 'tornado, whirlwind, cloud', val: '🌪' },
  { title: 'Fog', keywords: 'haze, smoke, smog, fog, foggy weather, cloudiness, cloudy visibility, low visibility, fog, cloud, depressed', val: '🌫' },
  { title: 'Wind Blowing Face', keywords: 'cloud, face, wind, blow, windy days, windy conditions, exhaling, blowing, air, breathing, breath fog, cold days, winter', val: '🌬' },
  { title: 'Fork and Knife with Plate', keywords: 'forks, knife, knives, plates, cooking, dining, restaurants, hunger, hungry, dinners, meals, eating, food', val: '🍽' },
  { title: 'Cyclone', keywords: 'hurricane, typhoon, twister, cyclone, dizzy', val: '🌀' },
  { title: 'Foggy', keywords: 'foggy, fog, haze, cloudiness, clouds, confusion, uncertainty, winter', val: '🌁' },
  { title: 'Rainbow', keywords: 'rainbows, rain, positivity, beauty, weather, colors, happiness, togetherness, LGTBQ', val: '🌈' },
  { title: 'Water Wave', keywords: 'waves, water, ocean, beaches, water sports, sports, outdoors, summer, water activities, surfing, surfers, vacation, tourism', val: '🌊' },
  { title: 'Silhouette of Japan', keywords: 'map of Japan, maps, Japan, Japanese, Asia, Asian', val: '🗾' },
  { title: 'Mount Fuji', keywords: 'Mount Fuji, Fuji-san, Japan, mountains, snow, hiking, mountaineering, nature, winter, outdoors, extreme sports, adventure, snow-capped', val: '🗻' },
  { title: 'Sunrise over Mountains', keywords: 'sunrise, sun, sunrise over mountains, mountain, mornings, new days, new beginnings, good morning, sunrises, early mornings, nature, camping, outdoors', val: '🌄' },
  { title: 'Sunrise', keywords: 'sunrises, sunrise, sun, mornings, early mornings, good morning, rise and shine, new day, new beginnings', val: '🌅' },
  { title: 'Night with Stars', keywords: 'nighttime, nighttime cityscapes, skylines, starry nights, evenings, cities, buildings, nightlife, and night scenery', val: '🌃' },
  { title: 'Statue of Liberty', keywords: 'Statue of Liberty, statue, liberty, freedom, New York City, immigrants, immigration, tourism, tourist attractions, United States', val: '🗽' },
  { title: 'Bridge at Night', keywords: 'bridge at night, night, bridge', val: '🌉' },
  { title: 'Microbe', keywords: 'bacteria, microbes, amoeba, viruses, germs, influenza, infections, dirty', val: '🦠️' },
  { title: 'Hot Springs', keywords: 'hotsprings, hot, springs, steaming, water, thermal, tubs, circular, baths, healing', val: '♨️' },
  { title: 'Fountain', keywords: 'fountains, waters, parks, wishing, wells, summer', val: '⛲️' },
  { title: 'Wood', keywords: 'wood, lumber, timber, logs, wood, wooden, trees, lumber, timber, forests, deforestation, log cabins, chopping blocks, axes', val: '🪵' },
  { title: 'Rock', keywords: 'rock, stone, solid, boulder, heavy', val: '🪨' },
  { title: 'Potted Plant', keywords: 'grow, nurturing, house, useless, plant, potted plant, boring', val: '🪴' },
  { title: 'Infinity', keywords: 'infinity, universal, unbounded, forever, eternity', val: '♾️' },
  { title: 'Female Sign', keywords: 'woman, female sign, women, girls, feminine, gender', val: '♀️' },
  { title: 'Male Sign', keywords: 'male sign, man, men, gender, manly, masculine', val: '♂️' },
  { title: 'Transgender Symbol', keywords: 'transgenders, transgender symbol, LGTBQ, sexuality, gender', val: '⚧️' },
  { title: 'Medical Symbol', keywords: 'medical symbols, aesculapius, medicine, staff, doctors, medical, hospitals', val: '⚕️' },
  { title: 'Curly Loop', keywords: 'curly loop, loop, curl, curly, loopy', val: '➰' },
  { title: 'Double Curly Loop', keywords: 'double curly loop, double, curl, loop, voicemail, tape recorder', val: '➿' },
  { title: 'Dollar Sign', keywords: 'heavy dollar sign, dollars, money, currency, cash, riches, wealth', val: '💲' },
  { title: 'Currency Exchange', keywords: 'money, currency, exchange, bank, yen, dollars', val: '💱' },
  { title: 'Copyright Sign', keywords: 'copyrights, c, legal, terminology, writers, ownership', val: '©️' },
  { title: 'Registered Sign', keywords: 'registered sign, R, trademarks, legal', val: '®️' },
  { title: 'Trade Mark Sign', keywords: 'trade marks, marks, trademarks, tm, ownership', val: '™️' },
  { title: 'Wavy Dash', keywords: 'punctuation, dash, wavy, squiggly, hyphen', val: '〰️' },
  { title: 'Input Symbol for Uppercase Letters', keywords: 'latin, uppercase, letters, capitals, letters, input, ABCD', val: '🔠' },
  { title: 'Input Symbol for Lowercase Letters', keywords: 'input, lowercase, letters, latin, abcd', val: '🔡' },
  { title: 'Input Symbol for Letters', keywords: 'input, alphabet, latin, letters, abc', val: '🔤' },
  { title: 'Information Source', keywords: 'information, i, facts, details, help, assistance, more', val: 'ℹ️' },
  { title: 'OK Sign', keywords: 'OK button, OK, perfect, good, alright, fine, buttons', val: '🆗' },
  { title: 'New Sign', keywords: 'NEW button, new, buttons, current, active, signs', val: '🆕' },
  { title: 'Up Sign', keywords: 'up, UP! button, mark, freight, package', val: '🆙' },
  { title: 'Cool Sign', keywords: 'COOL buttons, cool, air conditioning, great, cold', val: '🆒' },
  { title: 'Free Sign', keywords: 'FREE, buttons, free, boxes, freebies', val: '🆓' },
  { title: 'No Good Sign', keywords: 'NG button, ng, no good, television, Japan, bloopers', val: '🆖' },
  { title: 'Cellular Signal Bars', keywords: 'antenna bars, mobile, phone, cells, antenna, bars', val: '📶' },
  { title: 'Cinema', keywords: 'cinema, film, movies, camera, videos, malls', val: '🎦' },
  { title: 'Restroom', keywords: 'restroom, WC, lavatory, toilets, bathrooms, sinks', val: '🚻' },
  { title: 'Men\'s Symbol', keywords: 'men\'s room, man, restroom, lavatory, wc', val: '🚹' },
  { title: 'Women\'s Symbol', keywords: 'restroom, wc, bathrooms, girls, women, restrooms, lavatory, woman, women\'s room', val: '🚺' },
  { title: 'Baby Symbol', keywords: 'baby symbol, changing, baby, babies, diapers', val: '🚼' },
  { title: 'Water Closet', keywords: 'lavatory, closets, restrooms, wc, water, toilets, bathrooms, washrooms', val: '🚾' },
  { title: 'Put Litter in its Place Symbol', keywords: 'litter bin, litter, litter in bin sign, garbage pails, garbage', val: '🚮' },
  { title: 'Parking Sign', keywords: 'Parking Sign, signs, Letter P, P, parking, P', val: '🅿️' },
  { title: 'Wheelchair Symbol', keywords: 'wheelchair symbol, access, wheelchairs, disabled', val: '♿️' },
  { title: 'Circled M', keywords: 'circled M, m, circles, trains, travel, metro, subways', val: 'Ⓜ️' },
  { title: 'Passport Control', keywords: 'passports, controls, identification, borders, airports, international, travel', val: '🛂' },
  { title: 'Baggage Claim', keywords: 'claims, baggage, luggage, airports, travel', val: '🛄' },
  { title: 'Left Luggage', keywords: 'lockers, baggage, left luggage, luggage, suitcase', val: '🛅' },
  { title: 'Customs', keywords: 'customs, baggage, inspection, airports, signs, borders, control', val: '🛃' },
  { title: 'CL Sign', keywords: 'CL button, cl, clear, calculators', val: '🆑' },
  { title: 'SOS Sign', keywords: 'sos, help, SOS button, distress, rescue, emergency', val: '🆘' },
  { title: 'Squared VS', keywords: 'vs, versus, VS button, buttons, V, S', val: '🆚' },
  { title: 'Prohibited', keywords: 'prohibited, forbidden, entry, no, not, traffic, restrict, DO NOT ENTER', val: '🚫' },
  { title: 'No Smoking Sign', keywords: 'not, no, prohibited, forbidden, smoking, smoke free zone', val: '🚭' },
  { title: 'No One Under Eighteen', keywords: 'eighteen, 18, age restriction, prohibited, no one under eighteen, underage', val: '🔞' },
  { title: 'No Mobile Phones', keywords: 'forbidden, no, cell, no mobile phones, mobile, mute, silences, phone', val: '📵' },
  { title: 'No Littering', keywords: 'no, forbidden, litter, not, no littering, prohibited, do not litter', val: '🚯' },
  { title: 'Non-Potable Water', keywords: 'non-potable, non-drinking, water, do not drink, unsafe water, nonpotable, not potable', val: '🚱' },
  { title: 'No Bicycles', keywords: 'bike, no, forbidden, bicycle, no bicycles, prohibited, not allowed', val: '🚳' },
  { title: 'No Pedestrians', keywords: 'no pedestrians, not, forbidden, no, pedestrians, DO NOT ENTER, prohibited', val: '🚷' },
  { title: 'Children Crossing', keywords: 'children crossing, crossing, pedestrian, child, warning, schools, traffic', val: '🚸' },
  { title: 'No Entry', keywords: 'prohibited, forbidden, entry, no, not, traffic, restrict, DO NOT ENTER', val: '⛔️' },
  { title: 'Warning Sign', keywords: 'warnings, triangles, yellow, alerts, careful, beware, caution, signs, traffic, construction', val: '⚠️' },
  { title: 'Octagonal Sign', keywords: 'signs, stop, traffic, red, octagonal, octagons, shapes, geometric', val: '🛑' },
  { title: 'Construction Sign', keywords: 'construction, barriers, buildings, danger, under, site, careful', val: '🚧' },
  { title: 'Eight Spoked Asterisk', keywords: 'eight-spoked asterisks, asterisks, *, spokes, bullets, bullet, points', val: '✳️' },
  { title: 'Sparkle', keywords: '*, sparkles, sparks, bullets, asterisk, shiny, new', val: '❇️' },
  { title: 'X Mark', keywords: 'cross mark button, mark, ×, square, x, multiplication, crosses, X', val: '❎' },
  { title: 'Check Mark Button', keywords: 'check, mark, button, ✓, ballot, boxes', val: '✅' },
  { title: 'Eight Pointed Star', keywords: 'eight-pointed star, star, eight, points, star, orange, *', val: '✴️' },
  { title: 'Heart Decoration', keywords: 'heart decorations, hearts, red, pink, decorations, love, romance', val: '💟' },
  { title: 'Vibration Mode', keywords: 'mobile, phones, cells, mode, telephones, vibrations, vibrating', val: '📳' },
  { title: 'Mobile Phone Off', keywords: 'off, mobile, phones, cells, telephones, silent, silence, mute', val: '📴' },
  { title: 'Blood Type A', keywords: 'A button (blood type), blood types, A, medical, hospitals, doctors, patient', val: '🅰️' },
  { title: 'Blood Type B', keywords: 'B button (blood type), blood type, b, medical, doctors, hospitals, patients', val: '🅱️' },
  { title: 'Blood Type AB', keywords: 'AB button (blood type), blood type, AB, medical, hospitals, doctors, patients', val: '🆎' },
  { title: 'Blood Type O', keywords: 'O button (blood type), blood type, O, medical, doctors, patients, hospitals', val: '🅾️' },
  { title: 'Diamond Shape with a Dot Inside', keywords: 'diamond with a dot, geometric, diamond, inside, comic', val: '💠' },
  { title: 'Recycling Symbol', keywords: 'recycling symbol, recycle, arrows, rotating, recyclable', val: '♻️' },
  { title: 'ATM Sign', keywords: 'automated, banks, tellers, ATM signs, atm', val: '🏧' },
  { title: 'Potable Water Symbol', keywords: 'potable, drinking, water, clean, drinks, safe water', val: '🚰' },
  { title: 'Chart with Upwards Trend and Yen Sign', keywords: 'chart increasing with yen, graph, money, growth, yen, chart, Japan, Japanese', val: '💹' },
  { title: 'Part Alternation Mark', keywords: 'part, mark, part alternation mark, singers, songs, music', val: '〽️' },
  { title: 'Cross Mark', keywords: 'multiplication, red, multiply, mark, cross, cancel, ×, x', val: '❌' },
  { title: 'Circle Mark', keywords: 'hollow red circle, red, large, circle, o, round', val: '⭕️' },
  { title: 'Exclamation Mark', keywords: 'marks, !, exclamation, points, punctuation, surprise, excitement', val: '❗️' },
  { title: 'Question Mark', keywords: 'punctuation, questions, mark, ?, interrogatives', val: '❓' },
  { title: 'White Exclamation Mark', keywords: 'exclamation, !, mark, punctuation, outlined, white exclamation mark, white', val: '❕' },
  { title: 'White Question Mark', keywords: 'outlined, mark, ?, punctuation, white question mark, white, question', val: '❔' },
  { title: 'Twelve O\'Clock', keywords: '12:00, 12, 00, o\'clock, clock, twelve, midnight, noon, midday', val: '🕛' },
  { title: 'Twelve-Thirty', keywords: '12:30, 12, twelve, thirty, clock, twelve-thirty', val: '🕧' },
  { title: 'One O\'Clock', keywords: '1, clock, o\'clock, 1:00, 00, one', val: '🕐' },
  { title: 'One-Thirty', keywords: 'clock, 1, one-thirty, 1:30, clock, thirty', val: '🕜' },
  { title: 'Two O\'Clock', keywords: '2, 00, 2:00, o\'clock, two, clock', val: '🕑' },
  { title: 'Two-Thirty', keywords: '2:30, 2, two, clock, thirty, two-thirty', val: '🕝' },
  { title: 'Three O\'Clock', keywords: '3:00, 3, 00, clock, o\'clock, three', val: '🕒' },
  { title: 'Three-Thirty', keywords: '3:30, clock, 3, thirty, three, three-thirty', val: '🕞' },
  { title: 'Four O\'Clock', keywords: 'clock, four, o\'clock, 4:00, 00, 4', val: '🕓' },
  { title: 'Four-Thirty', keywords: 'four-thirty, thirty, four, clock, 4:30, 4', val: '🕟' },
  { title: 'Five O\'Clock', keywords: 'o\'clock, clock, five, 00, 5, 5:00', val: '🕔' },
  { title: 'Five-Thirty', keywords: 'five-thirty, five, clock, thirty, 5:30, 5', val: '🕠' },
  { title: 'Six O\'Clock', keywords: 'clock, o\'clock, six, 00, 6, 6:00', val: '🕕' },
  { title: 'Seven O\'Clock', keywords: 'o\'clock, clock, seven, 7:00, 7, 00', val: '🕖' },
  { title: 'Eight O\'Clock', keywords: 'clock, eight, o\'clock, 8, 8:00', val: '🕗' },
  { title: 'Nine O\'Clock', keywords: '9:00, 00, 9, o\'clock, nine, clock', val: '🕘' },
  { title: 'Ten O\'Clock', keywords: '10, clock, 00, 10:00, o\'clock, ten', val: '🕙' },
  { title: 'Eleven O\'Clock', keywords: 'clock, eleven, o\'clock, 11:00, 11, 00', val: '🕚' },
  { title: 'Six-Thirty', keywords: 'clock, 6, 6:30, six-thirty, six, thirty', val: '🕡' },
  { title: 'Seven-Thirty', keywords: 'clock, seven-thirty, thirty, 7:30, 7', val: '🕢' },
  { title: 'Eight-Thirty', keywords: 'clock, eight-thirty, eight, thirty, 8:30, 8', val: '🕣' },
  { title: 'Nine-Thirty', keywords: '9:30, nine-thirty, 9, nine, clock, thirty', val: '🕤' },
  { title: 'Ten-Thirty', keywords: '10:30, 10, ten, clock, thirty, ten-thirty', val: '🕥' },
  { title: 'Eleven-Thirty', keywords: 'eleven, eleven-thirty, thirtyclock, 11:30, 11', val: '🕦' },
  { title: 'Keycap Digit One', keywords: 'keycap, one, 1', val: '1⃣' },
  { title: 'Keycap Digit Two', keywords: 'keycap, two, 2', val: '2⃣' },
  { title: 'Keycap Digit Three', keywords: 'keycap, three, 3', val: '3⃣' },
  { title: 'Keycap Digit Four', keywords: 'keycap, four, 4', val: '4⃣' },
  { title: 'Keycap Digit Five', keywords: 'keycap, five, 5', val: '5⃣' },
  { title: 'Keycap Digit Six', keywords: 'keycap, six, 6', val: '6⃣' },
  { title: 'Keycap Digit Seven', keywords: 'keycap, seven, 7', val: '7⃣' },
  { title: 'Keycap Digit Eight', keywords: 'keycap, eight, 8', val: '8⃣' },
  { title: 'Keycap Digit Nine', keywords: 'keycap, nine, 9', val: '9⃣' },
  { title: 'Keycap Digit Zero', keywords: 'keycap, zero, 0', val: '0⃣' },
  { title: 'Keycap Digit Ten', keywords: 'keycap, ten, 10', val: '🔟' },
  { title: 'Input Symbol for Numbers', keywords: 'input, numbers, 1234', val: '🔢' },
  { title: 'Keycap Number Sign', keywords: 'keycap, number, sign, #, hashtag, pound', val: '#️⃣' },
  { title: 'Input Symbol for Symbols', keywords: 'input symbols, input, 〒♪&%', val: '🔣' },
  { title: 'Skip Forward Symbol', keywords: 'nnext track button, next track, next scene, triangle, arrow, skip, forward', val: '⏭' },
  { title: 'Skip Backward Symbol', keywords: 'last track button, previous track, previous scene, skip, backwards, arrow, triangle', val: '⏮' },
  { title: 'Play/Pause Symbol', keywords: 'play or pause button, play, triangle, right, pause, buttons, arrows', val: '⏯' },
  { title: 'Keycap Asterisk', keywords: 'keycaps, asterisks, stars', val: '*️⃣' },
  { title: 'Pause Symbol', keywords: 'pause buttons, pause, vertical, double, bar, buttons', val: '⏸' },
  { title: 'Stop Symbol', keywords: 'stop button, stop, square, buttons, end', val: '⏹' },
  { title: 'Record Symbol', keywords: 'record button record circle', val: '⏺' },
  { title: 'Eject Symbol', keywords: '', val: '⏏️' },
  { title: 'Radioactive Sign', keywords: 'sign, radioactive, radiation, radioactivity, danger, radiology', val: '☢️' },
  { title: 'Biohazard Sign', keywords: 'sign, biohazards, radioactive, danger, deadly, chemicals', val: '☣️' },
  { title: 'Plus Symbol', keywords: 'math, +, plus, sign, addition, and, positive, mathematics', val: '➕' },
  { title: 'Minus Symbol', keywords: 'minus, -, math, −, sign, negative, number, dash, hyphen', val: '➖' },
  { title: 'Multiplication Symbol', keywords: 'multiplication, cancel, multiply, ×, sign, x, cross mark, cancel', val: '✖️' },
  { title: 'Division Sign', keywords: 'division, math, sign, dividing, divide, ÷', val: '➗' },
  { title: 'Hearts Suit', keywords: 'heart suit, game, card, hearts, decks, playing cards', val: '♥️' },
  { title: 'Diamonds Suit', keywords: 'diamond suit, games, cards, decks, playing cards', val: '♦️' },
  { title: 'Clubs Suit', keywords: 'club suit, games, cards, decks, clubs', val: '♣️' },
  { title: 'Spades Suit', keywords: 'spade suit, game, card, spades, decks', val: '♠️' },
  { title: 'White Flower', keywords: 'white flowers, flowers. white, good, Asia, schoolwork', val: '💮' },
  { title: 'Hundred Points Symbol', keywords: 'hundred, hundred points, score, points, full, 100', val: '💯' },
  { title: 'Dizzy Symbol', keywords: 'dizzy, stars, dizziness, sick, orientation, comic', val: '💫' },
  { title: 'Collision Symbol', keywords: 'collision, comic, boom, bam, pow', val: '💥' },
  { title: 'Anger Symbol', keywords: 'angry, anger symbol, mad, rage, comic', val: '💢' },
  { title: 'Splashing Sweat Symbol', keywords: 'splashing, comic, sweat, sweat, droplets, water, rain', val: '💦' },
  { title: 'Sleeping Symbol', keywords: 'sleeping, comic, zzz, snoring, tired, exhaustion', val: '💤' },
  { title: 'Dash Symbol', keywords: 'dashing away, running, dash, comic', val: '💨' },
  { title: 'Check Mark', keywords: 'check, mark, ✓, boxes, checkmarks', val: '✔️' },
  { title: 'Ballot Box with Check', keywords: 'check box with check, checks, boxes, ✓, completed task, ballots', val: '☑️' },
  { title: 'Radio Button', keywords: 'geometric, button, shapes, round, on/off, radio', val: '🔘' },
  { title: 'Link Symbol', keywords: 'links, websites, Internet, chains, linked', val: '🔗' },
  { title: 'Trident Emblem', keywords: 'ship, anchor, tool, emblem, trident, spears, weapons, gods, Roman, Greek', val: '🔱' },
  { title: 'Black Square Button', keywords: 'buttons, black square button, shapes, geometric, squares, on, off', val: '🔲' },
  { title: 'White Square Button', keywords: 'geometric, square, button, outlined, on-off, white square button, shapes, black, white', val: '🔳' },
  { title: 'White Large Square', keywords: 'square, geometric, white large square, white, bullet points, shapes', val: '⬜️' },
  { title: 'Black Large Square', keywords: 'squares, black, black large square, shapes, geometric, bullet points', val: '⬛️' },
  { title: 'Black Medium Square', keywords: 'geometric, black medium square, squares, shapes, bullet points', val: '◼️' },
  { title: 'White Medium Square', keywords: 'squares, geometric, white medium square, white, bullet points, shapes', val: '◻️' },
  { title: 'Black Medium Small Square', keywords: 'square, black medium-small square, geometric, shapes, bullet points', val: '◾️' },
  { title: 'White Medium Small Square', keywords: 'squares, geometric, white medium-small square, bullet points, shapes', val: '◽️' },
  { title: 'Black Small Square', keywords: 'geometric, black small square, squares, bullet points, shapes', val: '▪️' },
  { title: 'White Small Square', keywords: 'squares, geometric, white small square, white, shapes, bullet points', val: '▫️' },
  { title: 'Red Triangle Pointed Up', keywords: 'red, geometry, red triangle pointed up, triangles, shapes, bullet points', val: '🔺' },
  { title: 'Medium Black Circle', keywords: 'circles, black circle, geometric, shapes, black, bullet points', val: '⚫️' },
  { title: 'Medium White Circle', keywords: 'geometric, circles, white circles, buttons, shapes', val: '⚪️' },
  { title: 'Red Circle', keywords: 'geometry, shapes, bullet points, circle, red', val: '🔴' },
  { title: 'Blue Circle', keywords: 'circles, blue, geometric, bullet, points, shapes', val: '🔵' },
  { title: 'Down-Pointing Red Triangle', keywords: 'red triangle pointed down, geometric, red, down, bullet marks, shapes', val: '🔻' },
  { title: 'Large Orange Diamond', keywords: 'geometric, large orange diamond, bullets, shapes, diamond, orange', val: '🔶' },
  { title: 'Large Blue Diamond', keywords: 'diamond, geometric, blue, large blue, shapes, bullets, diamonds', val: '🔷' },
  { title: 'Small Orange Diamond', keywords: 'orange, diamonds, small orange diamond, shapes, bullet points, geometric', val: '🔸' },
  { title: 'Small Blue Diamond', keywords: 'geometric, diamonds, shapes, small blue diamond, blue', val: '🔹' },
  { title: 'Exclamation Question Mark', keywords: 'interrobang, punctuation, mark, question, exclamation, !?, ?, !', val: '⁉️' },
  { title: 'Double Exclamation Mark', keywords: 'double exclamation mark, exclamation, punctuation, mark, !!, !', val: '‼️' },
  { title: 'Japanese Symbol for Beginner', keywords: 'Japanese, chevron, beginner, Japanese, symbol, driver, leaf, license', val: '🔰' },
  { title: 'Identification Sign', keywords: 'ID button, identification, cards, buttons, IDs, identity', val: '🆔' },
  { title: 'Keycap Asterisk', keywords: 'keycaps, asterisks, stars', val: '*️⃣' },
  { title: 'Elevator', keywords: 'lifts, accessibility, hoists, elevators, up, down', val: '🛗' },
  { title: 'Peace Symbol', keywords: 'peace symbol, peace, world peace, shalom, harmony, unity', val: '☮️' },
  { title: 'Place of Worship', keywords: 'religion, place of worship, worship, God, prayer, synagogue, church, mosque, temple', val: '🛐' },
  { title: 'Menorah with Nine Branches', keywords: 'candlestick, menorah, candelabra, Jewish, Judaism, Jew, Hanukkah, Chanukah, religion', val: '🕎' },
  { title: 'Latin Cross', keywords: 'latin cross, Christian, cross, religions', val: '✝️' },
  { title: 'Star of David', keywords: 'Jew, religion, David, Jewish, stars, star of David, Judaism, Judaic', val: '✡️' },
  { title: 'Orthodox Cross', keywords: 'Orthodox cross, Christians, Orthodox Christianity, cross, religion', val: '☦️' },
  { title: 'Star and Crescent', keywords: 'Muslim, religion, Islam, star and crescent, stars, crescents, flags, Middle East', val: '☪️' },
  { title: 'Yin Yang', keywords: 'taoism, religions, tao, yin, yang, Buddhism, peace, harmony', val: '☯️' },
  { title: 'Wheel of Dharma', keywords: 'dharma, wheels, Buddhist, religion, wheel of dharma, ships, nautical', val: '☸️' },
  { title: 'Atom Symbol', keywords: 'atom symbol, atoms, molecules, science, scientists', val: '⚛️' },
  { title: 'Aries', keywords: 'zodiac, Aries, ram, signs, astrology', val: '♈️' },
  { title: 'Taurus', keywords: 'Taurus, bull, ox, zodiac, astrology, signs', val: '♉️' },
  { title: 'Gemini', keywords: 'twins, zodiac, Gemini, astrology, signs', val: '♊️' },
  { title: 'Cancer', keywords: 'zodiac, Cancer, crab, signs, astrology', val: '♋️' },
  { title: 'Leo', keywords: 'lion, Leo, zodiac, astrology, signs', val: '♌️' },
  { title: 'Virgo', keywords: 'zodiac, Virgo, virgins, signs, astrology', val: '♍️' },
  { title: 'Libra', keywords: 'justice, scales, balance, Libra, zodiac, astrology', val: '♎️' },
  { title: 'Scorpius', keywords: 'scorpions, zodiac, scorpio, scorpius, astrology, signs', val: '♏️' },
  { title: 'Sagittarius', keywords: 'Sagittarius, zodiac, archers, astrology, signs', val: '♐️' },
  { title: 'Capricorn', keywords: 'goats, zodiac, Capricorn, astrology, signs', val: '♑️' },
  { title: 'Aquarius', keywords: 'bearer, water, Aquarius, zodiac', val: '♒️' },
  { title: 'Pisces', keywords: 'Pisces, fish, zodiac, astrology, signs', val: '♓️' },
  { title: 'Ophiuchus', keywords: 'Ophiuchus, snake, bearer, serpent, zodiac, astrology, Virgo', val: '⛎' },
  { title: 'Six Pointed Star with Middle Dot', keywords: 'fortune, dotted six-pointed star, six, stars, Judaism, Jewish, Hebrew, David', val: '🔯' },
  { title: 'Regional Indicator Symbol Letter A', keywords: 'letter, A, regional, indicator, symbols, flags', val: '🇦' },
  { title: 'Regional Indicator Symbol Letter B', keywords: 'Letter, B, b, regional, indicator, symbol', val: '🇧' },
  { title: 'Regional Indicator Symbol Letter C', keywords: 'letter C, C, regional, symbols, flags, indicator', val: '🇨' },
  { title: 'Regional Indicator Symbol Letter D', keywords: 'letter, D, regional, indicator, symbols, flags', val: '🇩' },
  { title: 'Regional Indicator Symbol Letter E', keywords: 'letter, E, e, symbols, indicators, regional, flags', val: '🇪' },
  { title: 'Regional Indicator Symbol Letter F', keywords: 'letter, F, regional, symbols, indicators, flags', val: '🇫' },
  { title: 'Regional Indicator Symbol Letter G', keywords: 'letter G, G, g, regional, indicators, symbols, flags', val: '🇬' },
  { title: 'Regional Indicator Symbol Letter H', keywords: 'letter H, H, h, indicators, symbols, regional, flags', val: '🇭' },
  { title: 'Regional Indicator Symbol Letter I', keywords: 'letter I, I, i, regional, symbols, indicators, flags', val: '🇮' },
  { title: 'Regional Indicator Symbol Letter J', keywords: 'letter J, J, j, indicators, symbols, regional, flags', val: '🇯' },
  { title: 'Regional Indicator Symbol Letter K', keywords: 'letter K, K, k, indicators, regional, flags, symbols', val: '🇰' },
  { title: 'Regional Indicator Symbol Letter L', keywords: 'letter L, L, l, regional, symbols, indicators, flags', val: '🇱' },
  { title: 'Regional Indicator Symbol Letter M', keywords: 'letter M, M, m, regional, indicators, flags, symbols', val: '🇲' },
  { title: 'Regional Indicator Symbol Letter N', keywords: 'letter N, N, n, flags, regional, symbols, indicators', val: '🇳' },
  { title: 'Regional Indicator Symbol Letter O', keywords: 'letter O, O, o, regional, symbols, indicators, flags', val: '🇴' },
  { title: 'Regional Indicator Symbol Letter P', keywords: 'letter P, P, p, symbols, regional, indicators, flags', val: '🇵' },
  { title: 'Regional Indicator Symbol Letter Q', keywords: 'letter Q, Q, q, symbols, regional, indicators, flags', val: '🇶' },
  { title: 'Regional Indicator Symbol Letter R', keywords: 'letter R, R, r, regional, symbols, indicators, flags', val: '🇷' },
  { title: 'Regional Indicator Symbol Letter S', keywords: 'letter S, S, s, symbols, regional, indicators, flags', val: '🇸' },
  { title: 'Regional Indicator Symbol Letter T', keywords: 'letter T, T, t, regional, symbols, indicators, flags', val: '🇹' },
  { title: 'Regional Indicator Symbol Letter U', keywords: 'letter U, U, u, symbols, indicators, regional, flags', val: '🇺' },
  { title: 'Regional Indicator Symbol Letter V', keywords: 'letter V, V, v, regional, indicators, symbols, flags', val: '🇻' },
  { title: 'Regional Indicator Symbol Letter W', keywords: 'letter W, W, w, indicators, symbols, regional, flags', val: '🇼' },
  { title: 'Regional Indicator Symbol Letter X', keywords: 'letter X, X, x, symbols, regional, flags, indicators', val: '🇽' },
  { title: 'Regional Indicator Symbol Letter Y', keywords: 'letter Y, Y, y, symbols, indicators, regional, flags', val: '🇾' },
  { title: 'Regional Indicator Symbol Letter Z', keywords: 'letter Z, Z, z, symbols, flags, regional, indicators', val: '🇿' },
  { title: 'Japanese Word Sign Meaning “Here”', keywords: 'Japanese, here, ココ, katakana, Japanese, button', val: '🈁' },
  { title: 'Japanese Sign Meaning “Reserved”', keywords: 'Japanese, ideograph, 指, Japanese, reserved, button', val: '🈯️' },
  { title: 'Japanese Sign Meaning “Vacancy”', keywords: 'Japanese, ideograph, Japanese, vacancy, button, 空', val: '🈳' },
  { title: 'Japanese Sign Meaning “Full; No Vacancy”', keywords: 'Japanese, no vacancy, button, Japanese, ideograph, 満', val: '🈵' },
  { title: 'Japanese Sign Meaning “Passing (Grade)”', keywords: 'Japanese “passing grade” button Japanese ideograph “passing grade” 合', val: '🈴' },
  { title: 'Japanese Sign Meaning “Prohibited”', keywords: 'Japanese, prohibited, button, Japanese, ideograph, 禁', val: '🈲' },
  { title: 'Japanese Sign Meaning “Bargain”', keywords: 'Japanese, bargain, button, Japanese, advantage, ideograph, 得', val: '🉐' },
  { title: 'Japanese Sign Meaning “Discount”', keywords: 'Japanese, discount, button, ideograph, Japanese, discount, 割', val: '🈹' },
  { title: 'Japanese Sign Meaning “Open for Business”', keywords: 'Japanese “open for business” button, Japanese, ideograph, 営', val: '🈺' },
  { title: 'Japanese Sign Meaning “Not Free of Charge”', keywords: 'Japanese, ]“not free of charge” button, Japanese, ideograph, 有', val: '🈶' },
  { title: 'Japanese Sign Meaning “Free of Charge”', keywords: 'Japanese, ideograph, free, charge”, 無, Japanese, button', val: '🈚️' },
  { title: 'Japanese Sign Meaning “Monthly Amount”', keywords: 'Japanese, monthly, amount, button, Japanese, ideograph, 月', val: '🈷️' },
  { title: 'Japanese Sign Meaning “Application”', keywords: 'Japanese, application, button, Japanese, ideograph, 申', val: '🈸' },
  { title: 'Japanese Sign Meaning “Service” or “Service Charge”', keywords: 'Japanese, service, charge, katakana, サ, Japanese, button', val: '🈂️' },
  { title: 'Japanese Sign Meaning “Acceptable”', keywords: 'Japanese, acceptable” button, Japanese, ideograph, 可', val: '🉑' },
  { title: 'Japanese Sign Meaning “Secret”', keywords: 'Japanese, ideograph, “secret”, 秘, Japanese, button', val: '㊙️' },
  { title: 'Japanese Sign Meaning “Congratulations”', keywords: 'ideograph, congratulations, 祝, Japanese, button', val: '㊗️' },
  { title: 'Flag for Japan', keywords: 'flag, JP, Japan', val: '🇯🇵' },
  { title: 'Flag for Korea', keywords: 'flag, KR, Korea', val: '🇰🇷' },
  { title: 'Flag for Germany', keywords: 'flag, DE, Germany', val: '🇩🇪' },
  { title: 'Flag for China', keywords: 'flag, CN, China', val: '🇨🇳' },
  { title: 'Flag for United States', keywords: 'flag, US, United States', val: '🇺🇸' },
  { title: 'Flag for France', keywords: 'flag, FR, France', val: '🇫🇷' },
  { title: 'Flag for Spain', keywords: 'flag, ES, Spain', val: '🇪🇸' },
  { title: 'Flag for Italy', keywords: 'flag, IT, Italy', val: '🇮🇹' },
  { title: 'Flag for Russia', keywords: 'flag, RU, Russia', val: '🇷🇺' },
  { title: 'Flag for Great Britain', keywords: 'flag, GB, Great Britain', val: '🇬🇧' },
  { title: 'Flag for Azerbaijan', keywords: 'flag, AZ, Azerbaijan', val: '🇦🇿' },
  { title: 'Flag for Australia', keywords: 'flag, AU, Australia', val: '🇦🇺' },
  { title: 'Flag for Aruba', keywords: 'flag, AW, Aruba', val: '🇦🇼' },
  { title: 'Flag for Angola', keywords: 'flag, AO, Angola', val: '🇦🇴' },
  { title: 'Flag for Albania', keywords: 'flag, AL, Albania', val: '🇦🇱' },
  { title: 'Flag for Armenia', keywords: 'flag, AM, Armenia', val: '🇦🇲' },
  { title: 'Flag for American Samoa', keywords: 'flag, AS, American Samoa', val: '🇦🇸' },
  { title: 'Flag for Austria', keywords: 'flag, AT, Austria', val: '🇦🇹' },
  { title: 'Flag for The United Arab Emirates', keywords: 'flag, AE, The United Arab Emirates', val: '🇦🇪' },
  { title: 'Flag for Antigua and Barbuda', keywords: 'flag, AG, Antigua and Barbuda', val: '🇦🇬' },
  { title: 'Flag for Afghanistan', keywords: 'flag, AF, Afghanistan', val: '🇦🇫' },
  { title: 'Flag for Anguilla', keywords: 'flag, AI, Anguilla', val: '🇦🇮' },
  { title: 'Flag for Andorra', keywords: 'flag, AD, Andorra', val: '🇦🇩' },
  { title: 'Flag for Argentina', keywords: 'flag, AR, Argentina', val: '🇦🇷' },
  { title: 'Flag for Bolivia', keywords: 'flag, BO, Bolivia', val: '🇧🇴' },
  { title: 'Flag for Brazil', keywords: 'flag, BR, Brazil', val: '🇧🇷' },
  { title: 'Flag for Brunei', keywords: 'flag, BN, Brunei', val: '🇧🇳' },
  { title: 'Flag for Bermuda', keywords: 'flag, BM, Bermuda', val: '🇧🇲' },
  { title: 'Flag for Bhutan', keywords: 'flag, BT, Bhutan', val: '🇧🇹' },
  { title: 'Flag for The Bahamas', keywords: 'flag, BS, The Bahamas', val: '🇧🇸' },
  { title: 'Flag for Belarus', keywords: 'flag, BY, Belarus', val: '🇧🇾' },
  { title: 'Flag for Belize', keywords: 'flag, BZ, Belize', val: '🇧🇿' },
  { title: 'Flag for Botswana', keywords: 'flag, BW, Botswana', val: '🇧🇼' },
  { title: 'Flag for Belgium', keywords: 'flag, BE, Belgium', val: '🇧🇪' },
  { title: 'Flag for Burkina Faso', keywords: 'flag, BF, Burkina Faso', val: '🇧🇫' },
  { title: 'Flag for Bulgaria', keywords: 'flag, BG, Bulgaria', val: '🇧🇬' },
  { title: 'Flag for Bahrain', keywords: 'flag, BH, Bahrain', val: '🇧🇭' },
  { title: 'Flag for Burundi', keywords: 'flag, BI, Burundi', val: '🇧🇮' },
  { title: 'Flag for Benin', keywords: 'flag, BJ, Benin', val: '🇧🇯' },
  { title: 'Flag for Bosnia and Herzegovina', keywords: 'flag, BA, Bosnia and Herzegovina', val: '🇧🇦' },
  { title: 'Flag for Barbados', keywords: 'flag, BB, Barbados', val: '🇧🇧' },
  { title: 'Flag for Bangladesh', keywords: 'flag, BD, Bangladesh', val: '🇧🇩' },
  { title: 'Flag for Cape Verde', keywords: 'flag, CV, Cape Verde', val: '🇨🇻' },
  { title: 'Flag for Curaçao', keywords: 'flag, CW, Curaçao', val: '🇨🇼' },
  { title: 'Flag for Cuba', keywords: 'flag, CU, Cuba', val: '🇨🇺' },
  { title: 'Flag for The Czech Republic', keywords: 'flag, CZ, The Czech Republic', val: '🇨🇿' },
  { title: 'Flag for Cyprus', keywords: 'flag, CY, Cyprus', val: '🇨🇾' },
  { title: 'Flag for Cameroon', keywords: 'flag, CM, Cameroon', val: '🇨🇲' },
  { title: 'Flag for Cook Islands', keywords: 'flag, CK, Cook Islands', val: '🇨🇰' },
  { title: 'Flag for Chile', keywords: 'flag, CL, Chile', val: '🇨🇱' },
  { title: 'Flag for Costa Rica', keywords: 'flag, CR, Costa Rica', val: '🇨🇷' },
  { title: 'Flag for Colombia', keywords: 'flag, CO, Colombia', val: '🇨🇴' },
  { title: 'Flag for The Democratic Republic Of The Congo', keywords: 'flag, CD, The Democratic Republic Of The Congo', val: '🇨🇩' },
  { title: 'Flag for Canada', keywords: 'flag, CA, Canada', val: '🇨🇦' },
  { title: 'Flag for The Republic Of The Congo', keywords: 'flag, CG, The Republic Of The Congo', val: '🇨🇬' },
  { title: 'Flag for Central African Republic', keywords: 'flag, CF, Central African Republic', val: '🇨🇫' },
  { title: 'Flag for Côte D\'ivoire', keywords: 'flag, CI, Côte D\'ivoire', val: '🇨🇮' },
  { title: 'Flag for Switzerland', keywords: 'flag, CH, Switzerland', val: '🇨🇭' },
  { title: 'Flag for Djibouti', keywords: 'flag, DJ, Djibouti', val: '🇩🇯' },
  { title: 'Flag for Dominica', keywords: 'flag, DM, Dominica', val: '🇩🇲' },
  { title: 'Flag for Denmark', keywords: 'flag, DK, Denmark', val: '🇩🇰' },
  { title: 'Flag for The Dominican Republic', keywords: 'flag, DO, The Dominican Republic', val: '🇩🇴' },
  { title: 'Flag for Algeria', keywords: 'flag, DZ, Algeria', val: '🇩🇿' },
  { title: 'Flag for Ethiopia', keywords: 'flag, ET, Ethiopia', val: '🇪🇹' },
  { title: 'Flag for Eritrea', keywords: 'flag, ER, Eritrea', val: '🇪🇷' },
  { title: 'Flag for Egypt', keywords: 'flag, EG, Egypt', val: '🇪🇬' },
  { title: 'Flag for Estonia', keywords: 'flag, EE, Estonia', val: '🇪🇪' },
  { title: 'Flag for Ecuador', keywords: 'flag, EC, Ecuador', val: '🇪🇨' },
  { title: 'Flag for Faroe Islands', keywords: 'flag FO Faroe Islands', val: '🇫🇴' },
  { title: 'Flag for Finland', keywords: 'flag, FI, Finland', val: '🇫🇮' },
  { title: 'Flag for Fiji', keywords: 'flag, FJ, Fiji', val: '🇫🇯' },
  { title: 'Flag for Guatemala', keywords: 'flag, GT, Guatemala', val: '🇬🇹' },
  { title: 'Flag for Guinea', keywords: 'flag, GN, Guinea', val: '🇬🇳' },
  { title: 'Flag for The Gambia', keywords: 'flag, GM, The Gambia', val: '🇬🇲' },
  { title: 'Flag for Guadeloupe', keywords: 'flag, GP, Guadeloupe', val: '🇬🇵' },
  { title: 'Flag for Greece', keywords: 'flag, GR, Greece', val: '🇬🇷' },
  { title: 'Flag for Equatorial Guinea', keywords: 'flag, GQ, Equatorial Guinea', val: '🇬🇶' },
  { title: 'Flag for Guam', keywords: 'flag, GU, Guam', val: '🇬🇺' },
  { title: 'Flag for Guinea-Bissau', keywords: 'flag, GW, Guinea-Bissau', val: '🇬🇼' },
  { title: 'Flag for Guyana', keywords: 'flag, GY, Guyana', val: '🇬🇾' },
  { title: 'Flag for French Guiana', keywords: 'flag, GF, French Guiana', val: '🇬🇫' },
  { title: 'Flag for Ghana', keywords: 'flag, GH, Ghana', val: '🇬🇭' },
  { title: 'Flag for Gibraltar', keywords: 'flag, GI, Gibraltar', val: '🇬🇮' },
  { title: 'Flag for Georgia', keywords: 'flag, GE, Georgia', val: '🇬🇪' },
  { title: 'Flag for Grenada', keywords: 'flag, GD, Grenada', val: '🇬🇩' },
  { title: 'Flag for Gabon', keywords: 'flag, GA, Gabon', val: '🇬🇦' },
  { title: 'Flag for Hungary', keywords: 'flag, HU, Hungary', val: '🇭🇺' },
  { title: 'Flag for Croatia', keywords: 'flag, HR, Croatia', val: '🇭🇷' },
  { title: 'Flag for Honduras', keywords: 'flag, HN, Honduras', val: '🇭🇳' },
  { title: 'Flag for Hong Kong', keywords: 'flag, HK, Hong Kong', val: '🇭🇰' },
  { title: 'Flag for Haiti', keywords: 'flag, HT, Haiti', val: '🇭🇹' },
  { title: 'Flag for Ireland', keywords: 'flag, IE, Ireland', val: '🇮🇪' },
  { title: 'Flag for Indonesia', keywords: 'flag, ID, Indonesia', val: '🇮🇩' },
  { title: 'Flag for Iran', keywords: 'flag,IR, Iran', val: '🇮🇷' },
  { title: 'Flag for Iraq', keywords: 'flag, IQ, Iraq', val: '🇮🇶' },
  { title: 'Flag for India', keywords: 'flag, IN, India', val: '🇮🇳' },
  { title: 'Flag for Israel', keywords: 'flag, IL, Israel', val: '🇮🇱' },
  { title: 'Flag for Iceland', keywords: 'flag, IS, Iceland', val: '🇮🇸' },
  { title: 'Flag for Jordan', keywords: 'flag, JO, Jordan', val: '🇯🇴' },
  { title: 'Flag for Jamaica', keywords: 'flag, JM, Jamaica', val: '🇯🇲' },
  { title: 'Flag for Kyrgyzstan', keywords: 'flag, KG, Kyrgyzstan', val: '🇰🇬' },
  { title: 'Flag for Kenya', keywords: 'flag, KE, Kenya', val: '🇰🇪' },
  { title: 'Flag for Cambodia', keywords: 'flag, KH, Cambodia', val: '🇰🇭' },
  { title: 'Flag for Kiribati', keywords: 'flag, KI, Kiribati', val: '🇰🇮' },
  { title: 'Flag for Kuwait', keywords: 'flag, KW, Kuwait', val: '🇰🇼' },
  { title: 'Flag for Kazakhstan', keywords: 'flag, KZ, Kazakhstan', val: '🇰🇿' },
  { title: 'Flag for Cayman Islands', keywords: 'flag, KY, Cayman Islands', val: '🇰🇾' },
  { title: 'Flag for Saint Kitts And Nevis', keywords: 'flag, KN, Saint Kitts And Nevis', val: '🇰🇳' },
  { title: 'Flag for The Comoros', keywords: 'flag, KM, The Comoros', val: '🇰🇲' },
  { title: 'Flag for North Korea', keywords: 'flag, KP, North Korea', val: '🇰🇵' },
  { title: 'Flag for Sri Lanka', keywords: 'flag, LK, Sri Lanka', val: '🇱🇰' },
  { title: 'Flag for Liberia', keywords: 'flag, LR, Liberia', val: '🇱🇷' },
  { title: 'Flag for Lesotho', keywords: 'flag, LS, Lesotho', val: '🇱🇸' },
  { title: 'Flag for Lithuania', keywords: 'flag, LT, Lithuania', val: '🇱🇹' },
  { title: 'Flag for Latvia', keywords: 'flag, LV, Latvia', val: '🇱🇻' },
  { title: 'Flag for Luxembourg', keywords: 'flag, LU, Luxembourg', val: '🇱🇺' },
  { title: 'Flag for Libya', keywords: 'flag, LY, Libya', val: '🇱🇾' },
  { title: 'Flag for Liechtenstein', keywords: 'flag, LI, Liechtenstein', val: '🇱🇮' },
  { title: 'Flag for Saint Lucia', keywords: 'flag, LC, Saint Lucia', val: '🇱🇨' },
  { title: 'Flag for Lebanon', keywords: 'flag, LB, Lebanon', val: '🇱🇧' },
  { title: 'Flag for Laos', keywords: 'flag, LA, Laos', val: '🇱🇦' },
  { title: 'Flag for Malawi', keywords: 'flag, MW, Malawi', val: '🇲🇼' },
  { title: 'Flag for Maldives', keywords: 'flag, MV, Maldives', val: '🇲🇻' },
  { title: 'Flag for Macau', keywords: 'flag, MO, Macau', val: '🇲🇴' },
  { title: 'Flag for Montenegro', keywords: 'flag, ME, Montenegro', val: '🇲🇪' },
  { title: 'Flag for Madagascar', keywords: 'flag, MG, Madagascar', val: '🇲🇬' },
  { title: 'Flag for Moldova', keywords: 'flag, MD, Moldova', val: '🇲🇩' },
  { title: 'Flag for Morocco', keywords: 'flag, MA, Morocco', val: '🇲🇦' },
  { title: 'Flag for Mongolia', keywords: 'flag, MN, Mongolia', val: '🇲🇳' },
  { title: 'Flag for Myanmar', keywords: 'flag, MM, Myanmar', val: '🇲🇲' },
  { title: 'Flag for Mauritania', keywords: 'flag, MR, Mauritania', val: '🇲🇷' },
  { title: 'Flag for Martinique', keywords: 'flag, MQ, Martinique', val: '🇲🇶' },
  { title: 'Flag for Malta', keywords: 'flag, MT, Malta', val: '🇲🇹' },
  { title: 'Flag for Montserrat', keywords: 'flag, MS, Montserrat', val: '🇲🇸' },
  { title: 'Flag for Malaysia', keywords: 'flag, MY, Malaysia', val: '🇲🇾' },
  { title: 'Flag for Mexico', keywords: 'flag, MX, Mexico', val: '🇲🇽' },
  { title: 'Flag for Mozambique', keywords: 'flag, MZ, Mozambique', val: '🇲🇿' },
  { title: 'Flag for Mali', keywords: 'flag, ML, Mali', val: '🇲🇱' },
  { title: 'Flag for Macedonia', keywords: 'flag, MK, Macedonia', val: '🇲🇰' },
  { title: 'Flag for Northern Mariana Islands', keywords: 'flag, MP, Northern Mariana Islands', val: '🇲🇵' },
  { title: 'Flag for Nicaragua', keywords: 'flag, NI, Nicaragua', val: '🇳🇮' },
  { title: 'Flag for Namibia', keywords: 'flag, NA, Namibia', val: '🇳🇦' },
  { title: 'Flag for The Netherlands', keywords: 'flag, NL, The Netherlands', val: '🇳🇱' },
  { title: 'Flag for Norway', keywords: 'flag, NO, Norway', val: '🇳🇴' },
  { title: 'Flag for Nepal', keywords: 'flag, NP, Nepal', val: '🇳🇵' },
  { title: 'Flag for New Zealand', keywords: 'flag, NZ, New Zealand', val: '🇳🇿' },
  { title: 'Flag for Niue', keywords: 'flag, NU, Niue', val: '🇳🇺' },
  { title: 'Flag for Niger', keywords: 'flag, NE, Niger', val: '🇳🇪' },
  { title: 'Flag for New Caledonia', keywords: 'flag, NC, New Caledonia', val: '🇳🇨' },
  { title: 'Flag for Nigeria', keywords: 'flag, NG, Nigeria', val: '🇳🇬' },
  { title: 'Flag for Oman', keywords: 'flag, OM, Oman', val: '🇴🇲' },
  { title: 'Flag for Palestinian Authority', keywords: 'flag, PS, Palestinian Authority', val: '🇵🇸' },
  { title: 'Flag for Pakistan', keywords: 'flag, PK, Pakistan', val: '🇵🇰' },
  { title: 'Flag for Paraguay', keywords: 'flag, PY, Paraguay', val: '🇵🇾' },
  { title: 'Flag for Palau', keywords: 'flag, PW, Palau', val: '🇵🇼' },
  { title: 'Flag for The Philippines', keywords: 'flag, PH, The Philippines', val: '🇵🇭' },
  { title: 'Flag for Papua New Guinea', keywords: 'flag, PG, Papua New Guinea', val: '🇵🇬' },
  { title: 'Flag for Peru', keywords: 'flag, PE, Peru', val: '🇵🇪' },
  { title: 'Flag for Panama', keywords: 'flag, PA, Panama', val: '🇵🇦' },
  { title: 'Flag for Puerto Rico', keywords: 'flag, PR, Puerto Rico', val: '🇵🇷' },
  { title: 'Flag for Portugal', keywords: 'flag, PT, Portugal', val: '🇵🇹' },
  { title: 'Flag for Poland', keywords: 'flag, PL, Poland', val: '🇵🇱' },
  { title: 'Flag for Qatar', keywords: 'flag, QA, Qatar', val: '🇶🇦' },
  { title: 'Flag for Réunion', keywords: 'flag, RE, Réunion', val: '🇷🇪' },
  { title: 'Flag for Serbia', keywords: 'flag, RS, Serbia', val: '🇷🇸' },
  { title: 'Flag for Romania', keywords: 'flag, RO, Romania', val: '🇷🇴' },
  { title: 'Flag for Rwanda', keywords: 'flag, RW, Rwanda', val: '🇷🇼' },
  { title: 'Flag for San Marino', keywords: 'flag, SM, San Marino', val: '🇸🇲' },
  { title: 'Flag for Saudi Arabia', keywords: 'flag, SA, Saudi Arabia', val: '🇸🇦' },
  { title: 'Flag for The Solomons Islands', keywords: 'flag, SB, The Solomons Ilands', val: '🇸🇧' },
  { title: 'Flag for The Seychelles', keywords: 'flag, SC, The Seychelles', val: '🇸🇨' },
  { title: 'Flag for Sudan', keywords: 'flag, SD, Sudan', val: '🇸🇩' },
  { title: 'Flag for Singapore', keywords: 'flag, SG, Singapore', val: '🇸🇬' },
  { title: 'Flag for Sweden', keywords: 'flag, SE, Sweden', val: '🇸🇪' },
  { title: 'Flag for Slovenia', keywords: 'flag, SI, Slovenia', val: '🇸🇮' },
  { title: 'Flag for El Salvador', keywords: 'flag, SV, El Salvador', val: '🇸🇻' },
  { title: 'Flag for Swaziland', keywords: 'flag, SZ, Swaziland', val: '🇸🇿' },
  { title: 'Flag for Syria', keywords: 'flag, SY, Syria', val: '🇸🇾' },
  { title: 'Flag for Sint Maarten', keywords: 'flag, SX, Sint Maarten', val: '🇸🇽' },
  { title: 'Flag for São Tomé And Príncipe', keywords: 'flag, ST, São Tomé And Príncipe', val: '🇸🇹' },
  { title: 'Flag for South Sudan', keywords: 'flag, SS, South Sudan', val: '🇸🇸' },
  { title: 'Flag for Senegal', keywords: 'flag, SN, Senegal', val: '🇸🇳' },
  { title: 'Flag for Sierra Leone', keywords: 'flag, SL, Sierra Leone', val: '🇸🇱' },
  { title: 'Flag for Slovakia', keywords: 'flag, SK, Slovakia', val: '🇸🇰' },
  { title: 'Flag for Suriname', keywords: 'flag, SR, Suriname', val: '🇸🇷' },
  { title: 'Flag for Somalia', keywords: 'flag, SO, Somalia', val: '🇸🇴' },
  { title: 'Flag for Tonga', keywords: 'flag, TO, Tonga', val: '🇹🇴' },
  { title: 'Flag for Tanzania', keywords: 'flag, TZ, Tanzania', val: '🇹🇿' },
  { title: 'Flag for Trinidad And Tobago', keywords: 'flag, TT, Trinidad And Tobago', val: '🇹🇹' },
  { title: 'Flag for Turkmenistan', keywords: 'flag, TM, Turkmenistan', val: '🇹🇲' },
  { title: 'Flag for Tunisia', keywords: 'flag, TN, Tunisia', val: '🇹🇳' },
  { title: 'Flag for Timor-Leste', keywords: 'flag, TL, Timor-Leste', val: '🇹🇱' },
  { title: 'Flag for Turkey', keywords: 'flag, TR, Turkey', val: '🇹🇷' },
  { title: 'Flag for Tuvalu', keywords: 'flag, TV, Tuvalu', val: '🇹🇻' },
  { title: 'Flag for Togo', keywords: 'flag, TG, Togo', val: '🇹🇬' },
  { title: 'Flag for French Southern Territories', keywords: 'flag, TF, French Southern Territories', val: '🇹🇫' },
  { title: 'Flag for Tajikistan', keywords: 'flag, TJ, Tajikistan', val: '🇹🇯' },
  { title: 'Flag for Thailand', keywords: 'flag, TH, Thailand', val: '🇹🇭' },
  { title: 'Flag for Turks And Caicos Islands', keywords: 'flag, TC, Turks And Caicos Islands', val: '🇹🇨' },
  { title: 'Flag for Uzbekistan', keywords: 'flag, UZ, Uzbekistan', val: '🇺🇿' },
  { title: 'Flag for Uruguay', keywords: 'flag, UY, Uruguay', val: '🇺🇾' },
  { title: 'Flag for Uganda', keywords: 'flag, UG, Uganda', val: '🇺🇬' },
  { title: 'Flag for Ukraine', keywords: 'flag, UA, Ukraine', val: '🇺🇦' },
  { title: 'Flag for Vanuatu', keywords: 'flag, VU, Vanuatu', val: '🇻🇺' },
  { title: 'Flag for Vietnam', keywords: 'flag, VN, Vietnam', val: '🇻🇳' },
  { title: 'Flag for Saint Vincent And The Grenadines', keywords: 'flag, VC, Saint Vincent And The Grenadines', val: '🇻🇨' },
  { title: 'Flag for Venezuela', keywords: 'flag, VE, Venezuela', val: '🇻🇪' },
  { title: 'Flag for British Virgin Islands', keywords: 'flag, VG, British Virgin Islands', val: '🇻🇬' },
  { title: 'Flag for U.S. Virgin Islands', keywords: 'flag, VI, U.S. Virgin Islands', val: '🇻🇮' },
  { title: 'Flag for Samoa', keywords: 'flag, WS, Samoa', val: '🇼🇸' },
  { title: 'Flag for Yemen', keywords: 'flag, YE, Yemen', val: '🇾🇪' },
  { title: 'Flag for South Africa', keywords: 'flag, ZA, South Africa', val: '🇿🇦' },
  { title: 'Flag for Zimbabwe', keywords: 'flag, ZW, Zimbabwe', val: '🇿🇼' },
  { title: 'Flag for Zambia', keywords: 'flag ZM Zambia', val: '🇿🇲' },
  { title: 'Flag for Antarctica', keywords: 'flag, AQ, Antarctica', val: '🇦🇶' },
  { title: 'Flag for Åland Islands', keywords: 'flag, AX, Åland Islands', val: '🇦🇽' },
  { title: 'Flag for Saint Barthélemy', keywords: 'flag, BL, Saint Barthélemy', val: '🇧🇱' },
  { title: 'Flag for Caribbean Netherlands', keywords: 'flag, BQ, Caribbean Netherlands', val: '🇧🇶' },
  { title: 'Flag for Cocos (Keeling) Islands', keywords: 'flag, CC, Cocos (Keeling) Islands', val: '🇨🇨' },
  { title: 'Flag for Christmas Island', keywords: 'flag, CX, Christmas Island', val: '🇨🇽' },
  { title: 'Flag for Western Sahara', keywords: 'flag, EH, Western Sahara', val: '🇪🇭' },
  { title: 'Flag for European Union', keywords: 'flag, EU, European Union', val: '🇪🇺' },
  { title: 'Flag for Falkland Islands', keywords: 'flag, FK, Falkland Islands', val: '🇫🇰' },
  { title: 'Flag for Micronesia', keywords: 'flag, FM, Micronesia', val: '🇫🇲' },
  { title: 'Flag for Guernsey', keywords: 'flag, GG, Guernsey', val: '🇬🇬' },
  { title: 'Flag for Greenland', keywords: 'flag, GL, Greenland', val: '🇬🇱' },
  { title: 'Flag for South Georgia and the South Sandwich Islands', keywords: 'flag, GS, South Georgia and the South Sandwich Islands', val: '🇬🇸' },
  { title: 'Flag for Canary Islands', keywords: 'flag, IC, Canary Islands', val: '🇮🇨' },
  { title: 'Flag for Isle Of Man', keywords: 'flag, IM, Isle Of Man', val: '🇮🇲' },
  { title: 'Flag for British Indian Ocean Territory', keywords: 'flag, IO, British Indian Ocean Territory', val: '🇮🇴' },
  { title: 'Flag for Jersey', keywords: 'flag, JE, Jersey', val: '🇯🇪' },
  { title: 'Flag for Monaco', keywords: 'flag, MC, Monaco', val: '🇲🇨' },
  { title: 'Flag for The Marshall Islands', keywords: 'flag, MH, The Marshall Islands', val: '🇲🇭' },
  { title: 'Flag for Mauritius', keywords: 'flag, MU, Mauritius', val: '🇲🇺' },
  { title: 'Flag for Norfolk Island', keywords: 'flag, NF, Norfolk Island', val: '🇳🇫' },
  { title: 'Flag for Nauru', keywords: 'flag, NR, Nauru', val: '🇳🇷' },
  { title: 'Flag for French Polynesia', keywords: 'flag, PF, French Polynesia', val: '🇵🇫' },
  { title: 'Flag for Saint Pierre And Miquelon', keywords: 'flag, PM, Saint Pierre And Miquelon', val: '🇵🇲' },
  { title: 'Flag for Pitcairn', keywords: 'flag, PN, Pitcairn', val: '🇵🇳' },
  { title: 'Flag for Saint Helena', keywords: 'flag, SH, Saint Helena', val: '🇸🇭' },
  { title: 'Flag for Chad', keywords: 'flag, TD, Chad', val: '🇹🇩' },
  { title: 'Flag for Tokelau', keywords: 'flag, TK, Tokelau', val: '🇹🇰' },
  { title: 'Flag for Taiwan', keywords: 'flag, TW, Taiwan', val: '🇹🇼' },
  { title: 'Flag for The Vatican City', keywords: 'flag, VA, The Vatican City', val: '🇻🇦' },
  { title: 'Flag for Wallis And Futuna', keywords: 'flag, WF, Wallis And Futuna', val: '🇼🇫' },
  { title: 'Flag for Kosovo', keywords: 'flag, XK, Kosovo', val: '🇽🇰' },
  { title: 'Flag for Mayotte', keywords: 'flag, YT, Mayotte', val: '🇾🇹' },
  { title: 'Flag for Ascension Island', keywords: 'flag, AC, Ascension Island', val: '🇦🇨' },
  { title: 'Flag for Bouvet Island', keywords: 'flag, BV, Bouvet Island', val: '🇧🇻' },
  { title: 'Flag for Ceuta & Melilla', keywords: 'flag, EA, Ceuta & Melilla', val: '🇪🇦' },
  { title: 'Flag for Clipperton Island', keywords: 'flag, CP, Clipperton Island', val: '🇨🇵' },
  { title: 'Flag for Diego Garcia', keywords: 'flag, DG, Diego Garcia', val: '🇩🇬' },
  { title: 'Flag for Heard & McDonald Islands', keywords: 'flag, HM, Heard & McDonald Islands', val: '🇭🇲' },
  { title: 'Flag for St. Martin', keywords: 'flag, MF, St. Martin', val: '🇲🇫' },
  { title: 'Flag for Svalbard & Jan Mayen', keywords: 'flag, SJ, Svalbard & Jan Mayen', val: '🇸🇯' },
  { title: 'Flag for Tristan Da Cunha', keywords: 'flag, TA, Tristan Da Cunha', val: '🇹🇦' },
  { title: 'Flag for U.S. Outlying Islands', keywords: 'flag, UM, U.S. Outlying Islands', val: '🇺🇲' },
  { title: 'Flag for England', keywords: 'flag, EN, GBENG, England', val: '🏴󠁧󠁢󠁥󠁮󠁧󠁿' },
  { title: 'Flag for Scotland', keywords: 'flag, SCT, GBSCT, Scotland', val: '🏴󠁧󠁢󠁳󠁣󠁴󠁿' },
  { title: 'Flag for Wales', keywords: 'flag, WLS, GBWLS, Wales', val: '🏴󠁧󠁢󠁷󠁬󠁳󠁿' },
  { title: 'Flag for United Nations', keywords: 'flag, UN, United Nations', val: '🇺🇳' },
  { title: 'Triangular Flag on Post', keywords: 'posts, triangular, triangles, flags, castles, golf', val: '🚩' },
  { title: 'White Flag', keywords: 'waving, white, flags, surrender, peace, freedom', val: '🏳' },
  { title: 'Black Flag', keywords: 'black, flags, death, negativity, dark, anarchy, anarchism, rebellion, waving', val: '🏴' },
  { title: 'Rainbow Flag', keywords: 'colors, spectrum, rainbows, flags, pride, gay, LGTBQ', val: '🏳️‍🌈' },
  { title: 'Pirate Flag', keywords: 'treasure, Jolly Roger, pirate, plunder, pirate flag', val: '🏴‍☠️️' },
  { title: 'Transgender Flag', keywords: 'transgenders, white, flags, genders, symbols', val: '🏳️‍⚧️' },
  { title: 'Hot Beverage', keywords: 'steaming, beverages, coffees, teas, drinks, cups, saucers, chocolate, milk', val: '☕️' },
  { title: 'Teacup without Handle', keywords: 'drinks, cups, teas, beverages, teacups, soup, green, matcha, Asian, handles, hot, drinks, dishes', val: '🍵' },
  { title: 'Sake Bottle and Cup', keywords: 'bottles, beverages, cups, drinks, bars, sake, Japanese, hot, celebrations, food, cooking, alcohol, liquor, alcoholism', val: '🍶' },
  { title: 'Beer Mug', keywords: 'beers, drinking, mugs, bars, summers, celebrations, alcohol, alcoholism, foam, liquor, hot, parties', val: '🍺' },
  { title: 'Clinking Beer Mugs', keywords: 'beers, cheers, drinking, toasts, mugs, bars, summers, celebrations, alcohol, alcoholism, foam, liquor, hot, parties', val: '🍻' },
  { title: 'Cocktail Glass', keywords: 'cocktails, drinks, glasses, bars, drinking, bars, celebrations, alcohol, alcoholism, liquor, parties, toasts', val: '🍸' },
  { title: 'Tropical Drink', keywords: 'drinks, bars, tropical, alcohol, liquor, alcoholism, parties, vacations, beaches, holidays, summers, cold, refreshing, fruity', val: '🍹' },
  { title: 'Wine Glass', keywords: 'beverages, bars, glasses, drinks, wine, red, white, drinking, alcohol, liquor, alcoholism, parties, celebrations, congratulations, cooking, food', val: '🍷' },
  { title: 'Fork and Knife', keywords: 'knife, knives, food, cooking, forks, cutlery, utensils, silverware, eating, cutting', val: '🍴' },
  { title: 'Pizza', keywords: 'slices, cheese, pizzas, Italian, Italy, fast, food, toppings, pepperoni, sausage, mushroom, sauce, anchovies, olives, America, pizzerias', val: '🍕' },
  { title: 'Hamburger', keywords: 'hamburgers, burgers, cheeseburgers, fast, foods, fries', val: '🍔' },
  { title: 'French Fries', keywords: 'fries, French, potatoes, fast, food, hamburgers, burgers, fried, ketchup, chips', val: '🍟' },
  { title: 'Poultry Leg', keywords: 'drumstick, chicken, poultry, bone, leg', val: '🍗' },
  { title: 'Meat on Bone', keywords: 'meat, bones, soup, red, meat, cartoons, comics, caveman', val: '🍖' },
  { title: 'Spaghetti', keywords: 'spaghetti, pasta, meatballs, Italian, tomatoes, sauces, noodles, food', val: '🍝' },
  { title: 'Curry and Rice', keywords: 'rice, curry, curries, Asia, India, food, spicy, meals, restaurants', val: '🍛' },
  { title: 'Fried Shrimp', keywords: 'prawns, fried, shrimps, tempura, shellfish, seafood, crustaceans, food, breaded', val: '🍤' },
  { title: 'Bento Box', keywords: 'boxes, bento, Japanese, food, restaurant, sections, rice, sushi, Japan, meals, takeout', val: '🍱' },
  { title: 'Sushi', keywords: 'sushi, sashimi, raw, fish, shellfish, seafood, Japanese, Japan, chefs, wasabi, ginger, nori', val: '🍣' },
  { title: 'Fish Cake with Swirl Design', keywords: 'fish, pastry, swirls, cakes, Japanese, ramen, food', val: '🍥' },
  { title: 'Rice Ball', keywords: 'Japanese, balls, rice, nori, cone, seaweed, onigiri, nigirimeshi, omusubi', val: '🍙' },
  { title: 'Rice Cracker', keywords: 'rice, crackers, snacks, Japanese, nori, tea, food', val: '🍘' },
  { title: 'Cooked Rice', keywords: 'rice, Asia, white, cooked, starches, Central America, South America, agriculture, food', val: '🍚' },
  { title: 'Steaming Bowl', keywords: 'noodle, bowl, ramen, steaming, soup, hot, chopsticks, broth, Asian', val: '🍜' },
  { title: 'Pot of Food', keywords: 'foods, stews, pots, stovetops, cooking, soups', val: '🍲' },
  { title: 'Oden', keywords: 'seafood, oden, kebabs, skewers, sticks, Japanese, foods', val: '🍢' },
  { title: 'Dango', keywords: 'sweet, stick, skewer, Japanese, dessert, Dango', val: '🍡' },
  { title: 'Cooking', keywords: 'cooking, breakfast, egg, pans, frying, food, skillets', val: '🍳' },
  { title: 'Bread', keywords: 'loaf, loaves bread, toast, bakery, bakeries, yeast, sandwiches, food', val: '🍞' },
  { title: 'Doughnut', keywords: 'doughnuts, donuts, sweets, desserts, breakfast, snacks, holes, fillings, fried, dough, bakeries, bakery', val: '🍩' },
  { title: 'Custard', keywords: 'sweets, custards, desserts, puddings, flan, eggs, bakeries', val: '🍮' },
  { title: 'Soft Ice Cream', keywords: 'desserts, ice cream, ice, soft, cream, sweet, cones, summers, milk, sweets, foods, frozen', val: '🍦' },
  { title: 'Ice Cream', keywords: 'desserts, ice, cream, sweets, sundaes, milk, cake, food, dairy, frozen', val: '🍨' },
  { title: 'Shaved Ice', keywords: 'shaved, desserts, sweet, ices, summer, snow, cones, foods, frozen', val: '🍧' },
  { title: 'Birthday Cake', keywords: 'cakes, birthdays, celebrations, sweet, desserts, pastry, pastries, bakery, bakeries, happy, candles', val: '🎂' },
  { title: 'Shortcake', keywords: 'pastry, pastries, desserts, sweet, whipped, cream, strawberry, shortcake, cake, slices, birthday', val: '🍰' },
  { title: 'Cookie', keywords: 'sweets, desserts, cookies, biscuits, bakeries, bakery, snacks, chocolate, chips', val: '🍪' },
  { title: 'Chocolate Bar', keywords: 'desserts, sweets, chocolate, bars, candy, cocoa, brown, snacks, Valentine\'s', val: '🍫' },
  { title: 'Candy', keywords: 'sweets, dessert, candy, candies, sugar, snacks, chocolate, lollies, cavities, piñatas', val: '🍬' },
  { title: 'Lollipop', keywords: 'desserts, sweets, candy, candies, lollipops, sugar, snacks, foods, kids, children, suckers', val: '🍭' },
  { title: 'Honey Pot', keywords: 'honey, sweet, honeypots, pots, bees, beehives, sugar, sticky, food', val: '🍯' },
  { title: 'Red Apple', keywords: 'fruits, apples, red, trees, healthy, vitamins, salads, pies, seeds', val: '🍎' },
  { title: 'Green Apple', keywords: 'fruits, apples, green, healthy, salads, trees, pies, pits', val: '🍏' },
  { title: 'Tangerine', keywords: 'oranges, orange, fruits, tangerines, healthy, trees, tropical, peels, vitamins, C', val: '🍊' },
  { title: 'Lemon', keywords: 'lemons, yellow, limes, tropical, fruits, citrus, trees, sour', val: '🍋' },
  { title: 'Cherries', keywords: 'cherries, berries, cherry, red, fruits, salads, trees, healthy', val: '🍒' },
  { title: 'Grapes', keywords: 'fruit, grapes, juice, wine, raisins, jelly, grapevines, salads', val: '🍇' },
  { title: 'Watermelon', keywords: 'watermelons, fruits, summer, seeds, big, melons, vines, salads, red, green', val: '🍉' },
  { title: 'Strawberry', keywords: 'fruits, berry, strawberry, berries, strawberries, salads, patches, jams, seeds, red, pink, healthy, vitamins, produce, markets, farmers', val: '🍓' },
  { title: 'Peach', keywords: 'peach, fruits, pies, salads, pits, trees, healthy', val: '🍑' },
  { title: 'Melon', keywords: 'melons, fruits, cantaloupes, salads, seeds, vines, healthy, summer', val: '🍈' },
  { title: 'Banana', keywords: 'fruits, bananas, trees, foods, tropical, salads, healthy, yellow', val: '🍌' },
  { title: 'Pear', keywords: 'pears, fruits, salads, trees, healthy, vitamins, seeds', val: '🍐' },
  { title: 'Pineapple', keywords: 'pineapples, fruits, tropical, healthy, salads, yellow', val: '🍍' },
  { title: 'Roasted Sweet Potato', keywords: 'roasted, orange, potatoes, sweet, mashed, chips, vegetables, tubers, gardens, cooking, produce, diets, healthy', val: '🍠' },
  { title: 'Eggplant', keywords: 'eggplants, aubergine, vegetables, healthy foods, salads, gardens, produce, vitamins, purple', val: '🍆' },
  { title: 'Tomato', keywords: 'tomatoes, fruits, vegetables, healthy, foods, salads, cooking, sauces, ketchup, gardens, produce, vitamins, orange', val: '🍅' },
  { title: 'Ear of Maize', keywords: 'maze, maize, ears, corn, cornbread, cobs, vegetables, healthy, salads, gardens, produce, vitamins, popcorn, cornmeal, cornflour, stalks', val: '🌽' },
  { title: 'Hot Pepper', keywords: 'peppers, hot, healthy, salads, vegetables, produce, farmers, market, vitamins, gardens, cooking, sizzling, heat, spicy, spices', val: '🌶' },
  { title: 'Hot Dog', keywords: 'hotdogs, frankfurters, hot, dogs, sausages, buns, baseball, foods, fast, mustard', val: '🌭' },
  { title: 'Taco', keywords: 'tacos, Mexican, Mexico, shells, fillings, salads, meats, sauce, cheese, beans, burrito', val: '🌮' },
  { title: 'Burrito', keywords: 'Mexican, Mexico, wraps, burritos, tortillas, food, rice', val: '🌯' },
  { title: 'Bottle with Popping Cork', keywords: 'bottles, corks, popping, bars, New Years, celebration, liquor, toasts, cheers, alcohol, alcoholism, parties, congratulations, drinking', val: '🍾' },
  { title: 'Popcorn', keywords: 'popcorn, snacks, movies, theaters, red, white, cartons, buttered, cinema, popping', val: '🍿' },
  { title: 'Kiwifruit', keywords: 'fruits, foods, kiwis, kiwifruits, healthy, trees, salads, green', val: '🥝' },
  { title: 'Avocado', keywords: 'foods, fruits, avocados, salads, seeds, trees, guacamole, healthy', val: '🥑' },
  { title: 'Potato', keywords: 'vegetables, potatoes, mashed, foods, salads, produce, farmers, market, vitamins, gardens, cooking, chips, baked, fries, brown', val: '🥔' },
  { title: 'Carrot', keywords: 'carrots, healthy, salads, vegetables, produce, farmers, market, vitamins, eyesight, gardens, orange, rabbits', val: '🥕' },
  { title: 'Cucumber', keywords: 'cucumbers, healthy, salads, vegetables, produce, farmers, market, vitamins, pickles, gardens, green', val: '🥒' },
  { title: 'Peanuts', keywords: 'vegetable, food, peanuts, nuts, oil, legumes, snacks, butter, roast, shells', val: '🥜' },
  { title: 'Croissant', keywords: 'rolls, French, France, bread, foods, croissants, breakfasts, crescents, bakeries, bakery, flakey', val: '🥐' },
  { title: 'Baguette Bread', keywords: 'food, bread, French, France, baguette, bakery, sandwich', val: '🥖' },
  { title: 'Pancakes', keywords: 'pancakes, hotcake, pancake, food, breakfasts, maple, flat, syrup, butter, morning, crêpe', val: '🥞' },
  { title: 'Bacon', keywords: 'breakfasts, foods, meats, bacon, strips, unhealthy, fatty, lard, eggs', val: '🥓' },
  { title: 'Stuffed Flatbread', keywords: 'flatbreads, falafels, gyros, food, kebabs, stuffed, pita, naan, tortillas, wraps, condiments, fast, food', val: '🥙' },
  { title: 'Egg', keywords: 'food, breakfast, eggs, shells, ducks, chickens, hens, Easter, cooking, bacon, hard-boiled', val: '🥚' },
  { title: 'Shallow Pan Of Food', keywords: 'food, paella, shallow, pans, casserole, cooking, stovetops', val: '🥘' },
  { title: 'Green Salad', keywords: 'salads, food, greens, green, vegetables, produce, farmers, market, vitamins, gardens, cooking', val: '🥗' },
  { title: 'Glass of Milk', keywords: 'glasses, drinks, milk, cows, dairy, calcium, healthy, beverages, children, white, homogenized', val: '🥛' },
  { title: 'Coconut', keywords: 'piña colada, coconuts, palms, tropical, meat, water, fruits', val: '🥥' },
  { title: 'Broccoli', keywords: 'wild cabbage, broccoli, healthy, salads, vegetables, produce, farmers, market, vitamins, florets, cauliflower, gardens, green, cooking', val: '🥦' },
  { title: 'Pretzel', keywords: 'twisted, pretzels, dough, snacks, salty, hard, soft, bread, Germany', val: '🥨' },
  { title: 'Cut of Meat', keywords: 'lamb, cuts, meats, chops, pork, steaks, food, butchers, red', val: '🥩' },
  { title: 'Sandwich', keywords: 'sandwiches, bread, slices, meats, food, condiments, salads, cheeses, vegetables, fast, health', val: '🥪' },
  { title: 'Bowl With Spoon', keywords: 'congee, cereals, breakfasts, bowls, spoons, soups, porridge, oatmeal, foods', val: '🥣' },
  { title: 'Canned Food', keywords: 'canned, food, cans, tuna, fish, salmon, food, beans, foods, storage', val: '🥫' },
  { title: 'Dumpling', keywords: 'dumplings, gyōza, pierogis, empanada, jiaozi, potsticker, dough, fillings', val: '🥟' },
  { title: 'Fortune Cookie', keywords: 'prophecy, fortunes, cookies, sweets, Chinese, restaurants, desserts', val: '🥠' },
  { title: 'Takeout Box', keywords: 'takeout, boxes, to-go, togo, cartons, containers, Chinese, Asian, oysters, pails', val: '🥡' },
  { title: 'Pie', keywords: 'pastry, pastries, pies, fillings, desserts, bakery, bakeries, snacks, cream, fruit, apple, pumpkin, coconut, blueberry, pecan, lemon, merengue', val: '🥧' },
  { title: 'Cup With Straw', keywords: 'juice, cups, straws, drinks, thirsty, beverages, red, soda', val: '🥤' },
  { title: 'Chopsticks', keywords: 'hashi, chopsticks, Asian, eating, food, tableware, utensils', val: '🥢' },
  { title: 'Mango', keywords: 'tropical, fruits, mangos, healthy, salads, trees', val: '🥭️' },
  { title: 'Leafy Green', keywords: 'cabbage, lettuce, leafy, greens, kale, bok choy, green, vitamins, salads, vegetables, healthy, gardens, produce, markets, farmers', val: '🥬️' },
  { title: 'Bagel', keywords: 'schmear, breakfast, bakery, bagel, bread, toasted, cream, cheese, lox, New York', val: '🥯️' },
  { title: 'Salt', keywords: 'salt, shakers, condiments, salty, flavor, seasoning, the Dead Sea, bath, minerals, cooking, iodized, sea, rock, kosher', val: '🧂️' },
  { title: 'Moon Cake', keywords: 'moon, cakes, autumn, festivals, yuèbǐng, Asia, China, foods, duck egg, lunar', val: '🥮️' },
  { title: 'Lobster', keywords: 'seafood, red, shellfish, luxury, claws, bisque, lobsters, crustaceans, shrimp, crabs, scavengers', val: '🦞️' },
  { title: 'Cupcake', keywords: 'sweets, bakery, baking, bakeries, cupcakes, desserts', val: '🧁️' },
  { title: 'Fondue', keywords: 'melted, pots, Swiss, fondues, chocolate, cheeses, France, dipping, bread, meats, oils', val: '🫕' },
  { title: 'Olive', keywords: 'olive, food', val: '🫒' },
  { title: 'Flatbread', keywords: 'naan, flatbread, arepa, lavash, pita, bread, flat, tortillas, bakery, food', val: '🫓' },
  { title: 'Tamale', keywords: 'tamales, Mexican, Latin America, corn, fillings, masa, wrapped, tamal, steamed, hojas, leaves, husks, banana, meat', val: '🫔' },
  { title: 'Bell Pepper', keywords: 'capsicum, vegetable, bell pepper, pepper', val: '🫑' },
  { title: 'Blueberries', keywords: 'blueberry, blueberries, blue, berry, bilberry', val: '🫐' },
  { title: 'Bubble Tea', keywords: 'milk, bubbles, pearls, teas, boba, Taiwan, tapioca, straws, beverages, hot, cold, drinks', val: '🧋' },
  { title: 'Ship', keywords: 'boats, ships, passengers, cruises, tourism, tourists, transportation, luxury, oceans, seas, international, vacations', val: '🚢' },
  { title: 'Sailboat', keywords: 'seas, yachts, sailboats, boats, water, nautical, naval, transportation', val: '⛵️' },
  { title: 'Speedboat', keywords: 'speedboats, boats, nautical, water, transportation, motorboats, recreation', val: '🚤' },
  { title: 'Anchor', keywords: 'ships, tools, anchors, nautical, transportation, naval, boats', val: '⚓️' },
  { title: 'Rocket', keywords: 'space, outer, rockets, ships, astronauts, travel, transportation, exploration', val: '🚀' },
  { title: 'Airplane', keywords: 'aeroplane, airplane, planes, air, transportation, travel, flying, flight', val: '✈️' },
  { title: 'Seat', keywords: 'seat chair', val: '💺' },
  { title: 'Helicopter', keywords: 'vehicles, helicopters, flight, transportation, emergencies, rescues, tourism, birds, travel', val: '🚁' },
  { title: 'Steam Locomotive', keywords: 'trains, engines, steam, locomotives, railways, railroads, transportation', val: '🚂' },
  { title: 'Tram', keywords: 'trolleybuses, trams, cars, light rails, railways, electric, transportation, railroads', val: '🚊' },
  { title: 'Station', keywords: 'stations, railways, trains, stops, railroads, commuters, waiting, transportation, trave', val: '🚉' },
  { title: 'Mountain Railway', keywords: 'railways, cars, trains, mountains, mountainous, railroads, travel, transportation, tourism, tourists', val: '🚞' },
  { title: 'Train', keywords: 'trains, railways, railroads, passengers, commuters, travel, transportation', val: '🚆' },
  { title: 'High-Speed Train', keywords: 'railways, trains, shinkansen, high-speed, speed, transportation, fast, travel', val: '🚄' },
  { title: 'High-Speed Train with Bullet Nose', keywords: 'trains, speed, shinkansen, railways, bullets, commuters, transportation, travel, quiet, fast, high-speed', val: '🚅' },
  { title: 'Light Rail', keywords: 'railways, light, rails, electric, trains, railroads, travel, transportation, cities, commuters', val: '🚈' },
  { title: 'Metro', keywords: 'subways, metros, travel, trains, underground, transportation, high-speed, commuters, rails', val: '🚇' },
  { title: 'Monorail', keywords: 'vehicles, monorails, rails, trains, cars, railways, travel, transportation', val: '🚝' },
  { title: 'Tram Car', keywords: 'cars, trolleybuses, light rails, railways, electric, transportation, railroads, trams', val: '🚋' },
  { title: 'Railway Car', keywords: 'trolleybuses, trams, cars, travel, electrics, railways, trains, tourism, tourists, railroads', val: '🚃' },
  { title: 'Trolleybus', keywords: 'trolleybuses, trolleys, trams, buses, transportation, travel, light rails, trains, railroads', val: '🚎' },
  { title: 'Bus', keywords: 'vehicles, buses, autobuses, transportation, travel, tourism, tourists, tours, commuters', val: '🚌' },
  { title: 'Oncoming Bus', keywords: 'oncoming, public, buses, pedestrians, bus, stops, traffic, signal, arrival, bus stations, transportation, front', val: '🚍' },
  { title: 'Recreational Vehicle', keywords: 'recreational, utility, RVs, trailers, camping, families, vacation, wheels, homes', val: '🚙' },
  { title: 'Oncoming Automobile', keywords: 'automobiles, oncoming, cars, traffic, pedestians, transportation', val: '🚘' },
  { title: 'Automobile', keywords: 'cars, automobiles, driving, drivers, traffic, transportation, travel', val: '🚗' },
  { title: 'Taxi', keywords: 'vehicles, taxis, cabs, transportation, cars, automobiles, yellow, lifts, rides', val: '🚕' },
  { title: 'Oncoming Taxi', keywords: 'taxi, oncoming, rides, lifts, taxis, cabs, yellow', val: '🚖' },
  { title: 'Articulated Lorry', keywords: 'trucks, lorry, lorries, transportation, articulated lorry, semi', val: '🚛' },
  { title: 'Delivery Truck', keywords: 'trucks, delivery, deliveries, transporting, products, goods, transportation', val: '🚚' },
  { title: 'Police Cars Revolving Light', keywords: 'beacons, revolving, police, cars, lights, sirens, emergencies, emergency', val: '🚨' },
  { title: 'Police Car', keywords: 'police, patrols, cars, automobiles, sheriffs, sirens, lights, transportation, transit, criminals, crime, safety, protection, emergency, emergencies', val: '🚓' },
  { title: 'Oncoming Police Car', keywords: 'police, oncoming, cars, crime, sirens, arrivals, arriving, transportation', val: '🚔' },
  { title: 'Fire Engine', keywords: 'trucks, engines, fires, emergencies, sirens, transportation, medical, injuries, water, hoses, hydrants, hospitals', val: '🚒' },
  { title: 'Ambulance', keywords: 'vehicles, ambulances, transportation, medical, emergencies, hospitals', val: '🚑' },
  { title: 'Minibus', keywords: 'buses, minibuses, minibus, tourists, vehicles, transportation, travel, public', val: '🚐' },
  { title: 'Bicycle', keywords: 'bikes, bicycles, transportation, travel, riding', val: '🚲' },
  { title: 'Aerial Tramway', keywords: 'tramways, aerial, cables, ropeways, ski lifts, cars, gondolas, transportation', val: '🚡' },
  { title: 'Suspension Railway', keywords: 'railways, suspension, trains, railroads, monorails, transportation, commuters, aerial trams', val: '🚟' },
  { title: 'Mountain Cableway', keywords: 'gondolas, cables, cableways, mountains, views, tourists, aerial, tramways, cars, transportation, tourism', val: '🚠' },
  { title: 'Ferry', keywords: 'ferry, ferries, passengers, boats, schedules, commuters, transportation', val: '⛴' },
  { title: 'Tractor', keywords: 'vehicles, tractors, farms, construction, transportation', val: '🚜' },
  { title: 'Bus Stop', keywords: 'busstops, buses, stops, public, transportation, commuters, cities, vehicles', val: '🚏' },
  { title: 'Motorway', keywords: 'highways, roads, motorways, cars, automobiles, freeways, roads, driving, high speeds, travel, transportation', val: '🛣' },
  { title: 'Railway Track', keywords: 'railways, tracks, trains, transportation, travel, cars, railroads', val: '🛤' },
  { title: 'Motor Boat', keywords: 'motorboat, boats, motor boats, marine, nautical, naval, transportation travel', val: '🛥' },
  { title: 'Small Airplane', keywords: 'aeroplanes, small, airplanes, planes, transportation, travel, airports, transportation', val: '🛩' },
  { title: 'Airplane Departure', keywords: 'departures, airplane, aeroplane, check-in, airplanes, departures, airports, flights, transportation', val: '🛫' },
  { title: 'Airplane Arriving', keywords: 'aeroplanes, arrivals, airplanes, landing, plane, transportation, airports, travel, arrival, arriving', val: '🛬' },
  { title: 'Passenger Ship', keywords: 'ships, passengers, cruises, water, transportation, tourism, tourists, international, seas, oceans, ports, vacation', val: '🛳' },
  { title: 'Ticket', keywords: 'admissions, tickets, entry, boarding, passes, travel, transportation', val: '🎫' },
  { title: 'Vertical Traffic Light', keywords: 'traffic, signals, lights, vertical, crosswalks, pedestrians, automobiles, cars, walking, transportation, driving, drivers', val: '🚦' },
  { title: 'Horizontal Traffic Light', keywords: 'lights, signals, horizontal, traffic, pedestrians, crosswalks, transportation, walking, driving, cars, automobiles, drivers', val: '🚥' },
  { title: 'Carousel Horse', keywords: 'horses, carousels, children, amusement, parks, malls, fairs', val: '🎠' },
  { title: 'Ferris Wheel', keywords: 'wheels, rides, carnivals, amusement, parks, ferris', val: '🎡' },
  { title: 'Roller Coaster', keywords: 'amusement, parks, roller, coasters, ups, downs, speeds, entertaining, rides, excitement, emotion', val: '🎢' },
  { title: 'Scooter', keywords: 'kick, scooters, transportation, skateboards, travel', val: '🛴' },
  { title: 'Skateboard', keywords: 'skateboarders, skateboarding, board, tricks, feats, hobbies, pastimes', val: '🛹️' },
  { title: 'Motor Scooter', keywords: 'motors, scooters, mopeds, motorbikes, motorcycles, motorized bicycles, transportation, cities', val: '🛵' },
  { title: 'Canoe', keywords: 'canoes, boats, kayaks, canoeing, lakes, transportation', val: '🛶' },
  { title: 'Sled', keywords: 'sleighs, sleds, sledding, sleighing, winter, snow, transportation, dogsleds, Rosebud', val: '🛷' },
  { title: 'Flying Saucer', keywords: 'flying, saucers, UFOs, unidentified, science, planets, space, transportation, aliens', val: '🛸' },
  { title: 'Pickup Truck', keywords: 'pickups, pick-ups, trucks, flatbeds, cars, automobiles, vehicles, transportation', val: '🛻' },
  { title: 'Dog Face', keywords: 'pets, faces, dogs, cute, breeds, man\'s best friend, loyalty, domestic, furry, four-legged, beagles, long ears, tongue', val: '🐶' },
  { title: 'Wolf Face', keywords: 'wolf, face, wolves, howling, canines, forests, woods, nighttime, wild, animals, werewolves, packs, hunting, villains, Little Red Riding Hood, antisocial, lone wolf', val: '🐺' },
  { title: 'Cat Face', keywords: 'pets, face, cats, kittens, felines', val: '🐱' },
  { title: 'Mouse Face', keywords: 'mouse, face, mice, rodents, pests, mousetraps, pets, rats, squeaking, white, brown, gray', val: '🐭' },
  { title: 'Hamster Face', keywords: 'pets, hamsters, faces, rodents, pets, hamster wheels, hoarding, nuts, small, cute, cages', val: '🐹' },
  { title: 'Rabbit Face', keywords: 'face, rabbits, bunny, bunnies, Easter, chocolate, pets, long ears, reproduction, feet, good luck, charms, soft, fur, cute', val: '🐰' },
  { title: 'Frog Face', keywords: 'frogs, lily pads, amphibians, toads, bulging eyes, tadpoles, swamps, bogs, slimy, frogs\' legs, The Frog Prince, green', val: '🐸' },
  { title: 'Tiger Face', keywords: 'face, tigers, jungles, stripes, wild, safaris, dangerous, jaws, predators, cats, circus, Africa, Asia', val: '🐯' },
  { title: 'Koala', keywords: 'koalas, bears, marsupials, vegetarians, eucalyptus, herbivores, Australia, wild animals, forests', val: '🐨' },
  { title: 'Bear', keywords: 'face, bears, animals, brown, forests, teddy bears, woods, honey, bear hugs, hibernation, wildlife', val: '🐻' },
  { title: 'Pig Face', keywords: 'pigs, face, hogs, sows, farm animals, food, meat, pork, ham, dirt, overweight, fat, slovenly, overeating, laziness', val: '🐷' },
  { title: 'Pig Nose', keywords: 'pigs, noses, snouts, pigs, hogs, sows, farm animals, food, meat, pork, ham, dirt, overweight, fat, slovenly, overeating, laziness, face, cute, pink', val: '🐽' },
  { title: 'Cow Face', keywords: 'face, cows, milk, udders, cuds, farms, grazing, animals, meat, beef, milk', val: '🐮' },
  { title: 'Boar', keywords: 'pigs, boars, tusks, snouts, danger, wild, animals, pig heads, boar heads', val: '🐗' },
  { title: 'Monkey Face', keywords: 'face, monkey, monkeys, zoo, primates, jungles, tropical forests, trees, apes', val: '🐵' },
  { title: 'Monkey', keywords: 'monkey, monkeys, zoo, primates, jungles, tropical forests, trees, apes', val: '🐒' },
  { title: 'Horse Face', keywords: 'face, cartoon horse, racehorse, transportation, Wild West, cowboys, equestrian, horseback riding, racing, galloping, hooves, horseshoes, manes, saddles, stallions, reins', val: '🐴' },
  { title: 'Sheep', keywords: 'females, ewes, sheep, lambs, wool, lamb chops, milk', val: '🐑' },
  { title: 'Elephant', keywords: 'elephants, large animals, wild animals, tusks, trunks, Africa, India, circus, safari, endangered, fat, grey, gray', val: '🐘' },
  { title: 'Panda Face', keywords: 'pandas, endangered species, bamboo, cute, bears, face', val: '🐼' },
  { title: 'Penguin', keywords: 'penguins, birds, aquatic, arctic, marine, North Pole, South Pole, cold, frigid, snow, glaciers, waddling, swimming, feathers, tuxedo, cute', val: '🐧' },
  { title: 'Bird', keywords: 'birds, flying, feathers, nests, lightweight, nature, pets', val: '🐦' },
  { title: 'Baby Chick', keywords: 'chick, bird, baby, baby chicks, chickens, cute birds, poultry, farms, chicken, birds, innocence, Easter, yellow', val: '🐤' },
  { title: 'Front-Facing Baby Chick', keywords: 'front-facing baby chicks, chicks, birds, baby, small, poultry, fowl, Easter, peeps, farms, cute, adorable', val: '🐥' },
  { title: 'Hatching Chick', keywords: 'hatching, chicks, birds, baby, babies, young, eggs', val: '🐣' },
  { title: 'Chicken', keywords: 'chickens, birds, fowl, farms, eggs, cooking, roosters, poultry, hens', val: '🐔' },
  { title: 'Snake', keywords: 'Ophiuchus, charmers, snakes, serpents, zodiac, venom, forked tongues, shedding skin, medicine, evil, gardens, Garden of Eden, Adam, Eve, apple, poison, snake bites, sinister, slither, scales', val: '🐍' },
  { title: 'Turtle', keywords: 'tortoises, terrapin, turtles, slow, beaches, eggs, oceans, marine, aquatic, pet turtles', val: '🐢' },
  { title: 'Bug', keywords: 'insects, bugs, caterpillars, butterflies, butterfly, creepy-crawlies', val: '🐛' },
  { title: 'Honeybee', keywords: 'honeybees, bees, wasps, stings, allergies, insects, bugs, pollination, flowers, stingers, queen bees, bee hives, buzzing, yellow, black, summer, spring', val: '🐝' },
  { title: 'Ant', keywords: 'insect, ant, insects, tiny, ant hills, nests, ants, bugs, picnics', val: '🐜' },
  { title: 'Chipmunk', keywords: 'squirrels, chipmunks, nuts, rodents, trees, forests, Alvin', val: '🐿' },
  { title: 'Lady Beetle', keywords: 'ladybugs, ladybirds, lady beetles, insects, beetles, luck, red, spots', val: '🐞' },
  { title: 'Snail', keywords: 'snails, mollusks, slugs, gardens, slow, snail mail', val: '🐌' },
  { title: 'Octopus', keywords: 'octopus, octopi, eight legs, marine, invertebrates, ink, oceans, seas, squids, seafood dishes, scuba, diving, snorkeling', val: '🐙' },
  { title: 'Spiral Shell', keywords: 'spiral, shells, snails, conchs, seashores, beaches, oceans, invertebrates, seashells, mollusks', val: '🐚' },
  { title: 'Tropical Fish', keywords: 'marine, seas, oceans, pets, tropical, fish, fishing, scuba, diving, snorkeling, water, aquatic', val: '🐠' },
  { title: 'Fish', keywords: 'zodiac, Pisces, fish, oceans, seas, rivers, lakes, marine life, aquatic animals, fish tanks, pet fish, snorkeling, deep sea, going fishing, sport fishing, food, sushi', val: '🐟' },
  { title: 'Dolphin', keywords: 'Flipper, dolphins, jumping, swimming, fish, marine, aquatic, oceans, seas, beaches, intelligent, social, porpoises', val: '🐬' },
  { title: 'Spouting Whale', val: '🐳' },
  { title: 'Whale', keywords: 'whales, spouting, oceans, seas, blubber, aquatic mammals, marine life, Moby Dick, Jonah', val: '🐋' },
  { title: 'Cow', keywords: 'cows, milk, udders, cuds, farms, grazing, animals, meat, beef, milk', val: '🐄' },
  { title: 'Ram', keywords: 'zodiac, sheep, ram, male, Aries, butt heads, ramming, mountains, wool', val: '🐏' },
  { title: 'Rat', keywords: 'rats, rodents, mice, pests, plagues, disease, garbage, filth, dirt, slums, reproduction', val: '🐀' },
  { title: 'Water Buffalo', keywords: 'water, buffalo, farms, plowing', val: '🐃' },
  { title: 'Tiger', keywords: 'tigers, jungles, stripes, wild, safaris, dangerous, jaws, predators, cats, circus, Africa, Asia', val: '🐅' },
  { title: 'Rabbit', keywords: 'rabbits, bunny, bunnies, Easter, chocolate, pets, long ears, reproduction, feet, good luck, charms, soft, fur, cute', val: '🐇' },
  { title: 'Dragon', keywords: 'fairy tales, dragons, mythic, myths, stories, power, wealth, fire, breathing fire, flying animals, reptiles, mythical, serpentine, Chinese New Year', val: '🐉' },
  { title: 'Horse', keywords: 'racehorse, transportation, Wild West, cowboys, equestrian, horseback riding, racing, galloping, hooves, horseshoes, manes, saddles, stallions, reins', val: '🐎' },
  { title: 'Goat', keywords: 'zodiac, goats, Capricorn, cheese, milk, meat, food, stubborn, hairy', val: '🐐' },
  { title: 'Rooster', keywords: 'roosters, birds, cockadoodledoo, chickens, hens, cocks, fowl, males, crowing, early, morning', val: '🐓' },
  { title: 'Dog', keywords: 'pets, dogs, breeds, watch dogs, furry, man\'s best friend, four-legged', val: '🐕' },
  { title: 'Pig', keywords: 'sows, pigs, hogs, farm animals, food, meat, pork, ham, dirt, overweight, fat, slovenly, overeating, laziness', val: '🐖' },
  { title: 'Mouse', keywords: 'mouse, mice, rodents, pests, mousetraps, pets, rats, squeaking', val: '🐁' },
  { title: 'Ox', keywords: 'zodiac, Taurus, ox, bull, stubborn, farms, oxen, carts, cattle', val: '🐂' },
  { title: 'Dragon Face', keywords: 'faces, fairy tales, dragons, mythic, myths, stories, power, wealth, fire, breathing fire, flying animals, reptiles, mythical, serpentine, Chinese New Year', val: '🐲' },
  { title: 'Blowfish', keywords: 'fish blowfish', val: '🐡' },
  { title: 'Crocodile', keywords: 'Crocodile', val: '🐊' },
  { title: 'Bactrian Camel', keywords: 'two-hump camel, hump, camels, Bactrian, two humps, hot, heat, thirst, deserts, Middle East, Central Asia, Wednesday, hump day, big animals, dry climates', val: '🐫' },
  { title: 'Dromedary Camel', keywords: 'one-hump camel, humps, camels, Dromedary, hot, heat, thirst, deserts, Middle East, Central Asia, Wednesday, hump day, big animals, dry climates', val: '🐪' },
  { title: 'Leopard', keywords: 'leopards, speed, spots, safaris, wild animals, cats, Africa, Asia, fur, panther', val: '🐆' },
  { title: 'Cat', keywords: 'pets, cats, nine lives, kittens, felines, agility, meow', val: '🐈' },
  { title: 'Poodle', keywords: 'poodles, dogs, pets, dog breeds, curly, purebred', val: '🐩' },
  { title: 'Paw Prints', keywords: 'print paw prints paw feet', val: '🐾' },
  { title: 'Crab', keywords: 'zodiac, crab, Cancer, crab meat, Alaskan King Crab Legs, marine, ocean, shells, stuffed, soft-shell, crabby', val: '🦀' },
  { title: 'Lion Face', keywords: 'zodiac, lions, Leo, faces, dens, prides, King of the Jungle, manes, wild, cats, cubs, animals, strength, bravery, stealth, royalty, roar', val: '🦁' },
  { title: 'Scorpion', keywords: 'scorpion, scorpio, Scorpio, zodiac, stingers, poison', val: '🦂' },
  { title: 'Spider', keywords: 'spiders, insects, tarantulas, silk, webs, black widows, bugs, Spiderman', val: '🕷' },
  { title: 'Turkey', keywords: 'turkeys, birds, Turkey, Thanksgiving, meat, poultry, fowl, feathers, flightless', val: '🦃' },
  { title: 'Unicorn', keywords: 'unicorns, face, fairy tales, horns, horses, rainbows, mythical, girls, white, beautiful, children', val: '🦄' },
  { title: 'Shrimp', keywords: 'shellfish, food, shrimps, small, peeling, crustaceans, seafood, fried, tiny, lobsters, prawns', val: '🦐' },
  { title: 'Squid', keywords: 'mollusk, food, squid, octopus, eight legs, marine, invertebrates, ink, oceans, seas, seafood dishes, calamari, scuba, diving, snorkeling', val: '🦑' },
  { title: 'Butterfly', keywords: 'pretty, insects, butterfly, butterflies, floating, lightweight, cocoons, feminine, fluttering, spring, summer', val: '🦋' },
  { title: 'Gorilla', keywords: 'gorilla, apes, forests, jungles, wild, primates, evolution, monkeys, strong, dangerous, animals, zoos, tropical, tropics, endangered, large', val: '🦍' },
  { title: 'Fox Face', keywords: 'fox, foxes, hunting, wild, animals, slyness, face', val: '🦊' },
  { title: 'Deer', keywords: 'deer, forests, woods, wild animals, hunting, venison, Christmas, Santa Claus, reindeer, Rudolph the Red Nose Reindeer, deer in headlights, horns, fawns', val: '🦌' },
  { title: 'Rhinoceros', keywords: 'rhinoceros, rhinos, horn, horns, poaching, Africa, Asia, herbivore, powerful, animal', val: '🦏' },
  { title: 'Bat', keywords: 'vampires, bats, spooky, Halloween, haunted houses, nighttime, flying, rabies', val: '🦇' },
  { title: 'Eagle', keywords: 'eagles, birds, flying, birds of prey, hunting, United States, America, American Eagle, wings, wild', val: '🦅' },
  { title: 'Duck', keywords: 'ducks, birds, eggs, poultry, cooking, meat', val: '🦆' },
  { title: 'Owl', keywords: 'wise, owl, birds, hunters, night, nighttime, night owls, Halloween', val: '🦉' },
  { title: 'Lizard', keywords: 'reptiles, lizards, squamata, geckos, reptiles, tails, herbivores, jungles, iguanas', val: '🦎' },
  { title: 'Shark', keywords: 'sharks, fish, oceans, seas, marine life, aquatic animals, snorkeling, deep-sea, surfing, sport fishing, food, jaws, teeth, danger, beaches, summer', val: '🦈' },
  { title: 'Zebra', keywords: 'zebras, stripes, wild, safaris, Africa, Z-words, black, white', val: '🦓' },
  { title: 'Giraffe', keywords: 'spots, giraffes, tall, necks, long, legs, wild, animals, Africa, safari, faces', val: '🦒' },
  { title: 'Hedgehog', keywords: 'spiny, spines, hedgehogs, mammals, pets, animals', val: '🦔' },
  { title: 'Sauropod', keywords: 'brontosaurus, brachiosaurus, diplodocus, sauropods, dinosaurs, extinct, long necks, fossils, ancient, herbivores', val: '🦕' },
  { title: 'T-Rex', keywords: 'Tyrannosaurus Rex, T-Rex, dinosaurs, fossils, carnivores', val: '🦖' },
  { title: 'Raccoon', keywords: 'sly, raccoons, curious, mask, nocturnal, mammal, forests, pests, stealing', val: '🦝️' },
  { title: 'Llama', keywords: 'wool, vicuña, llamas, guanaco, alpaca, South America, camels, pack animals, meat', val: '🦙️' },
  { title: 'Hippopotamus', keywords: 'hippopotamus, hippo, fat, safari, animals, grey, gray, mammals, large, jaws, Africa', val: '🦛️' },
  { title: 'Kangaroo', keywords: 'marsupials, kangaroos, jump, joey, Australia, pouch, kicking', val: '🦘️' },
  { title: 'Badger', keywords: 'pests, honey badgers, badgers, nocturnal, animals, night, digging, claws', val: '🦡️' },
  { title: 'Swan', keywords: 'swans, birds, cygnets, ugly duckling, Swan Lake, romance, long necks', val: '🦢️' },
  { title: 'Peacock', keywords: 'proud, peahen, peacocks, ostentatious, birds, feathers, wildlife, quills', val: '🦚️' },
  { title: 'Parrot', keywords: 'talk, pirates, parrots, birds, pets, tropical, tropics', val: '🦜️' },
  { title: 'Mosquito', keywords: 'virus, pest, mosquito, malaria, bug bites, stings, fever, disease', val: '🦟️' },
  { title: 'Polar Bear', keywords: 'white, arctic, winter, polar bears, bears, aquatic, animals, North Pole, South Pole, cold, frigid, snow, glaciers, cute, rugs, fur', val: '🐻‍❄️' },
  { title: 'Seal', keywords: 'seal, sea Lion, playful, water, oceans, walrus, fishing, playful, cute', val: '🦭' },
  { title: 'Water Buffalo', keywords: '', val: '🐃' },
  { title: 'Worm', keywords: 'worms, earthworms, parasites, annelids, birds, underground, digging, fish bait, wiggle', val: '🪱' },
  { title: 'Bison', keywords: 'wisent, herds, buffalo, bison', val: '🦬' },
  { title: 'Cockroach', keywords: 'roaches, pests, insects, cockroaches, bugs, insecticide, dirt, filth, trash, garbage, water bugs', val: '🪳' },
  { title: 'Mammoth', keywords: 'woolly, tusks, mastodons, mammoths, large, extinction', val: '🦣' },
  { title: 'Dodo', keywords: 'Mauritius, birds, large, dodos, extinct, flightless, Mauritius, Madagascar, silly', val: '🦤' },
  { title: 'Black Cat', keywords: 'cats, unlucky, black cats, witchcraft, Halloween, spooky, scary, witches, evil, unlucky, black', val: '🐈‍⬛' },
  { title: 'Cricket', keywords: 'grasshoppers, crickets, bugs, insects, chirping', val: '🦗' },
  { title: 'Beaver', keywords: 'dams, beavers, rodents, habitats, building, builders', val: '🦫' },
  { title: 'Beetle', keywords: 'insects, bugs, beetles, scarabs', val: '🪲' },
  { title: 'Fly', keywords: 'flies, dirt, pests, buzzing, maggots, disease, garbage, filth, rotting, rotten, insects, bugs, annoying', val: '🪰' },
  { title: 'Thumbs Up', keywords: 'up, thumbs up, thumb, hand, +1, liking, pleased, yes, positive, positivity', val: '👍' },
  { title: 'Thumbs Down', keywords: 'thumbs down, negative, no, not, unhappy, displeased, dislike, thumbs, hands, down, -1', val: '👎' },
  { title: 'OK Hand', keywords: 'OK, hand, gestures, okay, agreement, fine, agreement', val: '👌' },
  { title: 'Oncoming Fist', keywords: 'punch, oncoming fist, hand, fist, fisted hands, punching, hitting, clenched', val: '👊' },
  { title: 'Raised Fist', keywords: 'raised fist, punch, hands, fists, clenched', val: '✊' },
  { title: 'Victory Hand', keywords: 'victory, v, hands, peace, hello, 2, two', val: '✌️' },
  { title: 'Waving Hand', keywords: 'waving, waves, hands, hello, goodbye, later, hi, bye', val: '👋' },
  { title: 'Raised Hand', keywords: 'raised hands, hands, questions, classroom, teacher, raising', val: '✋' },
  { title: 'Open Hands', keywords: 'open hands, open, hands, giving, sharing, charity', val: '👐' },
  { title: 'Backhand Index Pointing Up', keywords: 'up, point, hand, finger, backhand index pointing up, backhand, up, over, above, one, on top', val: '👆' },
  { title: 'Backhand Index Pointing Down', keywords: 'point, hand, finger, down, below, underneath, backhand index pointing down, backhand', val: '👇' },
  { title: 'Backhand Index Pointing Right', keywords: 'point, index, hand, finger, right, backhand index pointing right, backhand', val: '👉' },
  { title: 'Backhand Index Pointing Left', keywords: 'point, index, finger, backhand index pointing left, backhand', val: '👈' },
  { title: 'Raising Hands', keywords: 'raising hands, raised, hooray, hand, gesture, celebrations, joy, happiness, birthday', val: '🙌' },
  { title: 'Folded Hands', keywords: 'thanks, praying, please, hand, folded hands, asking, begging', val: '🙏' },
  { title: 'Flexed Biceps', keywords: 'muscle, flexed biceps, flex, comic, biceps, strength, muscles, strong, power', val: '💪' },
  { title: 'Vulcan Salute', keywords: 'vulcan salute, live long and prosper, Vulcan, Dr. Spock, hand, finger', val: '🖖' },
  { title: 'Hand with Fingers Splayed', keywords: 'splayed, hand with fingers splayed, hand, fingers, goodbye', val: '🖐️' },
  { title: 'Index Pointing Up', keywords: 'up, point, index pointing up, index, hand, finger', val: '☝️' },
  { title: 'Clapping Hands', keywords: 'hand, clapping hands, applause, claps, applauding, joy, excitement', val: '👏' },
  { title: 'Writing Hand', keywords: 'writing hand, write, hand, penmanship, writing, handwriting, students, studying', val: '✍️' },
  { title: 'Sign of the Horns', keywords: 'sign of the horns, rock-on, horns, hands, fingers, music, happy', val: '🤘' },
  { title: 'Middle Finger', keywords: 'middle finger, hand, fingers, vulgar, swear words', val: '🖕' },
  { title: 'Crossed Fingers', keywords: 'luck, hands, fingers, crossed fingers, cross, lying, good luck', val: '🤞' },
  { title: 'Call Me Hand', keywords: 'hand, call me hand, call', val: '🤙' },
  { title: 'Left-Facing Fist', keywords: 'leftwards, left-facing fist, fist', val: '🤛' },
  { title: 'Right-Facing Fist', keywords: 'rightwards, right-facing fist, fists, bumps', val: '🤜' },
  { title: 'Back Of Hand', keywords: 'raised back of hand, raised, hands, backhands, stop, wait', val: '🤚' },
  { title: 'Handshake', keywords: 'shake, meeting, handshakes, hands, greeting, hello, goodbye, agreements', val: '🤝' },
  { title: 'Love-You Gesture', keywords: 'love-you gesture, ILY, hands, I love you, romance, love, valentine', val: '🤟' },
  { title: 'Palms Up Together', keywords: 'prayer, palms up together, sharing, charity, giving, openness', val: '🤲' },
  { title: 'Pinched Fingers', keywords: 'wait, sarcastic, pinched, interrogation, hand gesture, fingers', val: '🤌' },
  { title: 'Nail Polish', keywords: 'nails, cosmetics, manicure, hands, fingers, cosmetics, spas, polish', val: '💅' },
  { title: 'Up Arrow', keywords: 'up, arrows, north, directions, cardinal, pages', val: '⬆️' },
  { title: 'Down Arrow', keywords: 'south, down, direction, cardinal, arrows', val: '⬇️' },
  { title: 'Left Arrow', keywords: 'west, left arrow, direction, cardinal, arrow, back', val: '⬅️' },
  { title: 'Right Arrow', keywords: 'right, east, direction, cardinal, arrows', val: '➡️' },
  { title: 'Up-Right Arrow', keywords: 'up-right arrow, northeast, intercardinal, direction, up, right, arrows', val: '↗️' },
  { title: 'Up-Left Arrow', keywords: 'up-left, arrows, northwest, inter-cardinal, directions, left', val: '↖️' },
  { title: 'Down-Right Arrow', keywords: 'southeast, intercardinal, down-right arrow, directions, arrows', val: '↘️' },
  { title: 'Down-Left Arrow', keywords: 'southwest, intercardinal, down-left arrow, direction, arrow', val: '↙️' },
  { title: 'Left Right Arrow', keywords: 'left, right, arrows, arrow, directions', val: '↔️' },
  { title: 'Up Down Arrow', keywords: 'up-down, arrows, up, down, directions, arrow', val: '↕️' },
  { title: 'Counterclockwise Arrows', keywords: 'withershins, counterclockwise arrows button, counterclockwise, arrow, anticlockwise', val: '🔄' },
  { title: 'Left-Pointing Triangle', keywords: 'triangle, reverse, button, reverse, left, arrow', val: '◀️' },
  { title: 'Right-Pointing Triangle', keywords: 'triangle, right, play, button, arrow', val: '▶️' },
  { title: 'Up-Pointing Triangle', keywords: 'upwards button, button, arrows, up, volume, elevator', val: '🔼' },
  { title: 'Down-Pointing Triangle', keywords: 'downwards button, down, buttons, arrow,s elevators, volume, lower', val: '🔽' },
  { title: 'Right Arrow Curving Left', keywords: 'arrows, traffic, directions, directionals, signals, previous', val: '↩️' },
  { title: 'Left Arrow Curving Right', keywords: 'left arrow curving right, arrows, turn back, u-turn, traffic signs', val: '↪️' },
  { title: 'Rewind Symbol', keywords: 'rewind, fast, reverse, button, double, arrow', val: '⏪' },
  { title: 'Fast Forward Symbol', keywords: 'forward, fast-forward button, fast, double, arrows', val: '⏩' },
  { title: 'Up-Pointing Double Triangle', keywords: 'fast, up, button, double, arrow, triangles, volume, loud', val: '⏫' },
  { title: 'Down-Pointing Double Triangle', keywords: 'fast, down, buttons, double, arrow, triangles, volume, quiet', val: '⏬' },
  { title: 'Right Arrow Curving Down', keywords: 'right, arrow curving, down, arrows, directions', val: '⤵️' },
  { title: 'Right Arrow Curving Up', keywords: 'right, arrows, curving, up', val: '⤴️' },
  { title: 'Shuffle Tracks Symbol', keywords: 'shuffle, tracks, music, random, button, crossed, arrows', val: '🔀' },
  { title: 'Repeat Symbol', keywords: 'repeat, button, clockwise, arrow, replay, reload', val: '🔁' },
  { title: 'Repeat Single Track Symbol', keywords: 'repeat single button, once, clockwise, arrow', val: '🔂' },
  { title: 'Clockwise Vertical Arrows', keywords: 'reload, clockwise vertical arrows, rotation, clockwise, arrow', val: '🔃' },
  { title: 'Top Arrow', keywords: 'up, TOP, arrows, top, directions', val: '🔝' },
  { title: 'End Arrow', keywords: 'END arrow, end, arrow', val: '🔚' },
  { title: 'Back Arrow', keywords: 'BACK arrow, back, arrow, go back, return', val: '🔙' },
  { title: 'On! Arrow', keywords: 'ON! arrow, on, mark, arrow, buttons', val: '🔛' },
  { title: 'Soon Arrow', keywords: 'SOON, arrows, soon, happening', val: '🔜' },
  { title: 'House Building', keywords: 'houses, homes, private, one-family, suburbs, families', val: '🏠' },
  { title: 'House with Garden', keywords: 'houses, homes, gardens, suburbs, country, towns, family, families, backyards, flowers, grass, trees, property', val: '🏡' },
  { title: 'School', keywords: 'education, buildings, schools, learning, elementary, students, teaching, teachers', val: '🏫' },
  { title: 'Office Building', keywords: 'offices, buildings, businesses, corporations, white-collar, workers, working, jobs, cities', val: '🏢' },
  { title: 'Japanese Post Office', keywords: 'post, offices, Japanese, postal, mail, buildings, government, Asian, delivery', val: '🏣' },
  { title: 'Hospital', keywords: 'medicine, hospitals, buildings, doctors, medical, care, emergency, urgent, doctors, first-aid, illnesses, injury, injuries, health, healing', val: '🏥' },
  { title: 'Bank', keywords: 'buildings, banks, business, money, finance, banking, currency, bankers, investors, investments', val: '🏦' },
  { title: 'Convenience Store', keywords: 'stores, convenience, corner, delis, grocery, groceries, shops', val: '🏪' },
  { title: 'Love Hotel', keywords: 'love, hotels, romance, marriages, dating, sex, prostitution, travel, motels, buildings', val: '🏩' },
  { title: 'Hotel', keywords: 'hotels, buildings, travels, hospitality, lodging, vacations, trips, tourism', val: '🏨' },
  { title: 'Wedding', keywords: 'weddings, romance, chapels, weddings, marriage, married, love, prayer, happy, events', val: '💒' },
  { title: 'Church', keywords: 'religions, crosses, churches, Christians, faith, God, beliefs, Christianity, Catholicism, worship, believers, prayers, praying', val: '⛪️' },
  { title: 'Department Store', keywords: 'store, department, shopping, stores, malls, merchandise, products, buildings', val: '🏬' },
  { title: 'European Post Office', keywords: 'post, offices, buildings, European, mail, delivery', val: '🏤' },
  { title: 'Sunset over Buildings', keywords: 'sunsets, sun, dusk, city, evenings, cities, nightfall, metropolitan, buildings, skylines, skyscrapers', val: '🌇' },
  { title: 'Mosque', keywords: 'religions, Muslims, mosques, Islam, Allah, prayer, Koran, Middle East, buildings, temples, worship', val: '🕌' },
  { title: 'Synagogue', keywords: 'religions, holidays, Jewish, Jew, synagogues, temples, Judaism, Jews, Jewish, congregations, prayer, Israel', val: '🕍' },
  { title: 'Cityscape at Dusk', keywords: 'sunsets, buildings, landscapes, skylines, evening, dusk, cityscape, dusk, city, urban, nightlife, evening, romance, nighttime, metropolitan', val: '🌆' },
  { title: 'Japanese Castle', keywords: 'Japanese, castles, fortresses, Asian, royalty, architecture, tourism, history, buildings, kingdoms', val: '🏯' },
  { title: 'Castle', keywords: 'European, castles, royalty, kings, queens, princes, princesses, kingdoms', val: '🏰' },
  { title: 'Tent', keywords: 'camping, tents, wilderness, hiking, forests, vacation, relaxation', val: '⛺️' },
  { title: 'Factory', keywords: 'factory, factories, buildings, industry, businesses, production, manufacturing, environmental, chemicals, workers', val: '🏭' },
  { title: 'Tokyo Tower', keywords: 'towers, Tokyo, communication, observation, Japan, tourism, buildings, skyscrapers, tall', val: '🗼' },
  { title: 'House Buildings', keywords: 'multiple, houses, private, homes, neighborhoods, suburbs, family, families, buildings', val: '🏘' },
  { title: 'National Park', keywords: 'parks, national, nature, woods, forests, state, hiking, hikers, tourism, trees, plants, flowers, birds, animals', val: '🏞' },
  { title: 'Stadium', keywords: 'stadiums, sports, arenas, events, athletics, athletes, games, matches, teams, fans', val: '🏟' },
  { title: 'Cityscape', keywords: 'cityscapes, city, cities, metropolitan, urban, nightlife, concrete, skylines, architecture, buildings', val: '🏙' },
  { title: 'Derelict House Building', keywords: 'houses, homes, derelict, old, fixer-upper, cheap, real, estate, foreclosures, abandoned, disrepair, haunted', val: '🏚' },
  { title: 'Classical Building', keywords: 'classical, buildings, government, offices, libraries, courthouses, museums, town, halls, post', val: '🏛' },
  { title: 'Building Construction', keywords: 'construction, sites, buildings, warnings, danger, careful, builders, steel, iron, crane', val: '🏗' },
  { title: 'Hut', keywords: 'yurts, roundhouses, huts, houses, jungles, habitats, forests, nature, natural, buildings, straw, woods, native, housing, indigenous, remote', val: '🛖' },
  { title: 'Headstone', keywords: 'tombstones, headstones, cemetery, cemeteries, graves, graveyards, funerals, death, grief', val: '🪦' },
  { title: 'Game Die', keywords: 'games, die, dice, board games, randomness, luck, craps, casinos, sports', val: '🎲' },
  { title: 'Direct Hit', keywords: 'targets, hits, game, direct hits, darts, bullseye, aiming, bars, sports', val: '🎯' },
  { title: 'American Football', keywords: 'football, American, balls, sports, Super Bowl, gridiron', val: '🏈' },
  { title: 'Basketball and Hoop', keywords: 'hoops, basketballs, balls, teams, sports, NBA', val: '🏀' },
  { title: 'Soccer Ball', keywords: 'soccer, football, balls, round, sports, World Cup', val: '️⚽️' },
  { title: 'Baseball', keywords: 'baseballs, balls, sports, bats, World Series, players', val: '⚾️' },
  { title: 'Tennis Racquet and Ball', keywords: 'tennis, racquets, rackets, balls, matches, sports, Wimbledon', val: '🎾' },
  { title: 'Billiards', keywords: 'game, 8, ball, billiards, eight, pool, 8 ball, bars, cue balls', val: '🎱' },
  { title: 'Rugby Football', keywords: 'footballs, balls, rugby, sports, contact, teams', val: '🏉' },
  { title: 'Bowling', keywords: 'game, bowling, balls, alleys, shoes, pins, ten-pin, ten', val: '🎳' },
  { title: 'Flag in Hole', keywords: 'holes, sports, golf, flags, PGA, putting, miniature, grass, green', val: '️⛳️' },
  { title: 'Chequered Flag', keywords: 'racing, chequered flag, chequered, cars, automobiles, races, finish, checkered', val: '🏁' },
  { title: 'Horse Racing', keywords: 'racing, racetracks, racehorses, horses, jockeys, races, equestrians, sports', val: '🏇' },
  { title: 'Trophy', keywords: 'trophy, prizes, sports, competition, competitor, trophies, ribbons, winners', val: '🏆' },
  { title: 'Skier', keywords: 'snow, skiers, skiing, winter, outdoors, sports, lifts, skis', val: '⛷' },
  { title: 'Ice Skate', keywords: 'skates, skating, frozen, winter, frozen, ice, sports', val: '⛸' },
  { title: 'Cricket Bat and Ball', keywords: 'games, cricket, games, bats, balls, sports', val: '🏏' },
  { title: 'Volleyball', keywords: 'volleyballs, games, balls, beaches, players, teams, balls, sports, volleying', val: '🏐' },
  { title: 'Field Hockey Stick and Ball', keywords: 'sticks, hockey, games, fields, balls, pucks, track, sports', val: '🏑' },
  { title: 'Ice Hockey Stick and Puck', keywords: 'sticks, pucks, ice, hockey, games, winter, sports', val: '🏒' },
  { title: 'Table Tennis Paddle and Ball', keywords: 'table tennis, ping pong, paddles, bars, games, balls', val: '🏓' },
  { title: 'Badminton Racquet and Shuttlecock', keywords: 'shuttlecock, game, racquet, birdie, badminton, lawn', val: '🏸' },
  { title: 'Bow and Arrow', keywords: 'zodiac, archery, Valentine\'s Day, cupid, Sagittarius, bows, arrows, archer', val: '🏹' },
  { title: 'Snowboarder', keywords: 'snowboarders, snowboards, snow, skiing, snowboarding, outdoors, winter, fun, sports', val: '🏂' },
  { title: 'Racing Motorcycle', keywords: 'racing, motorcycles, motorbikes, speed, drivers, racetracks, finish lines', val: '🏍' },
  { title: 'Racing Car', keywords: 'racing, cars, sports, speed, drivers, races, racetracks', val: '🏎' },
  { title: 'Person Fencing', keywords: 'swords, fighters, fencing, fencers, sports', val: '🤺' },
  { title: 'Goal Net', keywords: 'nets, goals, soccer, football, goalies, games, sports', val: '🥅' },
  { title: 'First Place Medal', keywords: 'medals, gold, first, 1st, place, medals, winners, competitions, competitive, sports, ribbons', val: '🥇' },
  { title: 'Second Place Medal', keywords: 'silver, second, medals, 2nd, place, second, sports, competition, competitors, competitive, ribbons', val: '🥈' },
  { title: 'Third Place Medal', keywords: 'third, medals, bronze, 3rd, place, competition, competitors, competitive, ribbons, winners, Olympics, sports', val: '🥉' },
  { title: 'Boxing Glove', keywords: 'gloves, boxing, boxers, martial arts, sports, red, rings, hands, punching, fists, hitting', val: '🥊' },
  { title: 'Martial Arts Uniform', keywords: 'martial arts, judo, karate, taekwondo, kung fu, uniforms, belts, sports, white', val: '🥋' },
  { title: 'People Wrestling', keywords: 'wrestlers, wrestles, people, wrestling, sports, matches, mats', val: '🤼' },
  { title: 'Men Wrestling', keywords: 'men, man, males, girls wrestlers, wrestles, people, wrestling, sports, matches, mats', val: '🤼‍♂️' },
  { title: 'Fishing Pole and Fish', keywords: 'poles, fishing, fish, sporting, lakes, water, outdoors, bait, rivers', val: '🎣' },
  { title: 'Curling Stone', keywords: 'rocks, stones, game, curling, stone, sports, ice, players', val: '🥌' },
];
